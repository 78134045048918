.gifContainer{
	padding-top:40px;
	padding-bottom: 40px;
	 font-family:Arial;
	 height: auto;
}
.gifContainer img{
	height: 100%;
	width:100%;
}
.gifTextContainer{
	padding: 40px !important;
	 font-family:Arial;

	padding-top:10% !important;
}
.gifTextContainer label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.gifTextContainer p{
	font-size: 1.5em;
    line-height: 1.5em;
}
.iceBlueColor{
	color: #201A47 !important
}
.gifTextContainer a{
	text-decoration: underline;
}
.onHoverEffect:hover{

}
.fiveG img {
    height: 400px;
    width: 100%;
}
.fiveG {
    height: 400px;
    margin-top: -380px;
    background-image: url("/images/loading.gif");
    background-repeat: no-repeat;
}
.fiveG p{
        justify-content: center;

}
.padding40{
	padding-right: 80px !important;
}
.padding40Left{
	padding-left: 80px !important;
}