.handFreeInvesting{
	padding: 50px !important;
	 font-family:Arial;

}
.handFreeInvesting label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.handFreeInvesting p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}

.handFreeInvesting a{
	text-decoration: underline;
}
/*.paraeff{
  position: relative;
  left :50px !important;
  top:-430px;
  opacity:1 !important;
  transform: rotate(0deg);
  transition-duration: 1.5s;
}
*/.para0{
  position: relative;
/*  left:-500px;
*/  top:-400px;
  opacity: 1;
  transform: rotate(0deg);
  transition-duration: 1.5s;

}
.heightAuto{
  height: auto;
}
.ht500{
	height: 610px;
}
.learnMoreHF a{
  padding: 20px;
  color: #201a47 !important;
  font-size: 15px;
  text-decoration: underline;
     
}
.startInterestingButtonHF:hover{
    box-shadow: inset 100px 0px 1px 0 #00BAF2;
    box-shadow: inset 0px 100px 1px 0 #00BAF2;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.startInterestingButtonHF{
  padding: 18px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.03em;
  background-color: #00819D;
  box-shadow: inset 0 0 0 0 #00BAF2;
    transition: 0.5s all ease-in-out;
}
.imageContainerHF{
  height: 400px;

}
.imageContainerHF img{
  height: 100%;
  width: 100%;
}

.handFreeInvestingImgContainer{
  height: 400px;
  margin-top: -470px;
  background-image: url("/images/loading.gif");
  background-repeat: no-repeat;


}
.handFreeInvestingImgContainer img{
  height: 400px;
  width: 100%;
}