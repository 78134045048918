.modalBodyCustom{
	padding: 40px !important

}
.modalBodyCustomSmall{
  padding: 10px !important;
  height: 900px;
  background-color:#fff;
  
}
.modalHeaderCustom{
	background-color: #009dd7;
	border-top-left-radius:  3px;
	border-top-right-radius: 3px;
	color:#fff;
}
.modalHeaderCustom i{
	color: #60e8dd;
  font-size: 30px;
	opacity: 1;
}
.modalHeaderCustom h4{
	font-size: 21px;

}
/*other*/
.customModalRP{
	background-color: #fff !important;
	height: 600px !important;
/*  width: 800px !important;
*/  z-index: 10000 !important;
  margin-top: 10px !important;
  border-radius: 3px;
}
.customModalKYC{
	background-color: #fff !important;
	height: 700px !important;
/*  width: 800px !important;
*/  margin-top: 10px !important;
  border-radius: 3px;
}
.customModalEN{
	background-color: #fff !important;
	height: 460px !important;
/*  width: 800px !important;
/* margin-top: 10px !important;*/
  border-radius: 3px;
  margin-top: 8% !important;
}
/* Create a custom checkbox */
.centreDetailContainer{
  position: relative;
  padding-top: 5px;
  align-content: center;
  cursor: pointer;
  font-size: 10px;
  user-select: none;
  text-align: center;
}
/* Hide the browser's default checkbox */
.centreDetailContainer input {
     position: relative;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    height: 17px;
    width: 17px;
    right: 4px;
    top: -2px;
}
.centreDetailCheck {
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 3px;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
.radioCheck {
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
/* On mouse-over, add a grey background color */
.centreDetailContainer:hover input ~ .centreDetailCheck{
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.centreDetailContainer input:checked ~ .centreDetailCheck {
  background-color: #201a47;
  border:2px solid #201a47;
}
/* Create the checkmark/indicator (hidden when not checked) */
.centreDetailCheck:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.centreDetailContainer input:checked ~ .centreDetailCheck:after {
  display: block;
}
/* Style the checkmark/indicator */
.centreDetailContainer .centreDetailCheck:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  align-content: center;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
/* On mouse-over, add a grey background color */
.centreDetailContainer:hover input ~ .radioCheck{
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.centreDetailContainer input:checked ~ .radioCheck {
  background-color: #201a47;
  border:2px solid #201a47;
}
/* Create the checkmark/indicator (hidden when not checked) */
.radioCheck:after {
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.centreDetailContainer input:checked ~ .radioCheck:after {
  display: block;
    background-color: transparent;
}
/* Style the checkmark/indicator */
.centreDetailContainer .radioCheck:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  align-content: center;
  border: solid white;
  border-width: 0 3px 3px 0;   


  transform: rotate(45deg);
}
.centreDetaillistItem{
  padding-left: 0px !important;
      font-family: 'PT Sans', sans-serif;

  font-size: 14px !important;
}
/*other css*/
:root
{
	--text: "Select values";
}
.multiple_select
{
	height: 30px !important;
	padding: 5px !important;
	-webkit-appearance: menulist;
	  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
font-family: 'PT Sans', sans-serif;

	position: relative;
}
.multiple_select::before
{
	content: var(--text);
	display: block;
  margin-left: 5px;
  margin-bottom: 2px;
}
.multiple_select_active
{
	overflow: visible !important;
	  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.multiple_select option
{
	display: none;
	width: 102% !important;
	margin-left: -4px !important;
    height: 18px;
	background-color: white;
}
.multiple_select_active option
{
	display: block;
}

.multiple_select option::before {
  content: "\2610";
}
.multiple_select option:checked::before {
  content: "\2611";
}
.css-1r4vtzz{
	width: 100% !important;
}
.css-48ayfv{
	width: 100% !important;
}
.css-1qprcsu-option{
	background-color: transparent !important;
}
#riskform p{
  font-size: 20px;
  color: #555 !important;
}

  .submitButtonRP{
    padding:10px;
    margin-top:25px;
    color: #fff;
    background-color: #201b47;
    cursor: pointer;

  }
  *:focus{
  	outline: none !important;
  }
  .pad40{
  	padding: 20px !important;
  	padding-top: 30px !important;
  }
.customInputKF{
  display: block !important;
  width: 100%;
    height: 34px;
    padding: 3px 0px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: none;
    border-bottom: 2px solid #ccc;
}
.inputContainerRP{
  padding: 10px;
  padding-top: 0px;
  /*height: 100px;*/
}
.errorMsg{
  min-height: 20px;
}
.formContainer{
  margin:10px;
  margin-top: 80px;
  margin-bottom: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,.075);
}
.kycImage{
  margin-top: 10%;
  height: 330px;

}
.kycImage img{
  height: 100%;
  width: 100%;
}
.submitButton{
  padding: 10px;
  color: #fff;
  font-weight: bold;
  cursor:pointer;
  font-size: 14px;
  margin-top: -4px !important;
  background-color: #009dd7!important;
}

.inputContainerRP label{
	color:#333 ;
	font-size: 15px;
}
.logoText{
  font-size: 30px !important;
}
.readyTo{
  font-size: 45px !important;
  color: #201a47;
}
.ht150{
  height: 150px !important;
}
