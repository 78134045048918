.footerContainer{
	border-top:10px solid #201A47;
	padding: 0px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	padding-top: 20px !important;
}
.footerDivFirst{
 font-family:Arial;

}
.footerDivFirst label{
color: #201A47;
    font-size: 18px;
}
.footerDivFirst ul>li{
	cursor: pointer;
	font-size: 14px;
	color: #5b5b5b;
	letter-spacing: 0.02em;
	margin-bottom: 5px;
}
.footerDivFirst ul{
	padding-left: 0px !important;
	list-style: none !important;
}
.borderRadi{
	border-radius: 2px !important;
}
.inputContainerEH{
	 border: 1px solid #aaa;
    height: 32px;
    margin-top: 10px;
    border-radius: 3px !important;
    padding: 0px !important;
}
.customInput{
	padding: 15px;
	border-radius: 0px !important;
    height: 30px !important;
    border: none;
   font-family:Arial;

}
.customInputEH:focus{
	outline: none !important;
}
.searchIcon{
	height: 30px;
	padding: 5px;
	cursor: pointer;
	background-color: #201A47;
	color: #fff;
}
.searchIcon:hover{
		background-color: #333;

}
.imgContainer{
	margin-top: 1px;
}
.footerLeftCss{
	padding: 15px;
	font-family: Arial;
}
.sitelink{
	color: #FFFFFF !important;	
}
.footerRightCss{
	padding: 15px;
	font-family: Arial;
	display: none;
	text-align: right;
}
