.sbbannerWall{
	height: 535px;
	/*background-color:  #444;*/
	padding: 0px !important;
	position: relative;
  	text-align: center;
  	color: white;
  	/*background-image: url("/images/blogBack.jpg");*/
  	background-size: 100% 100%;

}
.nopadding{
	padding: 0px;
}
.sbcentered {
  background-color: #19181847;
  padding: 0px !important;
  height: 535px;

}
.sbbtextcentered{
  top: 25%;
}
.fs40{
  font-size: 40px;
  margin-top: 40px;

}

.fs72{
  font-weight: 600;
  font-size: 72px;
}
.fs24{
  font-size: 22px;
}
.ht100{
  height: 100px;
}