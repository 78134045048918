.resendOtpColor{
	margin-left: 0px !important;	
}

.parteroptresent{
	font-size: 14px;
    text-align: center;
    padding: 5px;
    margin-left: 7px !important;
    margin-right: 7px !important;
    width: 93% !important;
    border-radius: 4px;
    font-weight: bold;
    background: #d8d8d8!important;
    cursor: pointer;

}