.cuformWall{
	height: 500px;
	margin-bottom:40px !important;
	/*background-color: #aaa;*/
}
.bt40{
		margin-bottom:40px !important;

}
.bt30{
		margin-bottom:  10px;
	}
.sbtn{
	    /*background: -webkit-linear-gradient(left,#ffc600 0%,#ff4200 50%,#ffc600 100%);*/
	    height: 50px;
	     background-color: #407dfa !important;
   		 border-radius: 25px !important;
    	 color: #FFF!important;

	    font-weight: 600;
	    font-size: 16px;
}
.form-control{
	height: 44px!important;
}