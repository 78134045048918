.titleContainer{
	text-align: center;
}
.titleContainer label{
	color: #201a47 !important;
	font-size: 26px !important;
	padding: 20px;

}
.productContainer{
	height: auto;
	padding: 20px;
	margin-top:20px;
	margin-bottom:20px;
	line-height: 25px;
	font-family: 'PT Sans', sans-serif;
	box-shadow: 5px 5px 5px 5px #aaa;
}
.productContainer label{
	color:#007993 ;
	font-size: 18px;
}
.imageContainer{
  padding-top: 5%;
  padding-bottom: 5%;
  height: auto;
}
.imageContainer img{
	height: 100%;
	width: 100%;
}
.customTablePP tr> td,th{
	border:1px solid #aaa;
	padding: 20px !important;
}
.customOl{
	    padding-left: 15px !important;
/*      list-style: none;
*/}
.customOl li{
	padding:5px !important;
}
.customOl li>a{
text-decoration: none !important}
.customOlOther{
      padding-left: 15px !important;
     list-style: none;
}
.customOlOther li{
  padding:5px !important;
}
.specifications{
	padding:10px;
}
.buyNowButtonPP{
	color: #fff;
	background-color: #201a47;
	padding: 12px;
  margin-top: 20px;
  font-size: 16px;

	cursor: pointer;
}

.enquireNow{
	color:#201a47;
	font-weight: bold;
	padding: 10px;
    margin-top: 20px;

  border:2px #201a47 solid;
	cursor: pointer;
	font-size: 16px;
}
.colored{
		color:#201a47 !important;
		font-weight: bold;
		cursor: pointer;
}
.lineSpace{
	line-height: 30px;
}
.investBlock{
	font-size: 18px;

}
.chartContainer{
	height: auto;
  text-align: center;
  padding: 10px;
  font-family: Arial;
}
.customIcon{
  padding: 20px;
  color: #fff;
  font-size: 20px !important;
  background-color: #201a47;
  border-radius: 50%;
  margin:10px;

}
.blueColor{
  color: #007993;
}
.listStyleNone li>p{
  padding: 10px;
  line-height: 30px;
  font-size: 20px;
}
.buyShares{
  text-align: center;
  font-family: Arial;
  padding: 40px !important;
}
.buyShares label{
  font-size: 40px;
  color: #201a47;
}
.chartContainer label{
color: #201a47;
font-size: 30px;
}
.chartContainer img{
	height: 100%;
	width: 100%;
  border:2px solid #aaa;
}
.nyscContainer{
	height: 300px;
}
.nyscContainer img{
	height: 100%;
	width: 100%;
}
.userImage{
	height: 50px;
	background-color: #333;
	border-radius: 50%;
}
.listStyleNone{
	list-style: none !important;
	padding-left: 0px !important;
}
.listStyleNone li{
	padding-top:8px !important;
}

/*other*/
.btn { 
  padding:6px; width:100%; border-radius:2px;
  background-color:#fff; color:#333;
  border:1px solid #777;  text-align:left; }
.btn-select { position:relative; }
.btn-select:after {
  content:"";
  display: block; 
  position:absolute; top:50%; right:10px; margin-top:-2px;  
  width: 0;
  height: 0;
  border-top: 4px solid #777;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown-menu-select { padding:0; margin-top:-2px; width:100%; border-radius:0 0 2px 2px; border-color:#777;}

.dropdown-radio { 
  display:block; position:relative;
  margin:0;
  width:100%; overflow:hidden; text-overflow:ellipsis;
  border-bottom:1px solid #eaeaea; 
  cursor:pointer;
}
.dropdown-radio input { 
  visibility: hidden; 
  position:absolute; left: -30px; }
.dropdown-radio i { 
  font-weight:normal; font-style:normal; 
  display:block; padding:7px; }

  .customDrop{
  	text-align: left !important;
  	border-radius: 2px !important;
  	overflow: hidden;
  }
  .dropdown-radio input{
  	visibility: visible !important;
  	left:7px;
  	top:7px;
  }
  .dropdown-radio i{
  	margin-left: 15px !important;
  }
  .titileName{
    color: #007993;
    font-size: 14px;
  }

  .hight400{
    height: 500px;
  }
  /*kyc css*/
  .formNameTitle {
    font-size: 30px;
    margin-top: 0%;
    /* margin-bottom: 13%; */
    padding: 10px;
    color: #201a47 !important;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif !important;
}

.hight450{
  height: 400px !important;
}
.backColorYellow{
  background-color: #FED880;
  padding: 40px;
  margin-bottom: 40px;
}
.backColorYellow span{
  font-size: 20px;
}
.backColorYellow label{
  font-size: 40px;
}
.sutableForHead{
  text-align:center !important;
    font-family: Arial;

}
.sutableForHead label{
  font-size: 40px;
  padding-top: 20px;
  color:#201a47;
}
.numberDiv{
  padding: 10px;
  color: #201a47;
}
.imgInfo{
/*  background-color: #FED880!important;
*/  height:auto; text-align: center;
  font-family: Arial;
  padding: 20px;
}
.imgInfo label{
  padding-top:20px;
  font-size: 40px !important;
  color:#201a47 !important;
}
.imgInfo p{
  font-size: 21px;
  padding: 10px;
  line-height: 30px;
  padding-top: 20px;

}
.listStyle{
  list-style: circle;
}
.backColorDBlue{
  background-color: #00819D;
}
.mt100{
  margin-top: 10%;
}
.numberOfDiv{
  padding: 20px;
  text-align: center;
  font-size: 20px;
  background-color: #00819D;
  color: #fff;
  border-radius: 50%;
  margin:5px;
}
.textOfProductDesc{
  font-size: 18px;
  font-weight: bold;
}

/*accordian css*/
a:hover,a:focus{
    text-decoration: none;
    outline: none;
}
#accordion .panel{
    border: none;
    border-radius: 3px;
    box-shadow: none;
}
#accordion .panel-heading{
    padding: 0;
    border: none !important;
    border-radius: 3px;
}
.panel-group{
  margin-bottom: 0px !important;
}
#accordion .panel-title a{
    display: none;
    background: #fff;
    font-size: 18px;
    font-weight: bold;
    color: #f81ac1;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    position: relative;
    transition: all 0.5s ease 0s;
}
#accordion .panel-title a.collapsed{
    box-shadow: none;
    color: #676767;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body{
  border-top: none !important;
}
#accordion .panel-title a:before,
#accordion .panel-title a.collapsed:before{
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    width: 25px;
    height: 25px;
    line-height: 28px;
    font-size: 15px;
    font-weight: 900;
    color: #f81ac1;
    text-align: center;
    position: absolute;
    top: 8px;
    right: 15px;
    transform: rotate(135deg);
    transition: all 0.3s ease 0s;
}
.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body{
  border-bottom: none !important;
}
#accordion .panel-title a.collapsed:before{
    color: #676767;
    transform: rotate(0);
}
#accordion .panel-title a:after{
    content: "";
    width: 1px;
    height: 100%;
    background: #ececec;
    position: absolute;
    top: 0;
    right: 55px;
}
#accordion .panel-body{
    padding: 10px 15px 15px;
    border: none;
    font-size: 15px;
    color: #615f5f;
    line-height: 27px;
}
.customPanelPP{
  border:none !important;
  text-align: left !important;
}
/*.customPanelPP .customHead{
  background-color: transparent !important;
}*/
.customHead a{
  color: #333 !important;
  font-weight: normal !important;
}
/*end*/
.bannerContainerSH{
    margin-top: -1px;

  background-image: url("/images/safeHBack.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerSF{
    margin-top: -1px;

  background-image: url("/images/superfocused.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerUS{
  margin-top: -1px;
  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/bannerImg1.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerSHM{
  margin-top: -1px;
  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/bannerImg11.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerFiveG{
    margin-top: -1px;

  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/safeH.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerStocks{
    margin-top: -1px;

  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/usS.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.blackDivPP{
  padding-top: 100px !important;
  padding: 60px !important;
   font-family:Arial;

  background-color: rgba(0,0,0,0.5) !important;
}
.blackDivPPSmall{
  padding-top: 50px !important;
  background-color: rgba(0,0,0,0.5) !important;
}
.riskDivPP{
  background-size: 100% 100%;
  margin-top: 40px;
  background-attachment: fixed;
  color: #fff;
  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/introToSHM.jpg");
}
.SftestimonialDivPP{
  background-size: 100% 100%;
  margin-top: 40px;
  /*background-attachment: fixed;*/
  color: #fff;
  background-image: url("/images/SFtestimonial.png");
}
.riskDivPP p{

}
.rriskDivPPiskDiv label{
  margin-top:10px;
  font-weight: normal;
  font-size: 20px;
  line-height: 47px;

}
.blogQuate{
  margin-top:20px;
  line-height: 70px;
  font-weight: bolder;
  font-size: 40px; 
}
.sfblogQuate {
    margin-top: 20px;
    line-height: 32px;
    font-weight: 500;
    font-size: 22px;
    /*border: none;*/
    padding-left:0px;
}
.centeredDiv{
  padding-top: 70px;
}
.text-left{
  text-align: left;
  padding-left: 20px;
  float:left;
}
.blogQuateSmall{
   margin-top:20px;
  font-weight: bolder;
  font-size: 35px; 
}
.nameOfAuther label{
  font-size:30px;
}
.addDiv{
  height: 100px;
}
.marginLeft10{
  margin-left: 20px;
}