
.startTrandingContent{
	padding: 80px !important;
	margin-top: 40px !important;
	 font-family:Arial;
    justify-content: center;


}
.startTrandingContent label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.startTrandingContent p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}

.startTrandingContent a{
	text-decoration: underline;
}

.startTrandingContentSmall{
	padding: 45px !important;
	 font-family:Arial;
    justify-content: center;

}
.startTrandingContentSmall label{
	font-size: 4rem;
	line-height:1.2;
	font-weight: bold;
}
.startTrandingContentSmall p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}

.startTrandingContentSmall a{
	text-decoration: underline;
}
.startTrandingImgContainer{
	height: 400px;
	margin-top: -500px;
}
.startTrandingImgContainer img{
	height: 400px;
	width: 100%;
}
.usStocksContainer{
	height: 400px;
	margin-top: -502px;
	  background-image: url("/images/loading.gif");
  background-repeat: no-repeat;
}
.usStocksContainer img{
	height: 400px;
	width: 100%;
}
.startTrandingImgContainerSmall img{
	height: 100%;
	width: 100%;
}
.startInterestingButton{
	padding: 18px;
	text-align: center;
	color: #fff;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 0.03em;
	background-color: #00819D;
}
.learnMore{
	padding: 17px !important;
	padding-left: 25px !important; 
}
.learnMoreST p{
  text-align: justify;

}
.learnMoreST a{
	font-weight: bold;
	padding: 0px !important;
	color:#1db1f1 !important;
	font-size:18px;
}
.pad50{
	padding-top:50px;
}
/*.paraeff4{
  position: relative;
  right:40px !important;
  opacity:1 !important;
  transform: rotate(0deg);
  transition-duration: 1.5s;
}
.para2{
  position: relative;
  right:-500px;
  top:-30px;
  opacity: 0;
  transform: rotate(0deg);
  transition-duration: 1.5s;

}
*/