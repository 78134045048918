.headerServices{
	padding: 10px;
	padding-bottom: 20px;
	font-size: 25px;
	border-bottom: 1px solid #aaa;
}
.blogContainCD{
	border-radius: 10px;
	margin-top:40px; 

	border: 1px solid #aaa;
	padding: 20px !important;
	padding-top: 30px !important;
}
.blogContainCD h4{

	text-align: center;
}	
.headerBlogCD{
	font-size: 25px;
	padding-bottom: 20px;
	padding-top: 20px;
	font-weight: bold !important;
	border-top: 1px solid #aaa;
}
.customTabContent{
	padding: 20px !important;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 2px 6px 4px #aaa;
    min-height: 480px !important;
}
.customStack{
	background-color:	#201a47;  
	border-radius: 10px;
	padding: 20px !important;
		cursor: pointer;

}
.customStack>li.active>a, .customStack>li.active>a:focus, .customStack>li.active>a:hover{
	border-radius: 3px !important;
	background-color:#00BAF2 !important;
	color: #333;
	cursor: pointer;
}
.customStack>li.active>a:hover{
 	color: #333 !important;
 		cursor: pointer;

}
.planContainer{
	padding: 15px !important;
	margin-bottom: 40px;
	font-family:Arial;
	border-radius: 10px;
	color: #aaa ;

}
.planContainer label{
	color: #333 !important;

}
.customStack li>a{
	background-color: #ddd;
	font-size: 16px;
	border:1px solid #aaa;
	padding: 15px !important;
	color: #666;
	margin-bottom:10px !important; 
}
.performancetab a, .kyctab a, .riskprofiletab a  {
	border:none !important;
	background-color: #201a47 !important;
    color: #fff !important;
    width: 370px;
    border: none !important;
}
.performancetab li:hover a, .kyctab li:hover a, .riskprofiletab li:hover a{
	background-color: #337ab7 !important;
}
.customTabContent h3{
 text-align: center;
 color: #201a47;
}
.customTabContent h5{
 text-align: center;
 color: #333;
}
.clickToPlan{
	color: #00f;
	font-size: 12px;
	cursor: pointer;
}
.myULCD li{
	cursor: pointer;
	font-size: 15px;

}
.myULCD a>li:hover{
	color: #aaa !important;
	text-decoration: none !important;
}
.myULSS li:hover{
	text-decoration: none !important;
	box-shadow: 0px 2px 14px 2px #aaa;
	
}
.myULSS li{

	cursor: pointer;
	font-size: 15px;
	list-style: none;
	background-color: #00baf2;
	margin-bottom:10px;
	border-radius: 30px;
	padding: 8px !important;	
	padding-left: 15px !important;	
	padding-right: 15px !important;	
	
}
.myULSS li>a{
	color: #fff;
	
}

.myULSS li>a:hover{
	color: #00f !important;
	text-decoration: none !important;
}
.myULCDSP{

	padding-left:0px !important;
}
.myULCDSP li{
	font-size: 15px;
	color: #666  !important;
	padding-bottom: 0px !important;
	list-style: none !important;
}
.myULCDSP li>a{
	color: #666  !important;
}
.myULCDSP li>a:hover{
	text-decoration: none !important;
	color: #666 !important;
}
.priBlogHead{
	font-size: 20px;
	color: #201a47;
}
.padding100{
	padding-bottom: 50px;
}
.activeSubscription{
	background-color: #24baf2 !important;
	color: #333 !important;
}
.customStack>li>a:hover{
	background-color: #0edaef !important;
    color: #333 !important;
}
.marLeft{
	margin-left: 20%;
}
.mainContainerSS{
	background-color: #00BAF2;
}
.performancetab{
	position: absolute !important;
    left: 324%;
    top: 6px;
    width: 100%;
    z-index: 1000 !important
}
.performancetabSmall{
	position: absolute;
    left: 0px;
    top: 101em;
    width: 100%;
    z-index: 1000 !important;

}
.kyctab{
	position: absolute !important;
    left: 324%;
    top: -116px;
    width: 100%;
    z-index: 1000 !important
}
.kyctabSmall{
	position: absolute;
    left: 0px;
    top: 61em;
    width: 100%;
    z-index: 1000 !important;

}
.riskprofiletab{
	position: absolute !important;
    left: 324%;
    top: -55px;
    width: 100%;
    z-index: 1000 !important
}
.riskprofiletabSmall{
	position: absolute;
    left: 0px;
    top: 81em;
    width: 100%;
    z-index: 1000 !important;

}
.marginTopM
{
	margin-top: -60px;
}
.borderTop{
	border-top:1px solid #aaa;
	padding-top: 10px;
}
.height310{
	height: 310px;
}
/*.customStack:first-child {
  background-color: yellow;
}
*/
.ht200{
	height: 100px;
    padding: 10px;
    display: inline-block;
    /* width: 143px !important; */
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.borderRadiusT{
	border-radius: 3px;
}
.mt15{
	margin-top: 15px !important;
}
.sbscribblogbtn{
	margin-top: 10px !important;
}
.productinvestbtn{
	margin-top: 30px !important;
}
.kycprofilebtn{
	margin-bottom: 6px;
}
.nav .kycprofilebtn a:hover, .nav .kycprofilebtn a:active{
	background-color: #0edaef !important;
    color: #333!important;
}
.kycprofilebtn a{
	background-color: #201a47;
    color: #fff !important;
    font-size: 16px;
    padding: 15px!important;
}

#overlayproductexpired{
	position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0.5;
}

#productexpiredModal .modalHeaderCustom {
	z-index: 1111 !important;
}

.productexpiredbtn{
	color: #ffffff !important;
	padding:10px;
}	

@media (min-width: 768px){ 
.productexpiredmodal-dialog {
    width: 510px !important;
    margin: 30px auto;
    height: 360px !important;
    margin-top: 12%!important;
}
.pad50new{
	padding: 0px 50px 30px 50px !important;
}
}

.disbledkycrisk{
	color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

@media (max-width: 767px){ 
	.headerBlogCD{
		margin-top: 30%;
	}
	.premiumsbc{
		margin-top: 50% !important;
	}
}