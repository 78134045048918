.riskprofileformbox{
	/*margin-top: 80px;*/
    /*margin-bottom: 80px;*/
    padding: 0 0 20px!important;
    box-shadow: 0 0 5px 5px rgba(0,0,0,.075);
    padding: 2% !important; 
}
.risksubmitButton {
    padding: 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border: 0px;
    text-align: center;
    font-size: 14px;
    margin-top: 20px !important;
    background-color: #009dd7!important;
}
.ansoptions{
    color: #333;
}