.mapouter{
	position:relative;
	text-align:right;
	height:420px;
	width:100%;
	padding: 0px;
}
.gmap_canvas{
	overflow:hidden;
	background:none!important;
	height:400px;
	width:100%;
}
.cuMapWall{
	margin-top: 50px;
	padding: 0px;
}