.oneOnOneAdvice{
	padding: 40px !important;
	    justify-content: center;

	 font-family:Arial;
	padding-top:40px !important;
}
.oneOnOneAdvice label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.oneOnOneAdvice p{
	margin-top: 0.2em;
	
}
.oneOnOneAdvice a{
	text-decoration: underline;
}
.yearP{
	font-weight: bold;
}
.percentage{
	font-weight: bold;
	font-size:5rem;	
}
.terms{
	font-size: 1.5em;
}
.oneOnOneAdvice p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}
