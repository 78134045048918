.RelatedBlogsBox{
	background-color: #fafafa;
	padding: 20px;
}
.mtop75{
	margin-top: 85px; 

}
.r1blog1:hover .blogTitle{

	color: #1db1f1;

}
.r1blog1 a:hover{
		text-decoration: none;

}
.blogTitle{
	font-size: 18px;
	margin: 5px 0px;
}
.blogImgB{
	height: 173px!important;
	width: 100%;
}
.rblog{

	position: relative;
	height: 330px;
	overflow: hidden;
	margin-bottom: 50px;
	background-color: #fff;
    padding: 0px !important;
	box-shadow: 2px 2px 15px 4px #aaa;

}
.r1blog1{
	height: 375px;
	margin-bottom: 50px;
	/*background-color: #aaa;*/
	box-shadow: 2px 2px 15px 4px #aaa;
}
.p10{
	padding-left:  25px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}
.premiumBlogIndicateRB{
    position: absolute;
    left: -20px;
    top: 14px;
    padding-left: 20px;
    padding-right: 20px;
    transform: rotate(-40deg);
    background-color: #f5d26e;
    color: #333;
    font-size: 16px;
}