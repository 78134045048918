.typesOfGrouth{
	padding: 40px !important;
    font-family: Arial;
    padding-top: 5% !important;
}
.typesOfGrouth p{
    font-size: 1.5em;}
.typesOfGrouth span
{
	font-size: 40px;
}
.suitableForDiv{
    font-family: Arial;
}
.suitableForDiv p{
	 font-size: 18px;
}
.suitableForDiv span
{
	font-size: 40px;
}