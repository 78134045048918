.cubannerWall{

	height: 530px;
	/*background-color:  #444;*/
	padding: 0px;
	position: relative;
  	text-align: center;
  	color: white;
  	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/cubanner.jpg");
  	/*background-size: 100% 100%;*/
  	background-position: bottom;
  	background-attachment: fixed;

  	opacity: 0.8;
  	z-index: -1;
/*  	overflow: hidden;
*/  	transform: translate3d(0px, 0px, 0px);
}
.nopadding{
	padding: 0px !important;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 68px;
  font-weight: 600;

}
.cucentered{
   background-color: #191818ba;
  padding: 0px !important;
  height: 435px;
}
.cutextcentered{
   position: absolute;
  top: 22%;
  left: 0%;
}