.iconContainerCD{
color: #fff;
background-color: #25bbf3;
padding: 20px;
font-size: 25px !important;
border-radius: 50%;

}
.payZeroCD{
	font-size: 17px;
	line-height: 25px;
	color:#666;
	 font-family:Arial;
	font-weight: normal !important;
	word-spacing:0em;
	margin-top: 10px;
	padding:10px;
}
.subDivCD{
	text-align: center;
		cursor: pointer;


}
.subDivCD i{
	transition-duration: 0.5s;

}
.subDivCD:hover i{
	transform: scale(1.2);
	transition-duration: 0.5s;
	cursor: pointer;
}
.investContainerCD{
	text-align: center;
	padding: 20px;
	padding-bottom: 50px;
/**/		 font-family:Arial;

}
.investLabelCD{
	font-weight: bold;
	padding: 60px;
	font-size: 40px;
	color:#201A47;
}
.height500{
	height: 650px;
}
.minusMargin{
		margin-top: -80px;

}