@media all and (max-width : 768px) {

  .customUl li> a.newinvestlink{
    padding: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    width: 100%;
    margin: 0px;
  }   
  .newinvestspan{
    padding: 0px !important;
    width: 100%;
    text-align: center;
  }
  .investNowHeadBefore{
    width:100% !important;
  }

}
.investNowHead{
  margin-top: 8px;
  padding:8px;
  color: #201A47;
  font-weight: bold;
  background-color: #fff !important;
  height: 30px;
  padding-top:5px !important;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #00BAF2;
  transition: 0.5s all ease-in-out; 
}
.fs10{
  font-size: 12px !important;
}
.mt50{
  margin-top: 50px;
}
.mt8{
  margin-top: 8px;
}

.investNowHeadOther{

  padding:15px;
  padding-top:0px;
  color: #fff;
  font-weight: bold;
}
.investNowHeadOtherSmall{

  padding:15px;
  padding-top:0px;
  font-weight: bold;
}
.investNowHead:hover{
    box-shadow: inset 100px 0px 1px 0 #00BAF2;
    box-shadow: inset 0px 100px 1px 0 #00BAF2;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.investNowHead:hover span{
    color: #fff !important;

}
.investNowHeadBefore{
  background-color: #fff !important;
  height: 30px;
  width: 120px;
  margin-top: 5px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #00BAF2;
  transition: 0.5s all ease-in-out; 

}
.investNowHeadBefore span{
  padding:20px;
  color: #201A47;
  font-weight: bold;
}
.investNowHeadBefore:hover{
    box-shadow: inset 100px 0px 1px 0 #00BAF2;
    box-shadow: inset 0px 100px 1px 0 #00BAF2;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.investNowHeadBefore:hover span{
    color: #fff !important;

}
.h1zindex{
  z-index: 10000;
}
.subText{
    font-style: italic;
    font-family:Arial;
    color:#5f9c30;
}
.miniHeaderDesolved{
    height: auto;
    position: fixed;
    z-index: 1002;
    transition-duration: 0.8s;
    border-bottom: 1px solid #aaa;
    background-color: #fff;
 font-family:Arial;
}
.contactDetails {
    margin-top: 18px;
}
.greenColor{
  background-color: #5f9c30 !important;
}
.greenColor:hover{
  background-color: #5f9c30 !important;
}
.fs18{
    font-size:18px;
    color: #5f9c30;
}
.mt60{
  margin-top: 50px;
}
.contactLabal{
    font-size: 13px;
    padding-top: -5px;
}

.borderLeft{
    border-left: 1px solid #aaa;
    height: 20px;
    padding: 0px;
    margin-left: 25px;
    color: #999;
    padding-top: 2px;
    padding-left: 10px;
    font-size: 12px;
}
.myAccoutText{    
    color: #999;
    font-weight: normal;
    font-size: 12px;

}
.nav i{
    font-weight: bold;
}
.rightDivContainer img{
    height: 25px;
    cursor: pointer;
    margin-left: 15px;
}

.logoImageContainer{
    height: 46px;
}
.logoImageContainer img{
    margin-top: 20px;
    height: 100%;
    margin-left: 10px;
    width: 100%;
}
.headerMenu{
    margin-top: 5px;
}
.nav>li>a:hover {
    background-color: transparent !important;
}
.navbar-nav>li>a {
    padding-top: 15px;
    margin-right: 14px;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 14px !important;
 font-family:Arial;

    color:#333 !important;
}
.otherClass li>a:hover{
background-color: #5f9c30 !important;
}
.navText{
    background-color: transparent !important;
    margin-top: 21px;
    margin-left: 29px;
    margin-top: 24px;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: #5f9c30;
    border-color: #337ab7;
}
.backColor{
    height: 110px;
    color: #fff;
}
.cartImg{
    margin-top: 32px;
    cursor: pointer;
}
.mt5{
    background-color: #5f9c30;
    color: #fff;
    display: inline-block;
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 28px;
    text-align: center;
    margin-left: -7px;
    margin-top: 30px !important;
}
/*.customDropdown{
  height: auto;
  background-color: #201b47 !important;
  color: #fff !important;
    min-width: 250px !important;
    padding-left: 15px !important;
 font-family:Arial;


}*/

.customUl li> a.newinvestlink{
    padding: 5px !important;
    padding-left: 7px !important;
    padding-bottom: 7px !important;
    width: 100%;
    margin: 0px;
}
.newinvestspan{
  padding: 9px !important;
  width: 100%;
  text-align: center;
}


.headLi{
    font-size: 15px;
    font-weight:bold;
    margin-top: 10px;
    padding-left: 10px !important;
    color: #666;
}
.noStyle{
    padding-left: 10px !important;
    margin-left: 188px !important;
}
.noStyle li{
    list-style: none;
    padding-top: 10px;
    color: #999;
}
.noStyle>li>a{
    color: #999 !important;
}
.hello{
    z-index: 1000!important;
}
.headerflow{
    position: fixed;
  z-index: 10000;
  background-color: transparent;
  transition-duration: 0.8s;
}
.headerflowscroll{
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  height: 100px;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.8s;
}

#container {
  margin: 0 auto;
  max-width: 890px;
}
 .toggle, [id^=drop] {
 display: none;
}
nav {
  margin-top: 37px;
  padding: 0;
}

#logo {
  display: block;
  padding: 0 30px;
  float: left;
  font-size: 20px;
  line-height: 60px;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  float: left;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  margin: 0px;
  display: inline-block;
  float: left;
  background-color: transparent;
  width: auto;
}

nav a {
  display: block;
  padding: 0 13px;
  color: #666;
  line-height: 35px;
 font-family:Arial;

  text-decoration: none;
}

nav ul li ul li:hover { background: #eee; }

nav a:hover { color: #f08f00;
text-decoration: none; }

nav ul ul {
  display: none;
  position: absolute;
  top: 35px;
  background-color: #fff;
  box-shadow: 0 10px 29px rgba(92,141,255,.5);
  height: 0px;

}

nav ul :hover > ul { display: inherit; }

nav ul ul li {
  width: auto;
  float: none;
  display: list-item;
  position: relative;
  background-color: #fff;
}

nav ul ul ul li {
  width:322px;
  position: relative;
  top: -35px;
  left: 284px;
  background-color: #fff;
}
nav ul ul ul ul li {
  width:360px;
  position: relative;
  top: -35px;
  left: -350px;
  z-index: 100;
  background-color: #fff;
}
nav ul ul ul ul ul li {
  width:310px;
  position: relative;
  top: -35px;
  left: -305px;
  background-color: #fff;
}


li > a:only-child:after { content: ''; }




@media all and (max-width : 768px) {


#logo {
  display: block;
  padding: 0;
  width: 100%;
  text-align: center;
  float: none;
}

nav { margin: 0; }

.toggle + a,
 .menu { display: none; }

.toggle {
  display: block;
  background-color: #254441;
  padding: 0 60px;
  color: #eee;
  font-size: 22px;
  font-weight:bold;
  line-height: 60px;
  text-decoration: none;
  border: none;
}

.toggle:hover { background-color: #000000; }

[id^=drop]:checked + ul { display: block; }

nav ul li {
  display: block;
  width: 100%;
}

nav ul ul .toggle,
 nav ul ul a { padding: 0 40px; }

nav ul ul ul a { padding: 0 80px; }

nav a:hover,
 nav ul ul ul a { background-color: #000000; }

nav ul li ul li .toggle,
 nav ul ul a { background-color: #212121; }

nav ul ul {
  float: none;
  position: static;
  color: #eee;
}

nav ul ul li:hover > ul,
nav ul li:hover > ul { display: none; }

nav ul ul li {
  display: block;
  width: ;
}

nav ul ul ul li { position: static;

}
}

@media all and (max-width : 330px) {

nav ul li {
  display: block;
  width: 94%;
}

}

.headerlogo img{
  margin-top: 15px;
  height: 75px;
}
.zindex{
}
dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
.geoHover:hover~ul>li{
  z-index: 300;
}
.miniHeaderDesolved{
    height: 55px;
    border-bottom: 1px solid #aaa;
    background-color: #fff;
 font-family:Arial;
}
.contactDetails {
    margin-top: 18px;
}
.fs18{
    font-size:18px;
    color: #5f9c30;
}
.contactLabal{
    font-size: 13px;
    padding-top: -5px;
}

.borderLeft{
    border-left: 1px solid #aaa;
    height: 20px;
    padding: 0px;
    margin-left: 25px;
    color: #999;
    padding-top: 2px;
    padding-left: 10px;
    font-size: 12px;
}
.myAccoutText{    
    color: #999;
    font-weight: normal;
    font-size: 12px;
}
.nav i{
    font-weight: bold;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 0px !important;
  transition-duration: 0.8s;
  border-bottom:1px solid #ccc;

}
.customClass{
  margin:5px !important;
}
.customNavBar{
  position: absolute !important;
  width: 100%;
  border-radius: 0px !important;
  background-color: #201A47 !important;
  border:none !important;
  min-height: 40px !important;
  padding-left:35px !important;
  padding-right:2px !important;
}
.customNavBarSmall{
  position: absolute !important;
  width: 100%;
  border-radius: 0px !important;
  background-color: #fff !important;
  border:none !important;
  /*border-bottom:  5px solid #201a47 !important;*/
  min-height: 40px !important;
  padding-right:2px !important;
}
.customNavBarUpper{
  border-radius: 0px !important;
  background-color: #201a47 !important;
  border:none !important;
  padding: 8px;

  padding-left:20px !important;
  padding-right:20px !important;
}
.width50{
  /*width: 56% !important;*/
  /*width: 69% !important;*/
}
.customUl li>a{
  font-weight: normal !important;
  color: #fff !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  font-size: 15px !important;
  letter-spacing: 0.03em;
}
.customUl li:hover{
  text-decoration: none;
}
.customUlSmall li>a{
  font-weight: normal !important;
  color: #333 !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  font-size: 15px !important;
  letter-spacing: 0.03em;
}
.customUlSmall li:hover{
  text-decoration: none;
}
.webSiteNameOther{
   font-family:Arial;
   margin-top: -13px;
  font-weight: bold;
  margin-left: -40px !important;
  font-size: 40px !important;
}
.webSiteNameOther img{
  height: 45px;
  margin-left: 20px;
}
.webSiteName{
  font-family:Arial;
  font-weight: bold;
  padding: 0px !important;
  font-size: 40px !important;
}
.webSiteImage {
  margin-top: -7px;
    height: 62px;
}
.webSiteImage img{
  height: 100%;
  width: 100%;
}
.investNowButtonHeader{
  background-color: #fff;
  color: #201a47;
  padding: 10px !important;
  font-weight: bold;
  text-align:center;
  margin-top: 0.5%;
  font-size: 13px;
  transition-duration: 1s;
  cursor: pointer;
}
.investNowButtonHeader:hover{
  transition-duration: 1s;
  background-color: #AB989D;
}
.loginButton{
  color:#fff;
  padding: 14px;
  cursor: pointer;
}
.headLi{
   color: #333 !important; 
}
.customDropdown>a{
    padding-left: 30px !important;
    padding-top: 8px !important;
    font-size: 14px;
    margin-left: -15px !important;
    text-decoration: none;
    background-color: transparent !important;
    color: #fff !important;
}
.customDropdown a:hover{
      background-color:#25bbf3!important;

}
.customDropdown{
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
     height: auto;
    min-width: 250px !important;
    padding-left: 15px !important;
    font-family:Arial;
    float: left;
    width: 100px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: circle;
    background-color: #201A47 !important;
    background-clip: padding-box;
    border:none !important;
    border-radius: 4px;
    color:#fff !important;
/*    box-shadow: 0 6px 12px rgba(0,0,0,.175);
*/
}.customDropdownSmall>a{
    padding-left: 30px !important;
    padding-top: 8px !important;
    font-size: 14px;
    margin-left: -15px !important;
    text-decoration: none;
    background-color: transparent !important;
}
.customDropdownSmall a:hover{
      background-color:#25bbf3!important;

}
.customDropdownSmall{
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
     height: auto;
    min-width: 250px !important;
    padding-left: 15px !important;
    font-family:Arial;
    float: left;
    width: 100px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: circle;
    background-clip: padding-box;
    border:none !important;
    border-radius: 4px;
/*    box-shadow: 0 6px 12px rgba(0,0,0,.175);
*/
}
.customDropdownLogOut>a{
    padding-left: 20px !important;
    font-size: 14px;
    margin-left: -15px !important;
    text-decoration: none;
    background-color: transparent !important;
    color: #333 !important;
}
.customDropdownLogOut a:hover{
      background-color:transparent!important;

}
.customDropdownLogOut{
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
     height: auto;
    min-width: 300px !important;
    padding-left: 15px !important;
    font-family:Arial;
    float: left;
    width: 100px;
    padding: 5px 10px;
    margin: 4px 0 0;
    font-size: 14px;
    padding-bottom:15px !important;
    text-align: left;
    list-style: circle;
    background-color: #fff !important;
    background-clip: padding-box;
    border:none !important;
    border-radius: 4px;
    color:#fff !important;
/*    box-shadow: 0 6px 12px rgba(0,0,0,.175);
*/
}
.listItem{
    background-color: #201A47;
    margin-left: -20px;
    padding: 5px;
}
.listItem:hover{
  background-color: #201b47 !important;
  text-decoration: none !important;
}

.customDropdown>li>a:hover{
  background-color: transparent!important;

}
.colorP{
   font-family:Arial;

  color: #201A47 !important;
}
.colorWhite{
  color:#fff !important;
}
.faceBook{
  color: #201b47;
  padding-top: 8px;
  margin-right: 10px;
  font-size: 15px;
  transform: scale(1);
  transition-duration: 0.5s;

}
.faceBook img{
 height: 30px;
}
.faceBook:hover{
  cursor: pointer;
  transform: scale(1.1);
    transition-duration: 0.5s;

}
.iconContainerHeader{
  margin-left: 15px;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
  background-color:transparent !important;
}
.cursorPointer{
  cursor: pointer;
}
hr{
  margin:5px !important;
}
.myprofileButton{
  border-bottom: 1px solid #afafaf69;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}
.marginTop{
  border-top: 1px solid #afafaf69 !important;

}
.myprofileButtonSmall{
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  color: #333;
}
.logOutButton{
  background-color: #f00;
  color: #fff;
  padding:10px;
  border-radius: 3px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}
.ImgDiv{
  height: 80px;
  text-align: center;
  font-size: 25px;
  margin-top: 14px;
  padding-top: 24px;
  padding-left: 14px !important;
  border-radius: 50%;
  background-color: #1db1f1;
}
.ImgDiv label{
  font-size: 20px !important;
  margin-top:  bold;
}
.mailClass{
      margin-top: -11px;
    font-size: 14px;
}
.fs19{
  font-size: 18px;
}
.mr15{
  margin-top:15px !important;
}
.enquirenowsidebtn{
  /*transform: rotate(90deg);*/
  position: fixed !important;
  right: 0px;
  top: 42%;
  background-color: #009dd7 !important;
  border: 2px #009dd7 solid !important;
  color: #FFF !important;
  z-index: 1;
  padding: 14px 19px !important;

}

.modal-open{
  padding-right: 0px !important
}

@media only screen and (min-width:1000px)  and (max-width:1250px){
  .width50{
    /*width: 75% !important;*/
  }
}

.headersociallinksmb{
  background-color: #fff;
  padding: 0px;
}
.headersociallinksmb:hover{
  background-color: #fff;  
}

.headerrwcontain{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sebicontain{
  margin-top: 48px;
  background-color: #f5f5f5;

}