.bannerContainer{
	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/5gcpmBack.jpg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding: 40px !important;
 font-family:Arial;
	height: 100%;

	color:#333;
}
.bannerContainerSH{
	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/safeHeaven.jpeg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
 	font-family:Arial;
	height: 100%;

	color:#fff;
}
.bannerContent{
	height: auto;
	margin-top:4%;
	margin-bottom: 4%;
}
.investButton{
	height: 35px;
	text-transform: uppercase;
	text-align: center;
	font-size: 20px;
	padding-top: 4px;
	cursor: pointer;
	background-color: #201A47;
	color: #fff;
}
.bannerContent label{
	font-size: 22px;
}
.divHeading{
	color: #fff;
	font-size: 4.9rem;
	margin-top: 1.25rem !important;
	font-weight: bold;

}
.divHeadingSmall{
	color: #fff;
	font-size: 3.3rem;
	margin-top: 1.25rem !important;
	font-weight: bold;

}
.bannerPara{
	margin-top: .75rem !important;
	font-size: 22px;
	line-height: 34px;
}
.bannerPara p{
	color: #fff;
}
.investNowButton{
	height: 55px;
	text-align: center;
	padding-top:16px;
	margin-top: 2.5rem !important;
	font-weight: bold;
	cursor: pointer;
	color: #111;
	word-spacing: 0.04em;
	    border:1px solid #fff;

	background-color:  #fff; 
	  box-shadow: inset 0 0 0 0 #000;
    transition: 0.5s all ease-in-out;	

}
.investNowButton:hover{
	  box-shadow: inset 100px 0px 1px 0 #000;
    box-shadow: inset 0px 100px 1px 0 #000;
    color:#fff;
    border:1px solid #fff;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.slideDownButton{
	height: 20px;
	cursor: pointer;
	color: #fff !important;
	font-size: 10px;
	padding-top: 8%;
}
.slideDownButton img{
	padding-bottom: 5px;
}
.slideDownButton span{
	margin-left: -35px;
}