.blockquote{
	margin: 27px 0px 27px 0px;
    padding: 0px 0px 0px 27px;
    background-color: #f4f4f4;
    border-left: 5px solid #201A47 !important;
}
.blogBox p{
	font-family: Arial;
	text-align: justify !important;
	color: #444;
	margin-top: 40px;
}

.blogBoxSmall p>big>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}
.blogBoxSmall p>strong>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}
.blogBoxSmall p>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}

.blogBox p>big>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}

.blogBoxSmall p{
	font-family: Arial;
	text-align: justify !important;
	color: #444;
	overflow-wrap: break-word;

}
.blogBoxSmall p>big>a{
	overflow-wrap: break-word;
}
.blogBoxSmall p>a{
	overflow-wrap: break-word;
}

.sCont{
	font-size: 14px;
}
.userbtn{
	padding: 8px 30px;
    width: 155px;
    margin-top: 20px;
    margin-bottom: 25px;
    cursor: pointer;
	border: 1px solid #dddddd;
	background-color: #fff;
	columns: #111;
}
.userbtn:hover{
	background-color: #dddddd;
	color: #fff;
}
.mt40{
	margin-top: 40px;
}