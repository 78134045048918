.valuesWeCreateContainer{
	height: auto;
	background-image:url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/backImageAU.jpg");
	background-size: 100% 100%;
	padding:40px;
	background-repeat: no-repeat;
	 font-family:Arial;
}
.floatingDivContainer{
/*		height: 450px;
*/		border-radius: 10px;
		margin-top: 10px;
		padding: 40px;
		text-align: center;
		color: #fff;
		background-image: linear-gradient(to right ,#201a47, #25bbf3);
}
.iconCircle{
	padding: 28px;
	font-size: 30px !important;
	border:1px solid #fff;
	border-radius: 50%;

}
.headingOfValue{
	padding-top: 20px;
	 font-family:Arial;
	font-size: 30px;

}
.paraDesc{
		font-size:18px;
		padding: 5px;

}
.missonButton{
	padding: 8px;
	padding-right:60px;
	padding-left: 60px;
	font-size: 18px;
	cursor: pointer;
	margin-top: 8%;
	border-radius: 30px;
	background-color: #201a47;
}