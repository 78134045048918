@import url(https://fonts.googleapis.com/css?family=Inconsolata:700);
@import url(https://fonts.googleapis.com/css?family=Inconsolata:700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media all and (max-width : 768px) {

  .customUl li> a.newinvestlink{
    padding: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    width: 100%;
    margin: 0px;
  }   
  .newinvestspan{
    padding: 0px !important;
    width: 100%;
    text-align: center;
  }
  .investNowHeadBefore{
    width:100% !important;
  }

}
.investNowHead{
  margin-top: 8px;
  padding:8px;
  color: #201A47;
  font-weight: bold;
  background-color: #fff !important;
  height: 30px;
  padding-top:5px !important;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #00BAF2;
  transition: 0.5s all ease-in-out; 
}
.fs10{
  font-size: 12px !important;
}
.mt50{
  margin-top: 50px;
}
.mt8{
  margin-top: 8px;
}

.investNowHeadOther{

  padding:15px;
  padding-top:0px;
  color: #fff;
  font-weight: bold;
}
.investNowHeadOtherSmall{

  padding:15px;
  padding-top:0px;
  font-weight: bold;
}
.investNowHead:hover{
    box-shadow: inset 100px 0px 1px 0 #00BAF2;
    box-shadow: inset 0px 100px 1px 0 #00BAF2;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.investNowHead:hover span{
    color: #fff !important;

}
.investNowHeadBefore{
  background-color: #fff !important;
  height: 30px;
  width: 120px;
  margin-top: 5px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #00BAF2;
  transition: 0.5s all ease-in-out; 

}
.investNowHeadBefore span{
  padding:20px;
  color: #201A47;
  font-weight: bold;
}
.investNowHeadBefore:hover{
    box-shadow: inset 100px 0px 1px 0 #00BAF2;
    box-shadow: inset 0px 100px 1px 0 #00BAF2;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.investNowHeadBefore:hover span{
    color: #fff !important;

}
.h1zindex{
  z-index: 10000;
}
.subText{
    font-style: italic;
    font-family:Arial;
    color:#5f9c30;
}
.miniHeaderDesolved{
    height: auto;
    position: fixed;
    z-index: 1002;
    transition-duration: 0.8s;
    border-bottom: 1px solid #aaa;
    background-color: #fff;
 font-family:Arial;
}
.contactDetails {
    margin-top: 18px;
}
.greenColor{
  background-color: #5f9c30 !important;
}
.greenColor:hover{
  background-color: #5f9c30 !important;
}
.fs18{
    font-size:18px;
    color: #5f9c30;
}
.mt60{
  margin-top: 50px;
}
.contactLabal{
    font-size: 13px;
    padding-top: -5px;
}

.borderLeft{
    border-left: 1px solid #aaa;
    height: 20px;
    padding: 0px;
    margin-left: 25px;
    color: #999;
    padding-top: 2px;
    padding-left: 10px;
    font-size: 12px;
}
.myAccoutText{    
    color: #999;
    font-weight: normal;
    font-size: 12px;

}
.nav i{
    font-weight: bold;
}
.rightDivContainer img{
    height: 25px;
    cursor: pointer;
    margin-left: 15px;
}

.logoImageContainer{
    height: 46px;
}
.logoImageContainer img{
    margin-top: 20px;
    height: 100%;
    margin-left: 10px;
    width: 100%;
}
.headerMenu{
    margin-top: 5px;
}
.nav>li>a:hover {
    background-color: transparent !important;
}
.navbar-nav>li>a {
    padding-top: 15px;
    margin-right: 14px;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 14px !important;
 font-family:Arial;

    color:#333 !important;
}
.otherClass li>a:hover{
background-color: #5f9c30 !important;
}
.navText{
    background-color: transparent !important;
    margin-top: 21px;
    margin-left: 29px;
    margin-top: 24px;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: #5f9c30;
    border-color: #337ab7;
}
.backColor{
    height: 110px;
    color: #fff;
}
.cartImg{
    margin-top: 32px;
    cursor: pointer;
}
.mt5{
    background-color: #5f9c30;
    color: #fff;
    display: inline-block;
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 28px;
    text-align: center;
    margin-left: -7px;
    margin-top: 30px !important;
}
/*.customDropdown{
  height: auto;
  background-color: #201b47 !important;
  color: #fff !important;
    min-width: 250px !important;
    padding-left: 15px !important;
 font-family:Arial;


}*/

.customUl li> a.newinvestlink{
    padding: 5px !important;
    padding-left: 7px !important;
    padding-bottom: 7px !important;
    width: 100%;
    margin: 0px;
}
.newinvestspan{
  padding: 9px !important;
  width: 100%;
  text-align: center;
}


.headLi{
    font-size: 15px;
    font-weight:bold;
    margin-top: 10px;
    padding-left: 10px !important;
    color: #666;
}
.noStyle{
    padding-left: 10px !important;
    margin-left: 188px !important;
}
.noStyle li{
    list-style: none;
    padding-top: 10px;
    color: #999;
}
.noStyle>li>a{
    color: #999 !important;
}
.hello{
    z-index: 1000!important;
}
.headerflow{
    position: fixed;
  z-index: 10000;
  background-color: transparent;
  transition-duration: 0.8s;
}
.headerflowscroll{
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  height: 100px;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.8s;
}

#container {
  margin: 0 auto;
  max-width: 890px;
}
 .toggle, [id^=drop] {
 display: none;
}
nav {
  margin-top: 37px;
  padding: 0;
}

#logo {
  display: block;
  padding: 0 30px;
  float: left;
  font-size: 20px;
  line-height: 60px;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  float: left;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  margin: 0px;
  display: inline-block;
  float: left;
  background-color: transparent;
  width: auto;
}

nav a {
  display: block;
  padding: 0 13px;
  color: #666;
  line-height: 35px;
 font-family:Arial;

  text-decoration: none;
}

nav ul li ul li:hover { background: #eee; }

nav a:hover { color: #f08f00;
text-decoration: none; }

nav ul ul {
  display: none;
  position: absolute;
  top: 35px;
  background-color: #fff;
  box-shadow: 0 10px 29px rgba(92,141,255,.5);
  height: 0px;

}

nav ul :hover > ul { display: inherit; }

nav ul ul li {
  width: auto;
  float: none;
  display: list-item;
  position: relative;
  background-color: #fff;
}

nav ul ul ul li {
  width:322px;
  position: relative;
  top: -35px;
  left: 284px;
  background-color: #fff;
}
nav ul ul ul ul li {
  width:360px;
  position: relative;
  top: -35px;
  left: -350px;
  z-index: 100;
  background-color: #fff;
}
nav ul ul ul ul ul li {
  width:310px;
  position: relative;
  top: -35px;
  left: -305px;
  background-color: #fff;
}


li > a:only-child:after { content: ''; }




@media all and (max-width : 768px) {


#logo {
  display: block;
  padding: 0;
  width: 100%;
  text-align: center;
  float: none;
}

nav { margin: 0; }

.toggle + a,
 .menu { display: none; }

.toggle {
  display: block;
  background-color: #254441;
  padding: 0 60px;
  color: #eee;
  font-size: 22px;
  font-weight:bold;
  line-height: 60px;
  text-decoration: none;
  border: none;
}

.toggle:hover { background-color: #000000; }

[id^=drop]:checked + ul { display: block; }

nav ul li {
  display: block;
  width: 100%;
}

nav ul ul .toggle,
 nav ul ul a { padding: 0 40px; }

nav ul ul ul a { padding: 0 80px; }

nav a:hover,
 nav ul ul ul a { background-color: #000000; }

nav ul li ul li .toggle,
 nav ul ul a { background-color: #212121; }

nav ul ul {
  float: none;
  position: static;
  color: #eee;
}

nav ul ul li:hover > ul,
nav ul li:hover > ul { display: none; }

nav ul ul li {
  display: block;
  width: ;
}

nav ul ul ul li { position: static;

}
}

@media all and (max-width : 330px) {

nav ul li {
  display: block;
  width: 94%;
}

}

.headerlogo img{
  margin-top: 15px;
  height: 75px;
}
.zindex{
}
dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
.geoHover:hover~ul>li{
  z-index: 300;
}
.miniHeaderDesolved{
    height: 55px;
    border-bottom: 1px solid #aaa;
    background-color: #fff;
 font-family:Arial;
}
.contactDetails {
    margin-top: 18px;
}
.fs18{
    font-size:18px;
    color: #5f9c30;
}
.contactLabal{
    font-size: 13px;
    padding-top: -5px;
}

.borderLeft{
    border-left: 1px solid #aaa;
    height: 20px;
    padding: 0px;
    margin-left: 25px;
    color: #999;
    padding-top: 2px;
    padding-left: 10px;
    font-size: 12px;
}
.myAccoutText{    
    color: #999;
    font-weight: normal;
    font-size: 12px;
}
.nav i{
    font-weight: bold;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 0px !important;
  transition-duration: 0.8s;
  border-bottom:1px solid #ccc;

}
.customClass{
  margin:5px !important;
}
.customNavBar{
  position: absolute !important;
  width: 100%;
  border-radius: 0px !important;
  background-color: #201A47 !important;
  border:none !important;
  min-height: 40px !important;
  padding-left:35px !important;
  padding-right:2px !important;
}
.customNavBarSmall{
  position: absolute !important;
  width: 100%;
  border-radius: 0px !important;
  background-color: #fff !important;
  border:none !important;
  /*border-bottom:  5px solid #201a47 !important;*/
  min-height: 40px !important;
  padding-right:2px !important;
}
.customNavBarUpper{
  border-radius: 0px !important;
  background-color: #201a47 !important;
  border:none !important;
  padding: 8px;

  padding-left:20px !important;
  padding-right:20px !important;
}
.width50{
  /*width: 56% !important;*/
  /*width: 69% !important;*/
}
.customUl li>a{
  font-weight: normal !important;
  color: #fff !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  font-size: 15px !important;
  letter-spacing: 0.03em;
}
.customUl li:hover{
  text-decoration: none;
}
.customUlSmall li>a{
  font-weight: normal !important;
  color: #333 !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  font-size: 15px !important;
  letter-spacing: 0.03em;
}
.customUlSmall li:hover{
  text-decoration: none;
}
.webSiteNameOther{
   font-family:Arial;
   margin-top: -13px;
  font-weight: bold;
  margin-left: -40px !important;
  font-size: 40px !important;
}
.webSiteNameOther img{
  height: 45px;
  margin-left: 20px;
}
.webSiteName{
  font-family:Arial;
  font-weight: bold;
  padding: 0px !important;
  font-size: 40px !important;
}
.webSiteImage {
  margin-top: -7px;
    height: 62px;
}
.webSiteImage img{
  height: 100%;
  width: 100%;
}
.investNowButtonHeader{
  background-color: #fff;
  color: #201a47;
  padding: 10px !important;
  font-weight: bold;
  text-align:center;
  margin-top: 0.5%;
  font-size: 13px;
  transition-duration: 1s;
  cursor: pointer;
}
.investNowButtonHeader:hover{
  transition-duration: 1s;
  background-color: #AB989D;
}
.loginButton{
  color:#fff;
  padding: 14px;
  cursor: pointer;
}
.headLi{
   color: #333 !important; 
}
.customDropdown>a{
    padding-left: 30px !important;
    padding-top: 8px !important;
    font-size: 14px;
    margin-left: -15px !important;
    text-decoration: none;
    background-color: transparent !important;
    color: #fff !important;
}
.customDropdown a:hover{
      background-color:#25bbf3!important;

}
.customDropdown{
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
     height: auto;
    min-width: 250px !important;
    padding-left: 15px !important;
    font-family:Arial;
    float: left;
    width: 100px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: circle;
    background-color: #201A47 !important;
    background-clip: padding-box;
    border:none !important;
    border-radius: 4px;
    color:#fff !important;
/*    box-shadow: 0 6px 12px rgba(0,0,0,.175);
*/
}.customDropdownSmall>a{
    padding-left: 30px !important;
    padding-top: 8px !important;
    font-size: 14px;
    margin-left: -15px !important;
    text-decoration: none;
    background-color: transparent !important;
}
.customDropdownSmall a:hover{
      background-color:#25bbf3!important;

}
.customDropdownSmall{
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
     height: auto;
    min-width: 250px !important;
    padding-left: 15px !important;
    font-family:Arial;
    float: left;
    width: 100px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: circle;
    background-clip: padding-box;
    border:none !important;
    border-radius: 4px;
/*    box-shadow: 0 6px 12px rgba(0,0,0,.175);
*/
}
.customDropdownLogOut>a{
    padding-left: 20px !important;
    font-size: 14px;
    margin-left: -15px !important;
    text-decoration: none;
    background-color: transparent !important;
    color: #333 !important;
}
.customDropdownLogOut a:hover{
      background-color:transparent!important;

}
.customDropdownLogOut{
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
     height: auto;
    min-width: 300px !important;
    padding-left: 15px !important;
    font-family:Arial;
    float: left;
    width: 100px;
    padding: 5px 10px;
    margin: 4px 0 0;
    font-size: 14px;
    padding-bottom:15px !important;
    text-align: left;
    list-style: circle;
    background-color: #fff !important;
    background-clip: padding-box;
    border:none !important;
    border-radius: 4px;
    color:#fff !important;
/*    box-shadow: 0 6px 12px rgba(0,0,0,.175);
*/
}
.listItem{
    background-color: #201A47;
    margin-left: -20px;
    padding: 5px;
}
.listItem:hover{
  background-color: #201b47 !important;
  text-decoration: none !important;
}

.customDropdown>li>a:hover{
  background-color: transparent!important;

}
.colorP{
   font-family:Arial;

  color: #201A47 !important;
}
.colorWhite{
  color:#fff !important;
}
.faceBook{
  color: #201b47;
  padding-top: 8px;
  margin-right: 10px;
  font-size: 15px;
  transform: scale(1);
  transition-duration: 0.5s;

}
.faceBook img{
 height: 30px;
}
.faceBook:hover{
  cursor: pointer;
  transform: scale(1.1);
    transition-duration: 0.5s;

}
.iconContainerHeader{
  margin-left: 15px;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
  background-color:transparent !important;
}
.cursorPointer{
  cursor: pointer;
}
hr{
  margin:5px !important;
}
.myprofileButton{
  border-bottom: 1px solid #afafaf69;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}
.marginTop{
  border-top: 1px solid #afafaf69 !important;

}
.myprofileButtonSmall{
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  color: #333;
}
.logOutButton{
  background-color: #f00;
  color: #fff;
  padding:10px;
  border-radius: 3px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}
.ImgDiv{
  height: 80px;
  text-align: center;
  font-size: 25px;
  margin-top: 14px;
  padding-top: 24px;
  padding-left: 14px !important;
  border-radius: 50%;
  background-color: #1db1f1;
}
.ImgDiv label{
  font-size: 20px !important;
  margin-top:  bold;
}
.mailClass{
      margin-top: -11px;
    font-size: 14px;
}
.fs19{
  font-size: 18px;
}
.mr15{
  margin-top:15px !important;
}
.enquirenowsidebtn{
  /*transform: rotate(90deg);*/
  position: fixed !important;
  right: 0px;
  top: 42%;
  background-color: #009dd7 !important;
  border: 2px #009dd7 solid !important;
  color: #FFF !important;
  z-index: 1;
  padding: 14px 19px !important;

}

.modal-open{
  padding-right: 0px !important
}

@media only screen and (min-width:1000px)  and (max-width:1250px){
  .width50{
    /*width: 75% !important;*/
  }
}

.headersociallinksmb{
  background-color: #fff;
  padding: 0px;
}
.headersociallinksmb:hover{
  background-color: #fff;  
}

.headerrwcontain{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sebicontain{
  margin-top: 48px;
  background-color: #f5f5f5;

}
.footerContainer{
	border-top:10px solid #201A47;
	padding: 0px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	padding-top: 20px !important;
}
.footerDivFirst{
 font-family:Arial;

}
.footerDivFirst label{
color: #201A47;
    font-size: 18px;
}
.footerDivFirst ul>li{
	cursor: pointer;
	font-size: 14px;
	color: #5b5b5b;
	letter-spacing: 0.02em;
	margin-bottom: 5px;
}
.footerDivFirst ul{
	padding-left: 0px !important;
	list-style: none !important;
}
.borderRadi{
	border-radius: 2px !important;
}
.inputContainerEH{
	 border: 1px solid #aaa;
    height: 32px;
    margin-top: 10px;
    border-radius: 3px !important;
    padding: 0px !important;
}
.customInput{
	padding: 15px;
	border-radius: 0px !important;
    height: 30px !important;
    border: none;
   font-family:Arial;

}
.customInputEH:focus{
	outline: none !important;
}
.searchIcon{
	height: 30px;
	padding: 5px;
	cursor: pointer;
	background-color: #201A47;
	color: #fff;
}
.searchIcon:hover{
		background-color: #333;

}
.imgContainer{
	margin-top: 1px;
}
.footerLeftCss{
	padding: 15px;
	font-family: Arial;
}
.sitelink{
	color: #FFFFFF !important;	
}
.footerRightCss{
	padding: 15px;
	font-family: Arial;
	display: none;
	text-align: right;
}

.imgContainerAU{
	height: 550px !important;
}
.imgContainerAU img{
	border:none !important;
	height: 550px;
	width: 200px;
}
.gifContainer{
	padding-top:40px;
	padding-bottom: 40px;
	 font-family:Arial;
	 height: auto;
}
.gifContainer img{
	height: 100%;
	width:100%;
}
.gifTextContainer{
	padding: 40px !important;
	 font-family:Arial;

	padding-top:10% !important;
}
.gifTextContainer label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.gifTextContainer p{
	font-size: 1.5em;
    line-height: 1.5em;
}
.iceBlueColor{
	color: #201A47 !important
}
.gifTextContainer a{
	text-decoration: underline;
}
.onHoverEffect:hover{

}
.fiveG img {
    height: 400px;
    width: 100%;
}
.fiveG {
    height: 400px;
    margin-top: -380px;
    background-image: url("/images/loading.gif");
    background-repeat: no-repeat;
}
.fiveG p{
        justify-content: center;

}
.padding40{
	padding-right: 80px !important;
}
.padding40Left{
	padding-left: 80px !important;
}
.oneOnOneAdvice{
	padding: 40px !important;
	    justify-content: center;

	 font-family:Arial;
	padding-top:40px !important;
}
.oneOnOneAdvice label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.oneOnOneAdvice p{
	margin-top: 0.2em;
	
}
.oneOnOneAdvice a{
	text-decoration: underline;
}
.yearP{
	font-weight: bold;
}
.percentage{
	font-weight: bold;
	font-size:5rem;	
}
.terms{
	font-size: 1.5em;
}
.oneOnOneAdvice p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}


.startTrandingContent{
	padding: 80px !important;
	margin-top: 40px !important;
	 font-family:Arial;
    justify-content: center;


}
.startTrandingContent label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.startTrandingContent p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}

.startTrandingContent a{
	text-decoration: underline;
}

.startTrandingContentSmall{
	padding: 45px !important;
	 font-family:Arial;
    justify-content: center;

}
.startTrandingContentSmall label{
	font-size: 4rem;
	line-height:1.2;
	font-weight: bold;
}
.startTrandingContentSmall p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}

.startTrandingContentSmall a{
	text-decoration: underline;
}
.startTrandingImgContainer{
	height: 400px;
	margin-top: -500px;
}
.startTrandingImgContainer img{
	height: 400px;
	width: 100%;
}
.usStocksContainer{
	height: 400px;
	margin-top: -502px;
	  background-image: url("/images/loading.gif");
  background-repeat: no-repeat;
}
.usStocksContainer img{
	height: 400px;
	width: 100%;
}
.startTrandingImgContainerSmall img{
	height: 100%;
	width: 100%;
}
.startInterestingButton{
	padding: 18px;
	text-align: center;
	color: #fff;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 0.03em;
	background-color: #00819D;
}
.learnMore{
	padding: 17px !important;
	padding-left: 25px !important; 
}
.learnMoreST p{
  text-align: justify;

}
.learnMoreST a{
	font-weight: bold;
	padding: 0px !important;
	color:#1db1f1 !important;
	font-size:18px;
}
.pad50{
	padding-top:50px;
}
/*.paraeff4{
  position: relative;
  right:40px !important;
  opacity:1 !important;
  transform: rotate(0deg);
  transition-duration: 1.5s;
}
.para2{
  position: relative;
  right:-500px;
  top:-30px;
  opacity: 0;
  transform: rotate(0deg);
  transition-duration: 1.5s;

}
*/
.gifContainer{
	padding-top:40px;
	padding-bottom: 40px;
	 font-family:Arial;
	 height: auto;
}
.gifContainer img{
	height: 100%;
	width:100%;
}
.gifTextContainer{
	padding: 40px !important;
	 font-family:Arial;

	padding-top:10% !important;
}
.gifTextContainer label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.gifTextContainer p{
	font-size: 1.5em;
    line-height: 1.5em;
}
.iceBlueColor{
	color: #201A47 !important
}
.gifTextContainer a{
	text-decoration: underline;
}
.onHoverEffect:hover{

}
.sfocusedG img {
    height: 400px;
    width: 100%;
}
.sfocusedG {
    height: 400px;
    margin-top: -600px;
    background-image: url("/images/loading.gif");
    background-repeat: no-repeat;
}
.sfocusedG p{
        justify-content: center;

}
.padding40{
	padding-right: 80px !important;
}
.padding40Left{
	padding-left: 80px !important;
}
.handFreeInvesting{
	padding: 50px !important;
	 font-family:Arial;

}
.handFreeInvesting label{
	font-size: 5rem;
	line-height:1.2;
	font-weight: bold;
}
.handFreeInvesting p{
	margin-top: 1em;
	font-size: 1.5em;
    line-height: 1.5em;
}

.handFreeInvesting a{
	text-decoration: underline;
}
/*.paraeff{
  position: relative;
  left :50px !important;
  top:-430px;
  opacity:1 !important;
  transform: rotate(0deg);
  transition-duration: 1.5s;
}
*/.para0{
  position: relative;
/*  left:-500px;
*/  top:-400px;
  opacity: 1;
  transform: rotate(0deg);
  transition-duration: 1.5s;

}
.heightAuto{
  height: auto;
}
.ht500{
	height: 610px;
}
.learnMoreHF a{
  padding: 20px;
  color: #201a47 !important;
  font-size: 15px;
  text-decoration: underline;
     
}
.startInterestingButtonHF:hover{
    box-shadow: inset 100px 0px 1px 0 #00BAF2;
    box-shadow: inset 0px 100px 1px 0 #00BAF2;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.startInterestingButtonHF{
  padding: 18px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.03em;
  background-color: #00819D;
  box-shadow: inset 0 0 0 0 #00BAF2;
    transition: 0.5s all ease-in-out;
}
.imageContainerHF{
  height: 400px;

}
.imageContainerHF img{
  height: 100%;
  width: 100%;
}

.handFreeInvestingImgContainer{
  height: 400px;
  margin-top: -470px;
  background-image: url("/images/loading.gif");
  background-repeat: no-repeat;


}
.handFreeInvestingImgContainer img{
  height: 400px;
  width: 100%;
}
/*.paraeff1{
  position: relative;
  right:40px !important;
  top:-630px;
  opacity:1 !important;
  transform: rotate(0deg);
  transition-duration: 1.5s;
}
.para1{
  position: relative;
  right:-500px;
  top:-30px;
  opacity: 0;
  transform: rotate(0deg);
  transition-duration: 1.5s;

}
*/
.small img{
  height: 100%;
  width: 100%;
}
#myCarousel{
	height: 100%;
	width: 100%;
}
.bannerContainerCar{
	background-color: #333;
	height: 420px;
}
.height350 img{
height: 420px !important;
width: 100%;
}
.squareIndicator{
	left: 90% !important;
}
.squareIndicator li{
  border-radius: 0px !important;
  margin-right: 5px !important;
  padding: 5px !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
}
.squareIndicator .active{
  margin: 1px !important;
  margin-right:  5px !important;
  border: 1px solid #25bbf3 !important;
  background-color: #25bbf3 !important;
}
.caption{
  position: absolute;
  height: 400px;
  padding: 25px !important;
  text-align: left !important;
  z-index: 1000;
   font-family:Arial;
   background-color: rgba(0,0,0,0.2);
  margin-top: -380px;
  color: #fff;
}
.caption label{
    margin-top: 90px;
    transition: all 0s ease 0s;
    line-height: 60px;
    font-size: 40px;
    font-family:Arial;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00166, 0, -33.4375, 0, 1);
}
.caption p{
 font-family:Arial;
    font-size: 13px;
}
.captionSmall{
	position: absolute;
  height: 400px;
	padding: 25px !important;
	text-align: left !important;
	z-index: 1000;
	 font-family:Arial;
   background-color: rgba(0,0,0,0.2);
	/*margin-top: -400px;*/
  margin-top: -420px;
	color: #fff;
}
.captionSmall label{
      margin-top: 80px;

    transition: all 0s ease 0s;
    /*line-height: 45px;*/
    line-height: 42px;
    /*font-size: 30px;*/
    font-size: 25px;
    font-family:Arial;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, -0.00166, 0, -33.4375, 0, 1);
}
.captionSmall p{
 font-family:Arial;
    font-size: 13px;
}
.readMoreButtonCar{
	font-size: 15px;

	padding: 0px !important;
	cursor: pointer;
	 font-family:Arial;
	margin-top: 0px !important;
	font-weight: bold;
}

.flip-scale-up-hor {
	        -webkit-animation: flip-scale-up-hor 0.9s linear both;
	                animation: flip-scale-up-hor 0.9s linear both;
}

.slide-top {
	        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	                animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bottom {
	        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	                animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bottom-slow {
	        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	                animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes flip-scale-up-hor {
  0% {
            transform: scale(1) rotateX(0);
  }
  50% {
            transform: scale(2.5) rotateX(-90deg);
  }
  75% {
            transform: scale(1) rotateX(-180deg);
  }
   100% {
            transform: scale(1) rotateX(0);
  }
}

@keyframes flip-scale-up-hor {
  0% {
            transform: scale(1) rotateX(0);
  }
  50% {
            transform: scale(2.5) rotateX(-90deg);
  }
  75% {
            transform: scale(1) rotateX(-180deg);
  }
   100% {
            transform: scale(1) rotateX(0);
  }
}
 

@-webkit-keyframes slide-top {
  0% {
            transform: translateY(-100px);
  }
  100% {
            transform: translateY(0px);
  }
}
 

@keyframes slide-top {
  0% {
            transform: translateY(-100px);
  }
  100% {
            transform: translateY(0px);
  }
}
@-webkit-keyframes .slide-bottom{
  0% {
            transform: translateY(-200px);
  }
  100% {
            transform: translateY(0px);
  }
}
@keyframes .slide-bottom{
  0% {
            transform: translateY(-200px);
  }
  100% {
            transform: translateY(0px);
  }
}

@-webkit-keyframes .slide-bottom-slow{
  0% {
            transform: translateY(-300px);
  }
  100% {
            transform: translateY(0px);
  }
}

@keyframes .slide-bottom-slow{
  0% {
            transform: translateY(-300px);
  }
  100% {
            transform: translateY(0px);
  }
}

.blogContainer{
    height: auto;
}

.miniDiv1Filter img {
    transform: scale(1);
    cursor: pointer;
    height: 170px;
    width: 100%;
    transition-duration: 0.5s;
}
.miniDiv1Filter{
      overflow: hidden;
    height: 140px;
}
.miniDiv1Filter:hover img{
  transform: scale(1.1);
  cursor: pointer !important;
  transition-duration: 0.5s;
}
.miniDiv1Filter:hover .miniFilterTextDiv{
    opacity: 1;
        transition-duration: 0.5s;


}
.miniDiv1Filter:hover .miniFilterTextDiv1{
    opacity: 0;
        transition-duration: 0.5s;


}
.margins{
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
}
.miniFilterTextDiv{
    position: absolute;
    height: 200px;
    opacity: 0;
    border:1px solid #333;
    z-index: 1000;
    transition-duration: 0.5s;
 font-family:Arial;
    text-align: center;
    background-color: rgba(0,0,0,0.9);
    margin-top: -198px;
    padding: 50px;
    cursor: pointer;
    color: #fff;
}
.miniFilterTextDiv label{
    font-size: 20px;
    padding-top: 20px; 
    line-height: 5px;
    color: #fff;
}
.miniFilterTextDiv1{
    position: absolute;
    height: 200px;
    opacity: 1;
    z-index: 1000;
    transition-duration: 0.5s;
 font-family:Arial;
    cursor: pointer;

    text-align: center;
    background-color: rgba(0,0,0,0.6);
    margin-top: -174px;
    padding: 60px;
    color: #222;
}
.miniFilterTextDiv1 label{
    font-size: 20px;
    padding: 15px; 
    line-height: 20px;
    color: #fff;
}
.shopNowButtonFilters{
    height: 36px;
    color: #fff;
    font-size: 13px;
    border:1px #fff solid;
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    font-weight: bold;
    box-shadow: inset 0 0 0 0 #fff;
    transition: 0.5s all ease-in-out;
}
.shopNowButtonFilters:hover{
  box-shadow: inset 100px 0px 1px 0 #fff !important;
    box-shadow: inset 0px 100px 1px 0 #fff;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
}

.pad20{
    padding-top: 20px !important;
}
.blogHeading{
    font-size: 22px;
    font-weight: bold;
    font-family:Arial;
    margin-top: 35px;
    color: #201A47;
    text-align: center;
    padding: 10px;
}
.imgContainerBlog{
    height: 160px !important;
    font-family:Arial;
    overflow: hidden;

}
.imgContainerBlog img {
    border: none !important;
    height: 160px !important;
}
.imgContainerBlog label{
    font-size: 16px;
    padding-top: 8px;
}
.imgContainerBlog p{
    font-size: 14px;
    padding-top: 5px;
}
.imgContainerBlog a{
    font-weight: bold;
    color:#376bff !important;
}
.boxShadow{
       z-index: 0 !important;
       box-shadow:  2px 2px 2px 2px #aaa !important;

}
.customnNavButtoncaro1{
    margin-top: 100px;
}
/*.blogContainer{
    height: 420px;
}

.miniDiv1Filterother img {
    transform: scale(1);
    cursor: pointer;
    height: 100%;
    width: 100%;
    transition-duration: 0.5s;
}
.miniDiv1Filterother{
      overflow: hidden;
    height: 140px;
}
.miniDiv1Filterother:hover img{
  transform: scale(1.1);
  cursor: pointer !important;
  transition-duration: 0.5s;
}
.miniDiv1Filterother:hover .miniFilterTextDivother{
    opacity: 1;
        transition-duration: 0.5s;


}
.miniDiv1Filterother:hover .miniFilterTextDiv1{
    opacity: 0;
        transition-duration: 0.5s;


}
.margins{
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
}
.miniFilterTextDivother{
    position: absolute;
    height: 200px;
    opacity: 0;
    z-index: 1000;
    transition-duration: 0.5s;
 font-family:Arial;

    text-align: center;
    background-color: rgba(0,0,0,0.9);
    margin-top: -237px;
    padding: 30px;
    color: #fff;
}
.miniFilterTextDivother label{
    font-size: 20px;
    padding-top: 20px; 
    line-height: 5px;
    color: #fff;
}
.miniFilterTextDiv1{
    position: absolute;
    height: 200px;
    opacity: 1;
    z-index: 1000;
    transition-duration: 0.5s;
 font-family:Arial;

    text-align: center;
    background-color: rgba(0,0,0,0.6);
    margin-top: -220px;
    padding: 40px;
    color: #fff;
}
.miniFilterTextDiv1 label{
    font-size: 20px;
    padding: 20px; 
    line-height: 1px;
    color: #fff;
}
.shopNowButtonFilters{
    height: 36px;
    color: #fff;
    font-size: 13px;
    border:1px #fff solid;
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    font-weight: bold;
    box-shadow: inset 0 0 0 0 #fff;
    transition: 0.5s all ease-in-out;
}
.shopNowButtonFilters:hover{
  box-shadow: inset 100px 0px 1px 0 #fff !important;
    box-shadow: inset 0px 100px 1px 0 #fff !important;
    cursor: pointer !important;
    transition: 0.5s all ease-in-out;
}

.pad20{
    padding-top: 20px !important;
}
*/
.ht162{
    height: 170px !important;
}
.height170{
    height: 170px !important;
}
.miniFilterTextDiv2 {
    position: absolute;
    height: 200px;
    opacity: 1;
    z-index: 1000;
    transition-duration: 0.5s;
 font-family:Arial;
    cursor: pointer;
    text-align: center;
    background-color: rgba(0,0,0,0.6);
    margin-top: -185px;
    padding: 15px;
    padding-top: 75px !important;

    color: #fff;
}
.miniDiv1Filterother img {
    transform: scale(1);
    cursor: pointer;
    height: 170px;
    width: 100%;
    transition-duration: 0.5s;
}
.miniDiv1Filterother{
      overflow: hidden;
    height: 140px;
}
.miniDiv1Filterother:hover img{
  transform: scale(1.1);
  cursor: pointer !important;
  transition-duration: 0.5s;
}
.miniDiv1Filterother:hover .miniFilterTextDiv{
    opacity: 1;
        transition-duration: 0.5s;


}
.miniDiv1Filterother:hover .miniFilterTextDiv1{
    opacity: 0;
        transition-duration: 0.5s;


}
.miniFilterTextDivother{
    position: absolute;
    height: 200px;
    opacity: 0;
    z-index: 1000;
    transition-duration: 0.5s;
    font-family:Arial;
    text-align: center;
    background-color: rgba(0,0,0,0.9);
    margin-top: -185px;
    padding: 15px;
    padding-top: 50px !important;
    color: #fff;
}
.miniFilterTextDivother label{
    font-size: 20px;
    padding-top: 20px; 
    line-height: 25px;
    color: #fff;
}
.miniFilterTextDiv2 label{
    font-size: 19px;
    line-height: 45px;
    color: #fff;
}
.miniDiv1Filterother:hover .miniFilterTextDivother{
    opacity: 1;
    transition-duration: 0.5s;
}
.miniDiv1Filterother:hover .miniFilterTextDiv2{
    opacity: 0;
    transition-duration: 0.5s;
}
.shopNowButtonFilters:hover{
    cursor: pointer;
}

.miniDiv1Filter.ht162:hover .miniFilterTextDiv1{
    margin-top: -220px !important;
}
.miniDiv1Filterother.height170:hover .miniFilterTextDiv2{
    margin-top: -220px !important;
}
.modalBodyCustom{
	padding: 40px !important

}
.modalBodyCustomSmall{
  padding: 10px !important;
  height: 900px;
  background-color:#fff;
  
}
.modalHeaderCustom{
	background-color: #009dd7;
	border-top-left-radius:  3px;
	border-top-right-radius: 3px;
	color:#fff;
}
.modalHeaderCustom i{
	color: #60e8dd;
  font-size: 30px;
	opacity: 1;
}
.modalHeaderCustom h4{
	font-size: 21px;

}
/*other*/
.customModalRP{
	background-color: #fff !important;
	height: 600px !important;
/*  width: 800px !important;
*/  z-index: 10000 !important;
  margin-top: 10px !important;
  border-radius: 3px;
}
.customModalKYC{
	background-color: #fff !important;
	height: 700px !important;
/*  width: 800px !important;
*/  margin-top: 10px !important;
  border-radius: 3px;
}
.customModalEN{
	background-color: #fff !important;
	height: 460px !important;
/*  width: 800px !important;
/* margin-top: 10px !important;*/
  border-radius: 3px;
  margin-top: 8% !important;
}
/* Create a custom checkbox */
.centreDetailContainer{
  position: relative;
  padding-top: 5px;
  align-content: center;
  cursor: pointer;
  font-size: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
}
/* Hide the browser's default checkbox */
.centreDetailContainer input {
     position: relative;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    height: 17px;
    width: 17px;
    right: 4px;
    top: -2px;
}
.centreDetailCheck {
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 3px;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
.radioCheck {
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
/* On mouse-over, add a grey background color */
.centreDetailContainer:hover input ~ .centreDetailCheck{
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.centreDetailContainer input:checked ~ .centreDetailCheck {
  background-color: #201a47;
  border:2px solid #201a47;
}
/* Create the checkmark/indicator (hidden when not checked) */
.centreDetailCheck:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.centreDetailContainer input:checked ~ .centreDetailCheck:after {
  display: block;
}
/* Style the checkmark/indicator */
.centreDetailContainer .centreDetailCheck:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  align-content: center;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
/* On mouse-over, add a grey background color */
.centreDetailContainer:hover input ~ .radioCheck{
  background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.centreDetailContainer input:checked ~ .radioCheck {
  background-color: #201a47;
  border:2px solid #201a47;
}
/* Create the checkmark/indicator (hidden when not checked) */
.radioCheck:after {
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.centreDetailContainer input:checked ~ .radioCheck:after {
  display: block;
    background-color: transparent;
}
/* Style the checkmark/indicator */
.centreDetailContainer .radioCheck:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  align-content: center;
  border: solid white;
  border-width: 0 3px 3px 0;   


  transform: rotate(45deg);
}
.centreDetaillistItem{
  padding-left: 0px !important;
      font-family: 'PT Sans', sans-serif;

  font-size: 14px !important;
}
/*other css*/
:root
{
	--text: "Select values";
}
.multiple_select
{
	height: 30px !important;
	padding: 5px !important;
	-webkit-appearance: menulist;
	  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
font-family: 'PT Sans', sans-serif;

	position: relative;
}
.multiple_select::before
{
	content: "Select values";
	content: var(--text);
	display: block;
  margin-left: 5px;
  margin-bottom: 2px;
}
.multiple_select_active
{
	overflow: visible !important;
	  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.multiple_select option
{
	display: none;
	width: 102% !important;
	margin-left: -4px !important;
    height: 18px;
	background-color: white;
}
.multiple_select_active option
{
	display: block;
}

.multiple_select option::before {
  content: "\2610";
}
.multiple_select option:checked::before {
  content: "\2611";
}
.css-1r4vtzz{
	width: 100% !important;
}
.css-48ayfv{
	width: 100% !important;
}
.css-1qprcsu-option{
	background-color: transparent !important;
}
#riskform p{
  font-size: 20px;
  color: #555 !important;
}

  .submitButtonRP{
    padding:10px;
    margin-top:25px;
    color: #fff;
    background-color: #201b47;
    cursor: pointer;

  }
  *:focus{
  	outline: none !important;
  }
  .pad40{
  	padding: 20px !important;
  	padding-top: 30px !important;
  }
.customInputKF{
  display: block !important;
  width: 100%;
    height: 34px;
    padding: 3px 0px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: none;
    border-bottom: 2px solid #ccc;
}
.inputContainerRP{
  padding: 10px;
  padding-top: 0px;
  /*height: 100px;*/
}
.errorMsg{
  min-height: 20px;
}
.formContainer{
  margin:10px;
  margin-top: 80px;
  margin-bottom: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,.075);
}
.kycImage{
  margin-top: 10%;
  height: 330px;

}
.kycImage img{
  height: 100%;
  width: 100%;
}
.submitButton{
  padding: 10px;
  color: #fff;
  font-weight: bold;
  cursor:pointer;
  font-size: 14px;
  margin-top: -4px !important;
  background-color: #009dd7!important;
}

.inputContainerRP label{
	color:#333 ;
	font-size: 15px;
}
.logoText{
  font-size: 30px !important;
}
.readyTo{
  font-size: 45px !important;
  color: #201a47;
}
.ht150{
  height: 150px !important;
}

.iconContainerCD{
color: #fff;
background-color: #25bbf3;
padding: 20px;
font-size: 25px !important;
border-radius: 50%;

}
.payZeroCD{
	font-size: 17px;
	line-height: 25px;
	color:#666;
	 font-family:Arial;
	font-weight: normal !important;
	word-spacing:0em;
	margin-top: 10px;
	padding:10px;
}
.subDivCD{
	text-align: center;
		cursor: pointer;


}
.subDivCD i{
	transition-duration: 0.5s;

}
.subDivCD:hover i{
	transform: scale(1.2);
	transition-duration: 0.5s;
	cursor: pointer;
}
.investContainerCD{
	text-align: center;
	padding: 20px;
	padding-bottom: 50px;
/**/		 font-family:Arial;

}
.investLabelCD{
	font-weight: bold;
	padding: 60px;
	font-size: 40px;
	color:#201A47;
}
.height500{
	height: 650px;
}
.minusMargin{
		margin-top: -80px;

}
.valuesWeCreateContainer{
	height: auto;
	background-image:url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/backImageAU.jpg");
	background-size: 100% 100%;
	padding:40px;
	background-repeat: no-repeat;
	 font-family:Arial;
}
.floatingDivContainer{
/*		height: 450px;
*/		border-radius: 10px;
		margin-top: 10px;
		padding: 40px;
		text-align: center;
		color: #fff;
		background-image: linear-gradient(to right ,#201a47, #25bbf3);
}
.iconCircle{
	padding: 28px;
	font-size: 30px !important;
	border:1px solid #fff;
	border-radius: 50%;

}
.headingOfValue{
	padding-top: 20px;
	 font-family:Arial;
	font-size: 30px;

}
.paraDesc{
		font-size:18px;
		padding: 5px;

}
.missonButton{
	padding: 8px;
	padding-right:60px;
	padding-left: 60px;
	font-size: 18px;
	cursor: pointer;
	margin-top: 8%;
	border-radius: 30px;
	background-color: #201a47;
}

.bannerAUPara{
	font-size: 40px;
	font-weight: bold;
	line-height: 60px;
}
.headingAbout{
	color: #fff;
	font-size: 20px;
	padding: 30px;
	font-weight: bold;

}
.aboutUsContainer{
	background-image: linear-gradient(to right ,#201a47, #25bbf3);
	height: 100%;
}
.outstandingTeam{
	color: #fff;
	font-weight: bold;
	font-size:40px;
	padding-top:60px !important;
	padding: 20px;
 font-family:Arial;}
.customUlabout{
	list-style: circle;

}
.customUlabout li{
	color: #fff;
 font-family:Arial;	font-size: 20px;
	padding:20px;
}


.triangle-bottomleft {
    width: 0;
    height: 0;
     border-bottom: 520px solid #fff;
    border-right: 183px solid transparent
    }
.hexDiv1{
 font-family:Arial;	font-size: 18px;
	height: 280px;
	padding-left: 20px !important;
	padding-right: 20px !important;
	text-align: center;
	color: #fff;
}
.hexDiv1 img{
	height: 100px;
	padding: 5px;
	padding-bottom: 20px;
	border-bottom: 2px solid #aaa;
	-webkit-filter: invert(60%);
	        filter: invert(60%);
}
.hexDiv1 p{
	margin-top: 20px;
	color: #888;
	padding-top: 0px !important;
	padding: 50px;
	font-size: 17px !important;
}
.hexDiv1Small{
	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/hexaBack.png");
	background-repeat: no-repeat;
 font-family:Arial;	font-size: 18px;
	background-size: 100% 100%;
	height: 360px;
	padding: 70px !important;
	text-align: center;
	color: #fff;
}
.hexDiv1Small img{
	height: 70px;
}
.hexDiv1Small p{
	margin-top: 10px;
}
.customMarginHex{
	margin-top: 200px;
    margin-left: -173px;
}
.centerSecound{
margin-top: -183px;
    margin-left: -85px;

}
.marginLeft170{
margin-left: 170px;
}
.marginTop380{	
	margin-top: -380px;
}
.headLabelQF{
 font-family:Arial;
	position: absolute;
    font-size: 35px;
    top: 101px;
    left: 450px;
}
.headLabelQF1{
 font-family:Arial;
    font-size: 35px;
    margin-top: 20px;
}
.lastDiv{
	margin-top: -185px;
    margin-left: 170px;
}
.bannerContainerAU{
	background-image: url("/images/howWork.jpg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	text-align:center;
	height: auto;
	color: #fff;
	padding: 90px;
}
.descDivAU{
	padding: 40px;
	font-size: 18px;
}
.svgContent{
stroke:rgba(255, 255, 255, 0.45);stroke-width:5;
height: 10px !important;
width: 80px !important;
}
.qfHead{
	font-weight: bolder;
	font-size: 40px;
	padding: 40px; 

}
.nineYearImage{
	background-size: 100% 100%;
	height: 600px;

	text-align: center;
	background-attachment: fixed;
	color: #fff;
	background-image: url("/images/nineYear.jpg");
}
.nineYearImage p{
	margin-top:80px;
	line-height: 70px;
	font-weight: bolder;
	font-size: 56px;
}
.nineYearImage label{
	margin-top:10px;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;

}
.nineYearImage img{
	margin-top: 50px;
}
.riskDiv{
	background-size: 100% 100%;
	height: 600px;
	background-attachment: fixed;
	color: #fff;
	background-image: url("/images/riskBack.jpg");
}
.riskDiv p{
	margin-top:20px;
	line-height: 70px;
	font-weight: bolder;
	font-size: 60px;
}
.riskDiv label{
	margin-top:10px;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;

}
.riskDiv1{
	background-size: 100% 100%;
	height: 700px;
	background-attachment: fixed;
	color: #fff;
	background-image: url("/images/riskBack.jpg");
}
.riskDiv1 p{
	margin-top:20px;
	line-height: 70px;
	font-weight: bolder;
	font-size: 40px;
}
.riskDiv1 label{
	margin-top:10px;
	font-weight: normal;
	font-size: 18px;
	line-height: 30px;

}
.blackDiv{
	padding-top: 100px !important;
	padding: 60px !important;
		height: 600px;

	background-color: rgba(0,0,0,0.5) !important;
}
.blackDiv1{
	padding-top: 100px !important;
	padding: 40px !important;
	height: 700px;

	background-color: rgba(0,0,0,0.5) !important;
}
.blackDivRisk{
	padding-top: 30px !important;
	height: 600px;
	background-color: rgba(0,0,0,0.5) !important;
}
.blackDivOther{
     padding-top: 30px !important; 
    /* padding: 60px !important; */
    background-color: rgba(0,0,0,0.5) !important;
}

.imgContentAU{
	height: 80px;
}
.imgContentAU img{
	height: 100%;
	width: 100%;
}
.imageContent1{
	height: 500px;
	font-size: 20px;
	color: #666;

    padding: 30px !important;
/*    padding-left: 120px !important;
*/    padding-top: 20px !important;
}
.imageContent1 p{
    text-align: justify;	
}
.imageContent2{
	height: auto;
	font-size: 20px;
	color: #666;
    padding: 70px !important;
    padding-top: 40px !important;
}
.image1{
	height: 400px;
	margin-top: 30px !important;
	padding: 10px;
	background-image: url("/images/loading.gif");
 	background-repeat: no-repeat;
}
.image2{
	height: 350px;
}
.image2 img{
	height: 100%;
	width:  100%;
}
.image1 img{
	height: 100%;
	width:  100%;
}
.nameOfImageHead{
	font-size: 40px;
	color: #201A47;
}
.borderBottomDiv{
	width: 100px;
		border-bottom: 2px solid #201A47;
		margin-bottom: 20px;
}
.padding40inDiv{
	padding: 90px !important;
}

.sebicontent{
	color: #333;
	/*font-size: 19px;*/
	font-size: 1.5em;
    line-height: 1.5em;
    text-align: justify;
}

.sebiimage{
	text-align: center;
}

.sebiimage img{
	width:60%;
} 

.sebiname{
	margin-top:10px;
	font-size: 1.5em;
    line-height: 1.5em;
    
}

.regsebinumber{
	font-size: 24px;
}


.backColorEF{
	background-color: #eef3f4;
}
.contactUsBody{
	padding: 20px;
	margin-bottom: 20px;
	
}
.LabelContactUs{
	
}
.contactspan{
	font-size: 30px !important;
    color: #666;
    font-family: 'PT Sans',sans-serif;
    font-weight: normal !important;
}
.contactUsSpan{    
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #666;
	padding-top: 5px;
}
.customInputContact{
	font-family: 'PT Sans',sans-serif;
	border:1px solid #aaa;
}
.customInput:focus{
	outline: none !important;
}
.customInput::-webkit-input-placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInput:-ms-input-placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInput::-ms-input-placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInput::placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInputArea:focus{
	outline: none !important;
}
.customInputArea::-webkit-input-placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInputArea:-ms-input-placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInputArea::-ms-input-placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInputArea::placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.submitBtnContactUs{
	font-size: 14px;
    text-align: center;
	font-family: 'PT Sans',sans-serif;
    padding: 0px !important;
    background-color: #00baf2;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    height: 40px;
}
.storeInfo{
	padding: 20px;
	position: absolute !important;
	margin-top: 70px;
	margin-left: 17px;


}
.storeInfo label{
	font-size: 14px;
}
.contactUsBody span{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	padding:10px;
}
.contactUsForm{
	height: 100%;
	font-family: 'PT Sans',sans-serif;
	padding: 40px;

}
.customSelectContact{
	display: block;
    height: 40px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;

}
.heading{
    font-size: 18px;
    padding: 0px !important;
    padding-top: 10px !important;
font-weight: normal !important;	
}
.customSelectContact:focus{
	outline: none !important;
}
.mt10{
	margin-top:10px;
}
.marginBottom{
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid #aaa;
}
.sendButton{
	padding: 10px;
	background-color: #e7e7ea;
}
.headingSend{
  padding: 0px !important;
  font-weight: normal !important;
  font-size: 25px;
  
}
.sendUsSupport{
	margin-bottom: 20px;
}

.cubannerWall{

	height: 530px;
	/*background-color:  #444;*/
	padding: 0px;
	position: relative;
  	text-align: center;
  	color: white;
  	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/cubanner.jpg");
  	/*background-size: 100% 100%;*/
  	background-position: bottom;
  	background-attachment: fixed;

  	opacity: 0.8;
  	z-index: -1;
/*  	overflow: hidden;
*/  	transform: translate3d(0px, 0px, 0px);
}
.nopadding{
	padding: 0px !important;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 68px;
  font-weight: 600;

}
.cucentered{
   background-color: #191818ba;
  padding: 0px !important;
  height: 435px;
}
.cutextcentered{
   position: absolute;
  top: 22%;
  left: 0%;
}
.mapouter{
	position:relative;
	text-align:right;
	height:420px;
	width:100%;
	padding: 0px;
}
.gmap_canvas{
	overflow:hidden;
	background:none!important;
	height:400px;
	width:100%;
}
.cuMapWall{
	margin-top: 50px;
	padding: 0px;
}
.cuformWall{
	height: 500px;
	margin-bottom:40px !important;
	/*background-color: #aaa;*/
}
.bt40{
		margin-bottom:40px !important;

}
.bt30{
		margin-bottom:  10px;
	}
.sbtn{
	    /*background: -webkit-linear-gradient(left,#ffc600 0%,#ff4200 50%,#ffc600 100%);*/
	    height: 50px;
	     background-color: #407dfa !important;
   		 border-radius: 25px !important;
    	 color: #FFF!important;

	    font-weight: 600;
	    font-size: 16px;
}
.form-control{
	height: 44px!important;
}
.bannerContainer{
	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/5gcpmBack.jpg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding: 40px !important;
 font-family:Arial;
	height: 100%;

	color:#333;
}
.bannerContainerSH{
	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/safeHeaven.jpeg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
 	font-family:Arial;
	height: 100%;

	color:#fff;
}
.bannerContent{
	height: auto;
	margin-top:4%;
	margin-bottom: 4%;
}
.investButton{
	height: 35px;
	text-transform: uppercase;
	text-align: center;
	font-size: 20px;
	padding-top: 4px;
	cursor: pointer;
	background-color: #201A47;
	color: #fff;
}
.bannerContent label{
	font-size: 22px;
}
.divHeading{
	color: #fff;
	font-size: 4.9rem;
	margin-top: 1.25rem !important;
	font-weight: bold;

}
.divHeadingSmall{
	color: #fff;
	font-size: 3.3rem;
	margin-top: 1.25rem !important;
	font-weight: bold;

}
.bannerPara{
	margin-top: .75rem !important;
	font-size: 22px;
	line-height: 34px;
}
.bannerPara p{
	color: #fff;
}
.investNowButton{
	height: 55px;
	text-align: center;
	padding-top:16px;
	margin-top: 2.5rem !important;
	font-weight: bold;
	cursor: pointer;
	color: #111;
	word-spacing: 0.04em;
	    border:1px solid #fff;

	background-color:  #fff; 
	  box-shadow: inset 0 0 0 0 #000;
    transition: 0.5s all ease-in-out;	

}
.investNowButton:hover{
	  box-shadow: inset 100px 0px 1px 0 #000;
    box-shadow: inset 0px 100px 1px 0 #000;
    color:#fff;
    border:1px solid #fff;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

}
.slideDownButton{
	height: 20px;
	cursor: pointer;
	color: #fff !important;
	font-size: 10px;
	padding-top: 8%;
}
.slideDownButton img{
	padding-bottom: 5px;
}
.slideDownButton span{
	margin-left: -35px;
}
.bulkEmployeeImg img{
    height: 70px;
}
.bulkEmployeeImg{
    padding-left: 0px;
}
.productselection{
    margin-bottom: 20px;
}
.productchartcontent{
    /*margin-bottom: 40px;
    margin-top: 40px;*/
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 16px;
}

.productselection .selectbox{
    margin-bottom: 0px !important;
}

.bulkuploadfile{
    margin-top: 20px;
}

.errorlabel{
    color: #f00;
}

.productchartout{
    padding-top: 40px;
    padding-bottom: 40px;
}

.productspace{
	margin-top: 40px;
	margin-bottom: 40px;
}

.nav-pills.chartpills>li {
    float: left;
    border-top: 1px solid #dde0e4;
    border-bottom: 1px solid #dde0e4;
    padding-right: 0px;
    margin-left: 0px !important;
}   

.nav-pills.chartpills>li:last-child {
    border-right: 1px solid #dde0e4; 
}

.nav-pills.chartpills > li > a {
    border-radius: 0 !important;
    color: #444;
    border-left: 1px solid #dde0e4 !important;
    padding: 7px 10px !important;
}    

.nav-pills.chartpills > li.active > a, .nav-pills.chartpills > li > a.active {
    margin-bottom: 0px !important;
    background-color: #fff !important;
    border: 1px solid #3c8dbc !important;
    color: #3c8dbc !important;
    padding: 7px 10px !important;
}   


.chartdiv{
	padding-top: 30px;
}

.hrline{
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.investingva{
	font-size: 17px;
    line-height: 21px;
}

.pageloader{
    height:300px;
}

.nav-pills.chartpills{
    float: right;
}

.blockborder{
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.chartheading{
    font-size: 23px;
}

.cagrheading{
    font-size: 18px;  
    
}
.cagrvalue{
    font-weight: 600;
}
.chartheightfix{
    height: 620px;
}
.colorwealthypr{
    color: #1f7ae0;
}
.colorwealthycgr {
    color: #1f7ae0;
    line-height: 1.57;
    font-weight: 600;
}
.colorwealthyindex{
    color: #E3AF64;
}

@media (max-width: 899px){
    .extspace{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .chartheightfix{
        height: 720px !important;
    }
}

@media (min-width: 1300px){
    
    .chartheightfix{
        height: 820px !important;
    }
}

@media (min-width: 900px) and (max-width: 1200px){
    
    .chartheightfix{
        height: 560px !important;
    }
}
.investContainer{
	height: 100%;
	text-align: center;
	background-color: #201A47;
	font-family: 'PT Sans', sans-serif;
	padding: 40px !important;

}
.investLabel{
	color: #FFF !important;
	font-size: 3.5rem;
	line-height: 1.2;
	margin-top: 1.25rem !important;
}
.investPara{
	color: #fff;
	margin-bottom: 2.5rem !important;
	margin-top: .75rem !important;
	font-size: 160%;
}
.subDiv1{
	height: 100%;
	color:#fff;
	text-align: center !important;
	transition-duration: 0.5s;
}
.subDiv1 img{
	
	transition-duration: 0.5s;


}
.payZero{
	font-size: 2.875rem;
	line-height: 1.2;
	color: #007993 !important;
	font-weight: 700;
	word-spacing:0em;

}
.payZeroDesc{
	text-align: left;
	padding-top: 10px !important;

	font-size: 16px;

}
.iconContainer img{
	height: 100%;
	padding:8px;
	margin:2.6rem;
}
.investContainer .investLabel{
	text-align: center;
}
.subDiv1:hover img{
	transform: scale(1.2);
	transition-duration: 0.5s;
	cursor: pointer;
}

.typesOfGrouth{
	padding: 40px !important;
    font-family: Arial;
    padding-top: 5% !important;
}
.typesOfGrouth p{
    font-size: 1.5em;}
.typesOfGrouth span
{
	font-size: 40px;
}
.suitableForDiv{
    font-family: Arial;
}
.suitableForDiv p{
	 font-size: 18px;
}
.suitableForDiv span
{
	font-size: 40px;
}
.customstyle{
	list-style: none !important;
	margin-left: -25px !important;
}


.titleContainer{
	text-align: center;
}
.titleContainer label{
	color: #201a47 !important;
	font-size: 26px !important;
	padding: 20px;

}
.productContainer{
	height: auto;
	padding: 20px;
	margin-top:20px;
	margin-bottom:20px;
	line-height: 25px;
	font-family: 'PT Sans', sans-serif;
	box-shadow: 5px 5px 5px 5px #aaa;
}
.productContainer label{
	color:#007993 ;
	font-size: 18px;
}
.imageContainer{
  padding-top: 5%;
  padding-bottom: 5%;
  height: auto;
}
.imageContainer img{
	height: 100%;
	width: 100%;
}
.customTablePP tr> td,th{
	border:1px solid #aaa;
	padding: 20px !important;
}
.customOl{
	    padding-left: 15px !important;
/*      list-style: none;
*/}
.customOl li{
	padding:5px !important;
}
.customOl li>a{
text-decoration: none !important}
.customOlOther{
      padding-left: 15px !important;
     list-style: none;
}
.customOlOther li{
  padding:5px !important;
}
.specifications{
	padding:10px;
}
.buyNowButtonPP{
	color: #fff;
	background-color: #201a47;
	padding: 12px;
  margin-top: 20px;
  font-size: 16px;

	cursor: pointer;
}

.enquireNow{
	color:#201a47;
	font-weight: bold;
	padding: 10px;
    margin-top: 20px;

  border:2px #201a47 solid;
	cursor: pointer;
	font-size: 16px;
}
.colored{
		color:#201a47 !important;
		font-weight: bold;
		cursor: pointer;
}
.lineSpace{
	line-height: 30px;
}
.investBlock{
	font-size: 18px;

}
.chartContainer{
	height: auto;
  text-align: center;
  padding: 10px;
  font-family: Arial;
}
.customIcon{
  padding: 20px;
  color: #fff;
  font-size: 20px !important;
  background-color: #201a47;
  border-radius: 50%;
  margin:10px;

}
.blueColor{
  color: #007993;
}
.listStyleNone li>p{
  padding: 10px;
  line-height: 30px;
  font-size: 20px;
}
.buyShares{
  text-align: center;
  font-family: Arial;
  padding: 40px !important;
}
.buyShares label{
  font-size: 40px;
  color: #201a47;
}
.chartContainer label{
color: #201a47;
font-size: 30px;
}
.chartContainer img{
	height: 100%;
	width: 100%;
  border:2px solid #aaa;
}
.nyscContainer{
	height: 300px;
}
.nyscContainer img{
	height: 100%;
	width: 100%;
}
.userImage{
	height: 50px;
	background-color: #333;
	border-radius: 50%;
}
.listStyleNone{
	list-style: none !important;
	padding-left: 0px !important;
}
.listStyleNone li{
	padding-top:8px !important;
}

/*other*/
.btn { 
  padding:6px; width:100%; border-radius:2px;
  background-color:#fff; color:#333;
  border:1px solid #777;  text-align:left; }
.btn-select { position:relative; }
.btn-select:after {
  content:"";
  display: block; 
  position:absolute; top:50%; right:10px; margin-top:-2px;  
  width: 0;
  height: 0;
  border-top: 4px solid #777;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown-menu-select { padding:0; margin-top:-2px; width:100%; border-radius:0 0 2px 2px; border-color:#777;}

.dropdown-radio { 
  display:block; position:relative;
  margin:0;
  width:100%; overflow:hidden; text-overflow:ellipsis;
  border-bottom:1px solid #eaeaea; 
  cursor:pointer;
}
.dropdown-radio input { 
  visibility: hidden; 
  position:absolute; left: -30px; }
.dropdown-radio i { 
  font-weight:normal; font-style:normal; 
  display:block; padding:7px; }

  .customDrop{
  	text-align: left !important;
  	border-radius: 2px !important;
  	overflow: hidden;
  }
  .dropdown-radio input{
  	visibility: visible !important;
  	left:7px;
  	top:7px;
  }
  .dropdown-radio i{
  	margin-left: 15px !important;
  }
  .titileName{
    color: #007993;
    font-size: 14px;
  }

  .hight400{
    height: 500px;
  }
  /*kyc css*/
  .formNameTitle {
    font-size: 30px;
    margin-top: 0%;
    /* margin-bottom: 13%; */
    padding: 10px;
    color: #201a47 !important;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif !important;
}

.hight450{
  height: 400px !important;
}
.backColorYellow{
  background-color: #FED880;
  padding: 40px;
  margin-bottom: 40px;
}
.backColorYellow span{
  font-size: 20px;
}
.backColorYellow label{
  font-size: 40px;
}
.sutableForHead{
  text-align:center !important;
    font-family: Arial;

}
.sutableForHead label{
  font-size: 40px;
  padding-top: 20px;
  color:#201a47;
}
.numberDiv{
  padding: 10px;
  color: #201a47;
}
.imgInfo{
/*  background-color: #FED880!important;
*/  height:auto; text-align: center;
  font-family: Arial;
  padding: 20px;
}
.imgInfo label{
  padding-top:20px;
  font-size: 40px !important;
  color:#201a47 !important;
}
.imgInfo p{
  font-size: 21px;
  padding: 10px;
  line-height: 30px;
  padding-top: 20px;

}
.listStyle{
  list-style: circle;
}
.backColorDBlue{
  background-color: #00819D;
}
.mt100{
  margin-top: 10%;
}
.numberOfDiv{
  padding: 20px;
  text-align: center;
  font-size: 20px;
  background-color: #00819D;
  color: #fff;
  border-radius: 50%;
  margin:5px;
}
.textOfProductDesc{
  font-size: 18px;
  font-weight: bold;
}

/*accordian css*/
a:hover,a:focus{
    text-decoration: none;
    outline: none;
}
#accordion .panel{
    border: none;
    border-radius: 3px;
    box-shadow: none;
}
#accordion .panel-heading{
    padding: 0;
    border: none !important;
    border-radius: 3px;
}
.panel-group{
  margin-bottom: 0px !important;
}
#accordion .panel-title a{
    display: none;
    background: #fff;
    font-size: 18px;
    font-weight: bold;
    color: #f81ac1;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    position: relative;
    transition: all 0.5s ease 0s;
}
#accordion .panel-title a.collapsed{
    box-shadow: none;
    color: #676767;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body{
  border-top: none !important;
}
#accordion .panel-title a:before,
#accordion .panel-title a.collapsed:before{
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    width: 25px;
    height: 25px;
    line-height: 28px;
    font-size: 15px;
    font-weight: 900;
    color: #f81ac1;
    text-align: center;
    position: absolute;
    top: 8px;
    right: 15px;
    transform: rotate(135deg);
    transition: all 0.3s ease 0s;
}
.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body{
  border-bottom: none !important;
}
#accordion .panel-title a.collapsed:before{
    color: #676767;
    transform: rotate(0);
}
#accordion .panel-title a:after{
    content: "";
    width: 1px;
    height: 100%;
    background: #ececec;
    position: absolute;
    top: 0;
    right: 55px;
}
#accordion .panel-body{
    padding: 10px 15px 15px;
    border: none;
    font-size: 15px;
    color: #615f5f;
    line-height: 27px;
}
.customPanelPP{
  border:none !important;
  text-align: left !important;
}
/*.customPanelPP .customHead{
  background-color: transparent !important;
}*/
.customHead a{
  color: #333 !important;
  font-weight: normal !important;
}
/*end*/
.bannerContainerSH{
    margin-top: -1px;

  background-image: url("/images/safeHBack.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerSF{
    margin-top: -1px;

  background-image: url("/images/superfocused.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerUS{
  margin-top: -1px;
  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/bannerImg1.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerSHM{
  margin-top: -1px;
  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/bannerImg11.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerFiveG{
    margin-top: -1px;

  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/safeH.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.bannerContainerStocks{
    margin-top: -1px;

  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/usS.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
/*  padding: 60px !important;
*/ font-family:Arial;
  height: 100%;
}
.blackDivPP{
  padding-top: 100px !important;
  padding: 60px !important;
   font-family:Arial;

  background-color: rgba(0,0,0,0.5) !important;
}
.blackDivPPSmall{
  padding-top: 50px !important;
  background-color: rgba(0,0,0,0.5) !important;
}
.riskDivPP{
  background-size: 100% 100%;
  margin-top: 40px;
  background-attachment: fixed;
  color: #fff;
  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/introToSHM.jpg");
}
.SftestimonialDivPP{
  background-size: 100% 100%;
  margin-top: 40px;
  /*background-attachment: fixed;*/
  color: #fff;
  background-image: url("/images/SFtestimonial.png");
}
.riskDivPP p{

}
.rriskDivPPiskDiv label{
  margin-top:10px;
  font-weight: normal;
  font-size: 20px;
  line-height: 47px;

}
.blogQuate{
  margin-top:20px;
  line-height: 70px;
  font-weight: bolder;
  font-size: 40px; 
}
.sfblogQuate {
    margin-top: 20px;
    line-height: 32px;
    font-weight: 500;
    font-size: 22px;
    /*border: none;*/
    padding-left:0px;
}
.centeredDiv{
  padding-top: 70px;
}
.text-left{
  text-align: left;
  padding-left: 20px;
  float:left;
}
.blogQuateSmall{
   margin-top:20px;
  font-weight: bolder;
  font-size: 35px; 
}
.nameOfAuther label{
  font-size:30px;
}
.addDiv{
  height: 100px;
}
.marginLeft10{
  margin-left: 20px;
}

.formContainer2{
    height: 900px;
    margin-top: 80px;
    padding:0px !important ;
    margin-bottom: 80px;
    /* padding-top: 40px; */
    padding-bottom: 30px !important;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,.075);
}
  .setTopMargin{
    margin-top: 30px;
  }
  .Mt55{
    margin-top: 30px;
  }

.setZindex{
    z-index: 1;
}
.setplusZindex{
    z-index: 2;
}
.ml10{
    margin-left: 10px;
}
.ml5{
    margin-left: 5px !important;
}
.page{
    height : auto;
}
.mgt{
    margin-top: 70px !important ;
}
.mt30{
    margin-top: 30px ;
}
.mt40{
    margin-top: 15px ;
    margin-bottom: 15px ;
}
    

.mt50{
    margin-top: 50px ;
}


.mb20{
    margin-bottom: 20px ;
}

.mt50{
    margin-top: 100px !important;
}
.submitButton{
    margin-top: 50px;
}
.paddingtop{
    padding-top: 0px !important;
}
.inputContainerRP1 {
    padding-top: 20px;
    height: 200px;
}
.ApplicationName{
    font-size: 15px;
    font-weight: bold;
}
.sm-mt30{
    margin-top: 120px !important;
}

.marginTop{
    margin-top: 15px !important;
}
.setFont{
    /* color: #fff; */
    font-weight: 500 !important ;
    font-family: arial;
    padding-bottom: 5px;
}

.mtcustomInputKF {
    display: block !important;
    width: 100%;
    height: 34px;
    padding: 3px 0px;
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    border: none;
}

.marginT{
    margin-top: 15px;
}
.marginB{
    margin-bottom: 19px;
}

.distributerTitle2 {
    font-size: 20px;
    margin-top: 8%;
    margin-bottom: 5%;
    /* color: #333333; */
    color: #f04723;
    font-weight: bold;
    text-align: center;
    font-family: 'PT Sans', sans-serif !important;
    /* font-family: 'Roboto-BoldCondensed'; */
}



.fontSize{
    font-size: 13px;
    color: red;
    
}


/*============@media screen=====================*/

@media (max-width: 767px) { 

  .formContainer2 { 
    height :  1400px;
  }
  .textpd{
    margin-top: 10px;
  }    
  .textpd1{
    margin-top: 10px;
  }
  .Zeropadding{
    padding: 0px 0px !important;
  }
  .Mt30{
    margin-top: 30px;
  }
  .Mt55{
    margin-top: 59px;
  }
  .setMobileResponsive{
    margin: 10px 0px 20px 0px !important;
    padding: 0px 7px 0px 15px !important;
  }
  .lbfloatpass {
      padding-left: 5px;
  }
  .setResPad{
      padding-left: 14px !important;
  }
  .textpd2{
    margin-left: 10px;
  }
  .mt10{
    margin-top: 20px;
  }
  .smsetMargin{
    margin-left: 10px;
  }
  .dobMarginT{
    margin-top: 8px !important;
  }

}

@media (min-width: 768px) and  (max-width: 991px){ 
  .formContainer2 { 
    height :  1400px;
    margin-top:250px;
  }
  .textpd{
    margin-top: 10px;
  }    
  .textpd1{
    margin-top: 10px;
  } 
  .Zeropadding{
    padding: 0px 0px !important;
  }
  .Mt30{
    margin-top: 30px;
  }
  .Mt55{
    margin-top: 40px;
  }
  .setMobileResponsive{
    margin: 10px 0px 20px 0px !important;
    padding: 0px 7px 0px 15px !important;
  }
  .lbfloatpass {
      padding-left: 5px;
  }
  .setResPad{
      padding-left: 14px !important;
  }
  .textpd2{
    margin-left: 10px;
  }
  .mt10{
    margin-top: 20px;
  }
  .smsetMargin{
    margin-left: 10px;
  }
  .dobMarginT{
    margin-top: 8px !important;
  }

}

.removevalue{
  color: transparent;
}
.resendOtpColor{
	margin-left: 0px !important;	
}

.parteroptresent{
	font-size: 14px;
    text-align: center;
    padding: 5px;
    margin-left: 7px !important;
    margin-right: 7px !important;
    width: 93% !important;
    border-radius: 4px;
    font-weight: bold;
    background: #d8d8d8!important;
    cursor: pointer;

}
.nopadding{
	padding: 0px 0px;
}

.mt100{
	margin-top: 100px;
}
.mb50{
	margin-bottom: 20px;

}
.pagewrapper{
	height: auto;
}
.setM{
	height: 100px;
	/* width : 250px; */
}
.setWidth{
	width: 100px;
	margin: auto;
}

.mtop70{
	margin-top: 70px;
}
.setColor{
	height: 1000px;
}

.green{
	height: 320px;
}
.setSize{
	height: 100px;
	/* padding-left: auto; */
	background-color: #ccc;
}
.setColor{
	background-color: #f00;
}
.setText{
	color: #fff;
	text-align: center;
	font-weight: 500;
	background-color: #201a47;

}
.setFile{
	height: 270px;
	margin: auto;
	background-color: #ccc;
}

.mt40{
	margin-top: 40px;
}

.marginSet{
    margin-bottom: 20px;
}
.setSize a img{
	margin-left: 100px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;

}
.setFile a img{
	margin-left: 130px;
	margin-top: 80px;

}


.AllblogImgB{
	height: 173px!important;
	width: 100%;
}

.toolFile{
	overflow: hidden;
	height: 80px;
	text-align: justify;
}
.All1tools:hover .blogTitle{
	color:#25bbf3!important;
}
.All1tools{
	position: relative;
	height: auto;
	overflow: hidden;
	margin-bottom: 50px;
	padding-bottom: 30px;
	background-color: #fff;
	box-shadow: 0px 11px 15px 1px #ccc;
}
.p10{
	padding-left:  15px !important;
	padding-right: 15px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}

.z50{
	position: relative;
	top: -75px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 4%;
 right: -2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.All1tools:hover .middle {
  opacity: 1;
}

.wclr{
	color: #fff;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}
.rclr{
	color: #f00;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}

.hoverbk{
	background-color: #111;
    border-radius: 12px;
}
.toolDate {
    font-size: 13px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}


.p10{
	padding-left:  15px !important;
	padding-right: 15px;
}
.pl25{
	padding-left:  25px !important;
	padding-right:  15px;

}
.pt40{
	padding-top: 18px;
}
.toolsDate{
    margin-top: 56px;
}

.setBackground{
	background-color: #191818ba;
	color: #fff;
	border: 1px solid #aaa;

}

input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
}


.todaysdate{
    text-align: center;
    width: 140px !important;
    height: 33px;
}
.date{
	font-size: 18px;
	font-family: Arial;
	padding-top: 10px;
	padding-bottom: 10px;
    color: #7e8183;
    font-size: 14px;
    line-height: 25px;
}
.searchinput{
    color: #000;
}

.datebox{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.tab-pane .marginStyle .setLeftHeight{
    height: 33px !important;
    border: 1px solid #aaa;
}

.setmarginTop{
	padding-top: 3px;
	font-size: 20px;
}

.setTextColor{
	color: #ccc;
}

@media screen and (max-width: 600px) {
	.toolsDate{
		margin-top: 15px;
		/* padding-left: 120px; */
		/* margin-bottom: 20px; */
	}
	.setPaddingLeft{
		padding-left: 120px !important;
	}
	.marginSet{
    	margin-bottom: 20px;
	}
	.setSizeResponsive{
		/* width: 50%; */
		margin-top: 70px !important;
	}
	.setMarginTop{
		margin-top: 25px ;
	}
}

.AllBlogsbannerWall{
  height: 435px;
  /*background-color:  #444;*/
  padding: 0px !important;
  position: relative;
    text-align: center;
    color: white;
    background-image: url("/images/ceo.png");
    background-size: 100% 100%;

}
.nopadding{
  padding: 0px;
}
.AllBlogscentered {
  background-color: #191818ba;
  padding: 0px !important;
  height: 435px;
  text-align:center !important;

}
.AllBlogstextcentered{
  position: absolute;
  top: 25%;
  text-align: center !important;
  left: 0%;
  font-family: "Arial";
  
}

.fs72{
  font-weight: 600;
  font-size: 72px;
}
.fs24{
  font-size: 22px;
}

.AllBlogsBox{
	background-color: #f6f6f6;
	border: 1px solid #eaeaea;
	padding: 20px;
}

.blogTitle{
	margin: 5px 0px;
	font-size: 18px;
    margin: 5px 0px;
    line-height: 23px;
    font-family: "Arial";
}
.blogTitleList{
	margin: 5px 0px;
    font-size: 1.8em;
    line-height: 23px
	 
}
.blogTitleListSmall{
	margin: 5px 0px;
    font-size: 14px;
    line-height: 23px
	 
}
.premiumBlogIndicateList{
	color: #f5d26e;
    font-size: 16px;
    position: absolute;
    margin-top: -11px;
    margin-left: -4px
}
.AllblogImgB{
	height: 173px!important;
	width: 100%;
}
.AllblogImgList{
	height: 80px!important;
}
.Allblog a:hover{
	text-decoration: none;
}
.Allblog:hover .blogTitle{
	color: #1db1f1;
}
.blogPara{
	overflow: hidden;
	height: 125px;
	text-align: justify;
	text-overflow: ellipsis;
	padding-top: 10px;
	overflow-wrap: break-word;


}
.blogParaList{
	overflow: hidden;
	text-align: justify;
	text-overflow: ellipsis;
	overflow-wrap: break-word;


}
.All1blog1{
	position: relative;
	height: 330px;
	overflow: hidden;
	margin-bottom: 50px;
	background-color: #fff;
	box-shadow: 0px 11px 15px 1px #ccc;
}
.All1blogList{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 5px;
	padding-top: 10px !important;
	margin-top: 7px;
	margin-bottom: 7px;
	background-color: #fff;
}
.p10{
	padding-left:  20px;
	padding-right: 20px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}

.z50{
	position: relative;
	top: -75px;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 4%;
 right: -2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.All1blog1:hover .middle {
  opacity: 1;
}

.wclr{
	color: #fff;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}
.rclr{
	color: #f00;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}

.hoverbk{
	    background-color: #111;
    border-radius: 12px;
}
.noBlogs{
	margin-top: 5%;
	margin-bottom: 10%;
	font-size:30px !important;
}
.premiumBlogIndicate{
	  position: absolute;
	  left: -20px;
	  top:14px;
	  padding-left: 20px;
	  padding-right:  20px;
	  transform: rotate(-40deg);
	  background-color:#f5d26e;
	  color: #333;
	  font-size: 16px;

}
.loadingImageContainer{
	height: 350px;

}
.loadingImageContainer img{
	height: 100%;
	width: 100%;

}
.customGridIcon{
	margin-top: 60px;
	margin-right: 10px;
	cursor: pointer;
	font-size: 20px !important;
}
.blogDate{
	font-size: 12px;
}
.searchIconABB{
  font-size: 20px;
  margin-top: 49px;
  cursor: pointer;
  color: #fff;

}
.open{
    display: block !important;
    transition: 10s all ease !important;

}
.AllblogList{
	margin-top: 40px;
}
.customInputAllBlog{
  margin-left:10px;
  height: 30px;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  margin-right: 10px;
  background-color: transparent;
  background-image: none;
  border:none;
}
.outerborder{
  margin-top: 55px;
	border: 1px solid #aaa;
	height: 30px;
	border-radius: 5px;
	margin-right: 10px;
}
.pad10search{
	padding-top: 7px !important;
	padding-left: 5px !important;

}
/*input[type=text] {
  width: 10px;
  border:none;
  margin-top: 50px;
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  background-image: url('/images/magnifying-glass.png');

  background-position: 10px 10px; 
  background-repeat: no-repeat;
  cursor:pointer;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
input[type=text]:focus {
  width: 20%;
   box-sizing: border-box;
  border: 2px solid #ccc;
 
}
*/
.customName{
	font-weight: normal;
    width: 148px !important;
    overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
}

.margin0{
	margin:0px !important;
}
.SearchIN{
	padding:0px !important;
	padding-top: 15px !important;
	cursor: pointer;
}
.customPillsBlogs{
	margin-top: 20px;
 font-family:Arial;
}
.customPillsBlogs li{
	color: #333 !important;
	font-size: 21px;
	font-weight: bold;
	 font-family:Arial;

	margin-right: 15px;
}
.customPillsBlogs>li.active>a, .customPillsBlogs>li.active>a:focus, .customPillsBlogs>li.active>a:hover{
	background-color: transparent !important;
	color: #201a47 !important;
}
.customPillsBlogsSmall{
 font-family:Arial;
}
.customPillsBlogsSmall li{
	color: #333 !important;
	font-size: 140%;
	font-weight: bold;
}
.customPillsBlogsSmall li>a{
	padding:0px !important;
	padding-top:10px !important;
	margin-right: 15px;

}
.customPillsBlogsSmall>li.active>a, .customPillsBlogsSmall>li.active>a:focus, .customPillsBlogsSmall>li.active>a:hover{
	background-color: transparent !important;
	color: #201a47 !important;
}
.descriptionData{
	padding: 15px;
}
.blogBody{
	border:1px solid #aaa;
	padding: 20px;
	height: auto;
	cursor: pointer;
	margin-top: 20px;
	margin-bottom:  20px;

}
.blogContent{
	margin-top: 20px;

}

.blogImage{
	height: 350px;
	margin-top: 20px;

}
.blogImage img{
	height: 100%;
	width: 100%;
}
.logoOfBlogs{
	height: 42px;
	border-radius: 50%;
}
.nameOfBoggerAB{
	padding-left: 15px !important;
	margin: 0px !important;

}
.dropDownMenuDiv a{
 padding: 10px;
}
.blogContantDiv{
	line-height: 25px;
	font-size: 13px;
	padding-bottom: 20px;
	border-bottom: 1px solid #aaa;

}
.blogContantDiv label{
	font-size: 25px;
	padding-top: 20px;
	padding-bottom: 10px;
}
.commentSectioAB{
	padding-top: 10px;
}
.sbbannerWall{
	height: 535px;
	/*background-color:  #444;*/
	padding: 0px !important;
	position: relative;
  	text-align: center;
  	color: white;
  	/*background-image: url("/images/blogBack.jpg");*/
  	background-size: 100% 100%;

}
.nopadding{
	padding: 0px;
}
.sbcentered {
  background-color: #19181847;
  padding: 0px !important;
  height: 535px;

}
.sbbtextcentered{
  top: 25%;
}
.fs40{
  font-size: 40px;
  margin-top: 40px;

}

.fs72{
  font-weight: 600;
  font-size: 72px;
}
.fs24{
  font-size: 22px;
}
.ht100{
  height: 100px;
}
.blockquote{
	margin: 27px 0px 27px 0px;
    padding: 0px 0px 0px 27px;
    background-color: #f4f4f4;
    border-left: 5px solid #201A47 !important;
}
.blogBox p{
	font-family: Arial;
	text-align: justify !important;
	color: #444;
	margin-top: 40px;
}

.blogBoxSmall p>big>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}
.blogBoxSmall p>strong>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}
.blogBoxSmall p>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}

.blogBox p>big>img{
	height: 100% !important;
	width: 100% !important;
	margin:0px !important;
}

.blogBoxSmall p{
	font-family: Arial;
	text-align: justify !important;
	color: #444;
	overflow-wrap: break-word;

}
.blogBoxSmall p>big>a{
	overflow-wrap: break-word;
}
.blogBoxSmall p>a{
	overflow-wrap: break-word;
}

.sCont{
	font-size: 14px;
}
.userbtn{
	padding: 8px 30px;
    width: 155px;
    margin-top: 20px;
    margin-bottom: 25px;
    cursor: pointer;
	border: 1px solid #dddddd;
	background-color: #fff;
	-webkit-columns: #111;
	        columns: #111;
}
.userbtn:hover{
	background-color: #dddddd;
	color: #fff;
}
.mt40{
	margin-top: 40px;
}
.RelatedBlogsBox{
	background-color: #fafafa;
	padding: 20px;
}
.mtop75{
	margin-top: 85px; 

}
.r1blog1:hover .blogTitle{

	color: #1db1f1;

}
.r1blog1 a:hover{
		text-decoration: none;

}
.blogTitle{
	font-size: 18px;
	margin: 5px 0px;
}
.blogImgB{
	height: 173px!important;
	width: 100%;
}
.rblog{

	position: relative;
	height: 330px;
	overflow: hidden;
	margin-bottom: 50px;
	background-color: #fff;
    padding: 0px !important;
	box-shadow: 2px 2px 15px 4px #aaa;

}
.r1blog1{
	height: 375px;
	margin-bottom: 50px;
	/*background-color: #aaa;*/
	box-shadow: 2px 2px 15px 4px #aaa;
}
.p10{
	padding-left:  25px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}
.premiumBlogIndicateRB{
    position: absolute;
    left: -20px;
    top: 14px;
    padding-left: 20px;
    padding-right: 20px;
    transform: rotate(-40deg);
    background-color: #f5d26e;
    color: #333;
    font-size: 16px;
}
.likeDiv{
	border-top: 1px solid #aaa;
	padding: 15px;
	font-size: 20px !important;
}
.likeDiv a{
	padding: 10px;
}
.likeDivSmall{
	border-top: 1px solid #aaa;
	padding: 5px;
	font-size: 20px !important;
	display: flex;
    box-shadow: 1px 1px 10px 2px #ccc;
	justify-content:space-between;
	position: fixed !important;
    bottom: 0;
    z-index: 1000;

    background-color: #fff;
    right: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100% !important;
}
.likeDivSmall a{
	padding: 10px;
	font-size: 20px;
}
.likeDivSmall i{
	font-size: 28px;
}
.bottomDiv{
	font-family: 'Arial';
	padding:15px;
	padding-left:25px !important;
}
@media print 
{
    tr, td, th {page-break-inside:avoid}
    thead {display:table-header-group}
    .NoPrint {visibility:hidden; display:none}
    a {color:#000000}
}
.selectedPlan{
	height: auto;
/*  padding:30px;*/
  font-size: 15px;
  background-size: 100% 100%;
	margin-top:120px !important;
  margin-bottom:80px !important; 
  border:1px solid #333;
	font-family: Arial !important;

}
.selectedPlan label{
	font-size: 40px;
}
.selectedPlan img{
  padding-top:8px;
  height: 90px;
}
.PlanDetails{
	height: auto;
	padding: 15px !important;
	font-family: Arial !important;
	margin-bottom: 50px;
  box-shadow: 1px 1px 10px 2px #ccc;

}
.centreDetaillistItemIP{
  padding-left: 0px !important;
  font-family: Arial !important;
  font-weight: bold;
  font-size: 25px !important;
}
.priceDivIP{
  font-weight: bold;
  font-size: 25px !important;
}
.radioCheckIP{
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
.companyDetails{
  margin-top: 12px;
}
.centreDetailContainer input:checked ~ .radioCheckIP {
  background-color: #201a47;
  border:2px solid #201a47;
   height: 30px;
  width: 30px;
}
.customUlIP{
  list-style: none;
  font-size: 16px;
  padding-left: 20px !important;
  
}
.customUlIP li{
 padding-top: 5px;
}
.customUlIPFeatures{
  list-style: circle;
  font-size: 16px;
  padding-left: 40px !important;
  
}
.customUlIPFeatures li{
 padding-top: 5px;
}
.listStyleNone{
  list-style: none !important;
    margin-left: -20px !important;

}
.btnContainer{
  margin-bottom: 40px;

}
.makePaymentButton{
	padding: 15px;
	margin-bottom: 5px;
	background-color: #10a4e2;
	color: #fff;
	font-family: Arial !important;
	text-align: center;
	cursor: pointer;
	font-weight: bold;
	font-size: 15px;
	border-radius: 3px;
}
.fs30{
  font-size: 30px;
}
.priceDivUP{
  border-top:5px #201a47 solid;
  padding:40px;
}
.iconContainerIP{
  padding-top: 40px;
  height: 180px;
}
.logoContainerIP{

  padding-top: 20px;
  height: 180px;
}
.invoiceHead{
  font-size: 22px;

}
.dateContain{
  font-size: 14px !important;
}
.paymentDetails{
  color: #333;

}
.userDetails{
  color: #333;
}
.userName{
  color: #10a4e2;
  text-transform: capitalize;
  font-size: 24px;
}
.PaymentContainer{
  color: #10a4e2;
  font-size: 14px;
}
.customTableIP{
  color: #333 !important;
}
.customTableIP, th, td {
  border: 0px solid #aaa;
      text-align: center !important;

}
.customTableIP th{
  color: #fff !important;
  background-color: #10a4e2; 
}
.customTableIP td {
    background-color: #afafaf2e; 

}
.customTableIPTD{
padding: 15px !important;

padding-top: 50px !important;
padding-bottom: 50px !important;

}
.thankYouDiv{
  font-size: 25px;
  text-align: right;
  color: #10a4e2;
}
.bottomDiv{
  margin-top: 40px;
  border-bottom: 3px solid #10a4e2;
}
.receiptFooter{
  text-align: center;
  padding: 10px;
}
.receiptFooter label{
  font-size: 14px !important;
}
.PlanDetailsPlan{
	height: auto;
	padding: 50px !important;
	border-radius: 10px;
	text-align: left;
	font-family: Arial !important;
	margin-bottom: 50px;
    box-shadow: 1px 1px 10px 2px #ccc;
    transition-duration: 0.5s;
    cursor: pointer;
}
.PlanDetailsPlan:hover{
    box-shadow: 1px 1px 10px 10px #ccc;
        transition-duration: 0.5s;
    cursor: pointer;

}
.planName{
	font-size: 45px;
	color: #201A47 !important;
}
.timePeriod{
	font-size: 25px;
	margin-bottom: 40px;
}
.buyNowPlan{
	padding: 15px !important;
	color: #fff;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	border-radius: 3px;
	background-color: #201A47;
}
.PlanContainer{
	padding-bottom: 100px;
}
.selectedPlanPP{
    padding: 30px;
    font-size: 40px;
    margin-top: 120px;

    background-size: 100% 100%;
    margin-top: 120px !important;
    font-family: Arial !important;
}
.centreDetaillistItemOP{

}
.centreDetaillistItemOP{
  padding-left: 0px !important;
  padding-bottom:  15px;
  font-family: Arial !important;
  font-weight: bold;
  font-size: 18px !important;
}
.dateDivOP{
  font-weight: bold;
  font-size: 20px !important;
}
.PlanDetailsOP{
	height: auto;
	border-top:10px #201a47 solid;
	padding: 40px !important;
	border-radius: 5px;
	font-family: Arial !important;
	margin-bottom: 50px;
    box-shadow: 1px 1px 10px 2px #ccc;
    cursor: pointer;
}
.PlanDetailsOP:hover{
	
    box-shadow: 1px 1px 10px 8px #ccc;
    cursor: pointer;
}
.PlanDetailsOP:hover .hoverEffect{
	color: #109dd7 !important;
}
.noBold{
	font-weight: normal !important;
}
.orderDetailsDiv{
	border-bottom: 1px solid #ccc;
	padding: 0px !important;
	padding-bottom: 10px !important;
	padding-top: 10px !important;
}
.orderDetailsDiv label{
		font-weight: normal !important;
}
.orderSummaryHead{
	font-size: 18px;
}
.totalDiv{
	margin-top: 10px;
}
.selectedPlanOP{
	height: auto;
/*  padding:30px;*/
  	font-size: 30px;
  	background-size: 100% 100%;
	margin-top:120px !important;
  	margin-bottom:80px !important; 
	font-family: Arial !important;

}
.selectedPlanNoOrders{
	height: 200px;
/*  padding:30px;*/
  	font-size: 30px;
  	text-align: center;
  	background-size: 100% 100%;
	margin-top:220px !important;
  	margin-bottom:150px !important; 
	font-family: Arial !important;

}
.blogFormBox{
	
	margin: 25px 0px;
}
.ckbox{
	height: 375px;
}
.boxform1{
	position: relative;
	top: 25px;
}

.headingofdashboard{
	font-size: 35px;
	font-weight: normal !important;
	margin-top: 20px;
	padding-bottom: 10px;
	 font-family:Arial;

}
.dateondashboard{
	font-size: 16px;
	font-weight: normal !important;
	margin-top: 30px;
	 font-family:Arial;
	 box-shadow: 0px 2px 6px 4px #aaa;
	 padding:6px;
	 border-radius: 5px;


}
.riskprofileformbox{
	/*margin-top: 80px;*/
    /*margin-bottom: 80px;*/
    padding: 0 0 20px!important;
    box-shadow: 0 0 5px 5px rgba(0,0,0,.075);
    padding: 2% !important; 
}
.risksubmitButton {
    padding: 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border: 0px;
    text-align: center;
    font-size: 14px;
    margin-top: 20px !important;
    background-color: #009dd7!important;
}
.ansoptions{
    color: #333;
}
.kycformbox{
	/*margin-top: 80px;*/
    /*margin-bottom: 80px;*/
    padding: 0 0 30px!important;
    box-shadow: 0 0 5px 5px rgba(0,0,0,.075);
    padding: 4% !important; 
}
.headerServices{
	padding: 10px;
	padding-bottom: 20px;
	font-size: 25px;
	border-bottom: 1px solid #aaa;
}
.blogContainCD{
	border-radius: 10px;
	margin-top:40px; 

	border: 1px solid #aaa;
	padding: 20px !important;
	padding-top: 30px !important;
}
.blogContainCD h4{

	text-align: center;
}	
.headerBlogCD{
	font-size: 25px;
	padding-bottom: 20px;
	padding-top: 20px;
	font-weight: bold !important;
	border-top: 1px solid #aaa;
}
.customTabContent{
	padding: 20px !important;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 2px 6px 4px #aaa;
    min-height: 480px !important;
}
.customStack{
	background-color:	#201a47;  
	border-radius: 10px;
	padding: 20px !important;
		cursor: pointer;

}
.customStack>li.active>a, .customStack>li.active>a:focus, .customStack>li.active>a:hover{
	border-radius: 3px !important;
	background-color:#00BAF2 !important;
	color: #333;
	cursor: pointer;
}
.customStack>li.active>a:hover{
 	color: #333 !important;
 		cursor: pointer;

}
.planContainer{
	padding: 15px !important;
	margin-bottom: 40px;
	font-family:Arial;
	border-radius: 10px;
	color: #aaa ;

}
.planContainer label{
	color: #333 !important;

}
.customStack li>a{
	background-color: #ddd;
	font-size: 16px;
	border:1px solid #aaa;
	padding: 15px !important;
	color: #666;
	margin-bottom:10px !important; 
}
.performancetab a, .kyctab a, .riskprofiletab a  {
	border:none !important;
	background-color: #201a47 !important;
    color: #fff !important;
    width: 370px;
    border: none !important;
}
.performancetab li:hover a, .kyctab li:hover a, .riskprofiletab li:hover a{
	background-color: #337ab7 !important;
}
.customTabContent h3{
 text-align: center;
 color: #201a47;
}
.customTabContent h5{
 text-align: center;
 color: #333;
}
.clickToPlan{
	color: #00f;
	font-size: 12px;
	cursor: pointer;
}
.myULCD li{
	cursor: pointer;
	font-size: 15px;

}
.myULCD a>li:hover{
	color: #aaa !important;
	text-decoration: none !important;
}
.myULSS li:hover{
	text-decoration: none !important;
	box-shadow: 0px 2px 14px 2px #aaa;
	
}
.myULSS li{

	cursor: pointer;
	font-size: 15px;
	list-style: none;
	background-color: #00baf2;
	margin-bottom:10px;
	border-radius: 30px;
	padding: 8px !important;	
	padding-left: 15px !important;	
	padding-right: 15px !important;	
	
}
.myULSS li>a{
	color: #fff;
	
}

.myULSS li>a:hover{
	color: #00f !important;
	text-decoration: none !important;
}
.myULCDSP{

	padding-left:0px !important;
}
.myULCDSP li{
	font-size: 15px;
	color: #666  !important;
	padding-bottom: 0px !important;
	list-style: none !important;
}
.myULCDSP li>a{
	color: #666  !important;
}
.myULCDSP li>a:hover{
	text-decoration: none !important;
	color: #666 !important;
}
.priBlogHead{
	font-size: 20px;
	color: #201a47;
}
.padding100{
	padding-bottom: 50px;
}
.activeSubscription{
	background-color: #24baf2 !important;
	color: #333 !important;
}
.customStack>li>a:hover{
	background-color: #0edaef !important;
    color: #333 !important;
}
.marLeft{
	margin-left: 20%;
}
.mainContainerSS{
	background-color: #00BAF2;
}
.performancetab{
	position: absolute !important;
    left: 324%;
    top: 6px;
    width: 100%;
    z-index: 1000 !important
}
.performancetabSmall{
	position: absolute;
    left: 0px;
    top: 101em;
    width: 100%;
    z-index: 1000 !important;

}
.kyctab{
	position: absolute !important;
    left: 324%;
    top: -116px;
    width: 100%;
    z-index: 1000 !important
}
.kyctabSmall{
	position: absolute;
    left: 0px;
    top: 61em;
    width: 100%;
    z-index: 1000 !important;

}
.riskprofiletab{
	position: absolute !important;
    left: 324%;
    top: -55px;
    width: 100%;
    z-index: 1000 !important
}
.riskprofiletabSmall{
	position: absolute;
    left: 0px;
    top: 81em;
    width: 100%;
    z-index: 1000 !important;

}
.marginTopM
{
	margin-top: -60px;
}
.borderTop{
	border-top:1px solid #aaa;
	padding-top: 10px;
}
.height310{
	height: 310px;
}
/*.customStack:first-child {
  background-color: yellow;
}
*/
.ht200{
	height: 100px;
    padding: 10px;
    display: inline-block;
    /* width: 143px !important; */
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.borderRadiusT{
	border-radius: 3px;
}
.mt15{
	margin-top: 15px !important;
}
.sbscribblogbtn{
	margin-top: 10px !important;
}
.productinvestbtn{
	margin-top: 30px !important;
}
.kycprofilebtn{
	margin-bottom: 6px;
}
.nav .kycprofilebtn a:hover, .nav .kycprofilebtn a:active{
	background-color: #0edaef !important;
    color: #333!important;
}
.kycprofilebtn a{
	background-color: #201a47;
    color: #fff !important;
    font-size: 16px;
    padding: 15px!important;
}

#overlayproductexpired{
	position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0.5;
}

#productexpiredModal .modalHeaderCustom {
	z-index: 1111 !important;
}

.productexpiredbtn{
	color: #ffffff !important;
	padding:10px;
}	

@media (min-width: 768px){ 
.productexpiredmodal-dialog {
    width: 510px !important;
    margin: 30px auto;
    height: 360px !important;
    margin-top: 12%!important;
}
.pad50new{
	padding: 0px 50px 30px 50px !important;
}
}

.disbledkycrisk{
	color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

@media (max-width: 767px){ 
	.headerBlogCD{
		margin-top: 30%;
	}
	.premiumsbc{
		margin-top: 50% !important;
	}
}
.PlanDetailsPlan{
	height: auto;
	padding: 50px !important;
	border-radius: 10px;
	text-align: left;
	font-family: Arial !important;
	margin-bottom: 50px;
    box-shadow: 1px 1px 10px 2px #ccc;
    transition-duration: 0.5s;
    cursor: pointer;
}
.PlanDetailsPlan:hover{
    box-shadow: 1px 1px 10px 10px #ccc;
        transition-duration: 0.5s;
    cursor: pointer;

}
.productName{
	font-size: 25px;
	color: #201A47 !important;
}
.productPrice{
	font-size: 35px;
	color: #201A47 !important;
}
.timePeriod{
	font-size: 25px;
	margin-bottom: 40px;
}
.buyNowPlan{
	padding: 15px !important;
	color: #fff;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	border-radius: 3px;
	background-color: #201A47;
}
.PlanContainer{
	padding-bottom: 100px;
}
.selectedPlanPP{
    padding: 30px;
    font-size: 40px;
    margin-top: 120px;

    background-size: 100% 100%;
    margin-top: 120px !important;
    font-family: Arial !important;
}

.paidtag {
    padding: 15px !important;
    color: #fff;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 3px;
    background-color: #5cb85c;
    border-color: #4cae4c;
}    
@media print 
{
    tr, td, th {page-break-inside:avoid}
    thead {display:table-header-group}
    .NoPrint {visibility:hidden; display:none}
    a {color:#000000}
}
.note{
	font-size: 25px;
	color: #0f98d1;
	margin-top: 50px;
}
.noteRed{
  font-size: 25px;
  color: #f00;
  margin-top: 50px;
}
.outerBorder{
	border:1px solid #aaa;
}
.selectedPlanPR{
  height: 335px;
  padding:30px;
  color: #fff;
  font-size: 15px;
  background-size: 100% 100%;
  font-family: Arial !important;
  background-image: url("/images/bannerImgOrder.jpg");
}
.selectedPlanPR label{
	font-size: 40px;
}
.selectedPlanPR img{
  padding-top:8px;
  height: 90px;
}
.mt100{
	margin-top: 100px;
	margin-bottom: 60px;
}
.warning{
	font-size: 14px;
	font-weight: normal !important;
}
.successPay{
	color: #0cd222;
}
.failPay{
  color: #f00;
}

.researchpage {
    padding-top: 80px;
    padding-bottom: 80px;
    font-family: Arial;
}
.top-border-block {
    padding: 25px 30px 30px;
    margin-bottom: 30px;
    background: #fff;
    border-top: 10px solid #c9c9c1;
    position: relative;
}
.top-border-block:before {
    content: "";
    height: 10px;
    width: auto;
    display: block;
    position: absolute;
    background: url(/images/border-pat.jpg) repeat-x;
    top: -10px;
    left: 14px;
    right: 14px;
}    
.pageheading {
	font-size: 4rem;
    padding-top: 10px;
    padding-bottom: 20px;
    color: #201a47;
    line-height: 1.2;
    font-weight: bold;
}
.reporttitle{
	font-size: 27px;
    margin-top: 5px;
    color: #201a47 !important;
    font-family: Arial;
    font-weight: 600;
    line-height: 1.3
}
.reportdescription {
	font-size: 17px;
	font-family: Arial;
	padding-top: 10px;
	padding-bottom: 10px;
}
.date{
	font-size: 18px;
	font-family: Arial;
	padding-top: 10px;
	padding-bottom: 10px;
    color: #7e8183;
    font-size: 14px;
    line-height: 25px;
}
.searchinput{
    color: #000;
}
.reportborder {
    margin-top: 30px;
    margin-right: 0px !important;
}
.imgpdf{
    width:100%;
}
.imgblock{
    padding-left: 0px !important;
}
.datebox{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.fa-download:before {
    /* color: #201a47; */
    color: #25bbf3 !important;
    font-size: 20px;
}
.downloadpdf-img{
    width: 72px;
}
.leftspace{
    margin-left: 5px;
}
.searchreportinput{
    width:84%;
}
.lineheightdate{
    line-height: 1.8
}
.lineheightarrw{
    line-height: 1.26 !important
}
@media only screen and (max-width: 767px){
.pageheading {
    font-size: 24px;
    padding-top: 20px;
} 
.leftspace{
    margin-left: 5px !important;
}
.reportborder {
    margin-top: 0px;
    margin-bottom: 30px;
}   
.top-border-block {
    padding: 20px 10px 20px 10px;
}
}

input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
}

.todaysdate{
    text-align: center;
}

.margintopReport{
    margin-right: -20px;
    float: right !important;
}
    
@media print 
{
    tr, td, th {page-break-inside:avoid}
    thead {display:table-header-group}
    .NoPrint {visibility:hidden; display:none}
    a {color:#000000}
}
.selectedPlan{
	height: auto;
/*  padding:30px;*/
  font-size: 15px;
  background-size: 100% 100%;
	margin-top:120px !important;
  margin-bottom:80px !important; 
  border:1px solid #333;
	font-family: Arial !important;

}
.selectedPlan label{
	font-size: 40px;
}
.selectedPlan img{
  padding-top:8px;
  height: 90px;
}
.PlanDetails{
	height: auto;
	padding: 15px !important;
	font-family: Arial !important;
	margin-bottom: 50px;
  box-shadow: 1px 1px 10px 2px #ccc;

}
.centreDetaillistItemIP{
  padding-left: 0px !important;
  font-family: Arial !important;
  font-weight: bold;
  font-size: 25px !important;
}
.priceDivIP{
  font-weight: bold;
  font-size: 25px !important;
}
.radioCheckIP{
  position: absolute;
  top: 2px;
  left: 52px;
  vertical-align: center !important;
  align-content: center;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-left: -37px;
  z-index: 0;
}
.companyDetails{
  margin-top: 12px;
}
.centreDetailContainer input:checked ~ .radioCheckIP {
  background-color: #201a47;
  border:2px solid #201a47;
   height: 30px;
  width: 30px;
}
.customUlIP{
  list-style: none;
  font-size: 16px;
  padding-left: 20px !important;
  
}
.customUlIP li{
 padding-top: 5px;
}
.customUlIPFeatures{
  list-style: circle;
  font-size: 16px;
  padding-left: 40px !important;
  
}
.customUlIPFeatures li{
 padding-top: 5px;
}
.listStyleNone{
  list-style: none !important;
    margin-left: -20px !important;

}
.btnContainer{
  margin-bottom: 40px;

}
.makePaymentButton{
	padding: 15px;
	margin-bottom: 5px;
	background-color: #10a4e2;
	color: #fff;
	font-family: Arial !important;
	text-align: center;
	cursor: pointer;
	font-weight: bold;
	font-size: 15px;
	border-radius: 3px;
}
.fs30{
  font-size: 30px;
}
.priceDivUP{
  border-top:5px #201a47 solid;
  padding:40px;
}
.iconContainerIP{
  padding-top: 40px;
  height: 180px;
}
.logoContainerIP{

  padding-top: 20px;
  height: 180px;
}
.invoiceHead{
  font-size: 22px;

}
.dateContain{
  font-size: 14px !important;
}
.paymentDetails{
  color: #333;

}
.userDetails{
  color: #333;
}
.userName{
  color: #10a4e2;
  text-transform: capitalize;
  font-size: 20px;
}
.PaymentContainer{
  color: #10a4e2;
  font-size: 14px;
}
.customTableIP{
  color: #333 !important;
}
.customTableIP, th, td {
  border: 0px solid #aaa;
      text-align: center !important;

}
.customTableIP th{
  color: #fff !important;
  background-color: #10a4e2; 
}
.customTableIP td {
    background-color: #afafaf2e; 

}
.customTableIPTD{
padding: 15px !important;

padding-top: 20px !important;
padding-bottom: 20px !important;

}
.thankYouDiv{
  font-size: 25px;
  text-align: right;
  color: #10a4e2;
}
.bottomDiv{
  margin-top: 40px;
  border-bottom: 3px solid #10a4e2;
}
.receiptFooter{
  text-align: center;
  padding: 10px;
}
.receiptFooter label{
  font-size: 14px !important;
}

.margintopad{
  margin-top:40px;
}
.amountwords{
  text-align: right;
}
.paracontent{
  line-height: 1.6;
  font-size: 15px;
  font-family: Arial !important;
}
@media print 
{
    tr, td, th {page-break-inside:avoid}
    thead {display:table-header-group}
    .NoPrint {visibility:hidden; display:none}
    a {color:#000000}
    .iconContainerIP{ padding-top: 0px; height: auto; }
    .mt20{margin-top: 0px; }
    .margintopad {  margin-top: 0px; margin-bottom: 20px !important; }
    .customTableIPTD { color: #000 !important;  padding: 10px !important; padding-top: 10px !important;   padding-bottom: 10px !important; border: 1px solid #aaa; }
    .customTablePP tr> td, th { border: 1px solid #aaa; color: #000 !important; padding: 10px !important; }
    .textAlignRight {    text-align: left !important; }
    .succesmsg{ display: none; }
    .emptyh4{ margin:0px;padding:0px;line-height:1;    }
    .dateContain{margin-bottom: 0px;padding-bottom: 0px;}
    .invoicetoh4{ margin:0px;padding:0px;line-height:1;    }
}
.note{
	font-size: 25px;
	color: #0f98d1;
	margin-top: 50px;
}
.noteRed{
  font-size: 25px;
  color: #f00;
  margin-top: 50px;
}
.outerBorder{
	border:1px solid #aaa;
}
.selectedPlanPR{
  height: 335px;
  padding:30px;
  color: #fff;
  font-size: 15px;
  background-size: 100% 100%;
  font-family: Arial !important;
  background-image: url("/images/bannerImgOrder.jpg");
}
.selectedPlanPR label{
	font-size: 40px;
}
.selectedPlanPR img{
  padding-top:8px;
  height: 90px;
}
.mt100{
	margin-top: 100px;
	margin-bottom: 60px;
}
.warning{
	font-size: 14px;
	font-weight: normal !important;
}
.successPay{
	color: #0cd222;
}
.failPay{
  color: #f00;
}
.successpaid{
  margin-top: 20px !important;
}
.suceesssection{
  margin-top: 100px !important;
}
.failedsection{
  margin-top: 30px !important;
}
.failedsection label{
  font-size: 30px !important;
}


/*****SignUp.css*****/
.sentanceCase{
  text-transform: capitalize !important;
}
.toLowerCase{
  text-transform: lowercase !important;
}
.signUpBackground{
  background-image: url("/images/TGK.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.margintop20{
  margin-top: 60px !important;
}
.rrnRegisterBtn{
  cursor: pointer;
  margin: 0px !important;
}
.margintop0{
  margin-top: 0px !important;
}

.signUpFormWrap, .forgetpassFormWrap, .confirmModalFormWrap{
  /*height: 650px;*/
  position: absolute;
  box-shadow: 0px 0px 0px;
  border: 1px solid #ccc;
  margin-top: 80px;
  background-color: rgb(0, 0, 0, 0.5);
  float: right;
}
.signUpWrapper{
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/signIn.jpg");
  padding: 0px !important;
  margin-right: -15px !important;
  height: 740px !important;
}
.signupPadding{
}
.signUpFormWrap, .forgetpassFormWrap, .confirmModalFormWrap{
  height: 680px;
  position: absolute;
  box-shadow: 0px 0px 0px;

}
.divLoginInWrap{
  z-index: 111;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.signT{
  margin-top: 30%;
}
.OESSubTitle2, .signUpNameTitle,.signInNameTitle,.resetpwdNameTitle{
  text-align: center;
  font-family: 'PT Sans', sans-serif !important;
}
.bordbt{
  border-bottom: 2px solid #1d109d;
}
.marBtm30{
  margin-bottom: 30px;
}
.noIb {
    display: inline;
    display: initial;
}
.boxMarg{
  height: 45px;
  margin-bottom: 20px !important;
}

.loginbg{
 background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/signIn.jpg");
 background-repeat: no-repeat;
  background-size: 100% 100%;
}

.firstverification{
  margin-top: 32%;
}

.logoImg{
  margin-top: 12%;
  margin-bottom: 4%;
  width: 90%;
}
/*/* login page css */
.login {
    padding: 0px !important;
}
.bookLoadWrapLog{
  padding: 2% 0% 0% 3%; 
}
.box-view1,.regsterTMSWrap {
    align-items: center;
    background: url(/images/examBackground.jpg);
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 100vh;
}

.divConfirmOtpModalWrap, .divForgotPasswordWrap, 
.divResetPasswordWrap, .divVerifyEmailWrap{
  z-index: 111;
  padding-left: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.loginPadding{
  padding-top:80px;
}

.forgotpwd{
  margin-top:75px;
}
.box-view2{
  box-shadow: 0px 0px 86px;
  padding-right: 0px;
}
.ETELoginTit,.ETESignTit{
  font-size: 34px;
    color: #337ab7;
    margin-top: 10px !important;
    font-family: 'Roboto', sans-serif !important;
    padding: 0px;
    text-align: center;
}

.ETELoginSignTit,.ETESignSubTit{
  margin-top: 2px !important;
  color: #337ab7;
  font-size: 20px;
  font-family: 'PT Sans', sans-serif !important;
  text-align: center;
}
.ETESignTit{
  margin-top: 10px !important;
}
.ETESignSubTit{
  margin-bottom: 35px;
}

.abacusTextbox{
    border: 0 !important;
    box-shadow: none;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    height: 35px !important;
    font-size: 16px;
    background: #d8d8d8;
  font-family: 'PT Sans', sans-serif !important;
}
.tmsLoginTextBox{
  height: 35px !important;
  margin-bottom: 30px;
  box-shadow: none;
  border-radius: 4px;
  font-size: 16px;
  background: #d8d8d8;
  font-family: 'PT Sans', sans-serif !important;
}
.glyphi-custommm{
  border-left: 0px solid; 
}
.glyphi-custommmLeft{
  border-left: 1px solid; 
}
.UMname{
  margin-bottom: 12px;
}
.padBoth{
  padding: 6px 9px;
}
.abacusTextbox:focus{
    box-shadow: none;
}
.floating-label {
  position: absolute;
  pointer-events: none;
  left: 25px;
  top: 8px;
  color: #555;
    font-family: 'PT Sans', sans-serif !important;

  font-weight: 600;
  font-size: 13px;
  transition: 0.2s ease all;
}
.floating-label-Date{
  display: none;
}
/*.inputText:focus ~ .floating-label,.inputText:not(:focus):valid ~ .floating-label,.inputTextSearch:focus ~ .floating-label,.inputTextSearch:not(:focus):valid ~ .floating-label{
  top: -22px;
  font-size: 15px;
  color: #333333;
  left: 17px;
  font-weight: bold;
  display: block;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 85%;
}*/
.inputText.has-content ~ .floating-label{
  top: -22px;
}

.inputText:focus ~ .floating-label,
.inputTextSearch:focus ~ .floating-label{
  top: -22px;
  /*font-size: 15px;*/
  color: #333333!important;
  /*left: 17px;*/
  font-weight: bold;
  display: block;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 85%;
    font-family: 'PT Sans', sans-serif !important;

}

.oesSignUpForm:focus ~, .floating-label,.oesSignUpForm:not(:focus):valid ~, .floating-label{
  top: -16px;
  font-size: 15px;
  color: #4183D7;
  left: 10px;
  display: block;
  font-weight: bold;
}

.UMsignup{
  background: #fff;
}

.login h1{
  color: #337ab7 !important;
}
.tmsUserAccForm{
  border: 1px solid #eee !important;
}
.TMSLoginBTN{
  background-color: #337ab7 !important;
  font-size: 18px !important;
  margin-bottom: 10px !important;
}
.loginPageTit,.loginPageTitsub{
  font-size: 40px;
    color: #fff;
  font-family: 'PT Sans', sans-serif !important;
    margin-top: 35px !important;

}
.loginPageTitsub{
  margin-top: 5px !important;
}
.loadSubTit{
  color: #fff;
    margin-top: 10px !important;
    font-size: 20px;
  font-family: 'PT Sans', sans-serif !important;
}
.boxed-view__form  .btn{
  margin-top: 20px !important;
}
.createAccTit{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 98%;
  height: 34px;
}

/*.switch input {display:none;}*/

.aaaa .sliderNew {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  content: 'ok';
}

.aaaa .sliderNew:before {
  position: absolute;
  content: "";
  height: 34px;
  width: 50%;
  left: 0px;
  padding-top: 6px;
  bottom: 0px;
  color: #fff;
  background-color: #3c8dbc;
  transition: .4s;
  content: 'No, Its not!';
}

.aaaa input:checked + .sliderNew {
  background-color: #fff;  
}

.aaaa input:focus + .sliderNew {
  box-shadow: 0 0 1px #2196F3;
}

.aaaa input:checked + .sliderNew:before {
  transform: translateX(26px);
  content: 'Yes, It is!';
  left: 70px;
}

.viewNDDel{
  border-radius: 0px !important;
  font-family: 'PT Sans', sans-serif !important;
  padding: 2px 6px;
}

.offeredBtnWrap{
  z-index: 1;
    text-align: right;
    padding: 0px;
}
.offeredBtnWrap button{
  border-radius: 0px;
  font-family: 'PT Sans', sans-serif !important;
  font-weight: bold;
  font-size: 16px;
}
.hideHazardousWrap{
  display: none;
}
.HazardousWrap{
  width: 100%;
}
.TMsloginBackgrnd{
  /*padding-right: 0px;*/
    background: #fff;
    /*padding-left: 0px;*/
    padding-top: 4%;
    padding-bottom: 4%;
}
.forgotpass{
  text-align: center;
  font-weight: bold;
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  padding-top: 5px;
  background:#d8d8d8;
  padding: 4px;
  border-radius:4px;
  margin-top: 8px; 
  /*margin-left: 17px;*/
}
.UMcreateacc{
  /*font-family: Poppins-Regular;*/
  background:#d8d8d8;
  font-size: 16px;
  text-align: center;
  padding: 3px;
    font-family: 'PT Sans', sans-serif !important;

  border-radius:4px;
  margin-top: 12px !important;
  margin-bottom: 3% !important; 
  /*margin-left: 17px;*/
  font-weight: bold;
}
.signupp{
  padding-top: 5%;
  background: #fff;
  padding-bottom: 5%;
}
.customFl{
  float: left;
}
.UMGrey,.UMGrey1,.UMGreyy, .UMIHaveRead{
  color: #999;
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  margin-left: 0px;
}
.UMGrey1{
  color: #333333;
}
.UMGrey{
  color: #333333;
  float: left;
}
/*.UMGrey:hover{
  color: #337ab7;
  text-decoration: underline;
}*/

.UMGreyy{
  color: #333333;
  float: left;
}
#businessListTable > tbody > tr > td:first-child{
  /*cursor: pointer;*/
} 
.carFlatSubmitBtn{
  display: none;
}
#awardModal .close{
  text-align: right;
}
.awarModalHead{
  padding: 0px;
    text-align: right !important;
    /*background: #d43f3a !important;*/
}
.awarModalHead h4{
  text-align: left;
    margin-top: -20px;
    margin-left: 15px;
    margin-bottom: 5px;
    color: #555;
}
.awarModalHead label{
  color: #666;
  font-size: 14px;
}
.awarModalHead button{
      /* color: #fff; */
    /* background: #dd4b39; */
    border: 0px;
    padding: 0px 8px;
    font-size: 20px;
    background: transparent;
    /*color: #fff;*/
}
.award_ModalBody p{
  color: #333 !important;
  font-size: 16px;
  text-align: center;
  font-family:'Roboto', sans-serif !important;
}
.award_ModalBody{
  padding: 5% 0px !important;
}
.awardYesBtn, .awardCancelBtn{
  margin-right: 15px;
  /*background: #38678f !important;*/
  color: #fff !important;
  font-family: 'PT Sans', sans-serif !important;
  padding: 2px 10px;
  font-size: 16px;
  border-radius: 0px!important;
  border: 0px !important;
}
.awardFooter{
  text-align: center !important;
  padding: 8px !important;
  /*background: #2e6da4 !important;*/
}
.awardCancelBtn{
  display: none;
}
.UMloginbutton{
  background: #1C3481!important;
  padding: 4px 0;
  color: #fff;
  cursor: pointer;
  border: 0;
  font-size: 15px;
  text-align: center !important;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 0px;
  border-radius: 4px !important; 
}

.UMloginbutton:hover{
  color: #fff;
}
.UMloginbutton:focus{
  outline: none;
  background: #54Aff3;
  color: #fff;
}


/*-------------------- Online Exam system csss ------------------------*/

.signUpLeftWrap{
  background: #fff;
  padding: 0px 15px !important;

  /*height: 699px;*/
}
.signUpRighttWrap{
  overflow: hidden;
  /*height: 680px;*/
  padding: 0px !important;
  /*background: repeating-linear-gradient(
  -45deg,
  #606dbc,
  #606dbc 10px,
  #465298 60px,
  #465298 60px
);*/
/*background: linear-gradient(-45deg, 
    #fb3 33.3%, #58a 0, #58a 66.6%, yellowgreen 0);*/

}
.div1{
  height: 166px;
  background-color: #ffb900;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div2{
  height: 166px;
  background-color: #f56e28;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div3{
  height: 166px;
  background-color: #ea3771;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div4{
  height: 166px;
  background-color: #f190bb;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div5{
  height: 166px;
  background-color: #46c6f5;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div6{
  height: 166px;
  background-color: #08b3ba;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div7{
  height: 166px;
  background-color: #46b450;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div8{
  height: 166px;
  background-color: #ff5c5c;
  transform: skewY(-45deg);
  transform-origin: 0;

}
.signInbtn{
  text-align: center;
  color: #333333;
  padding: 4px;
  background: #d8d8d8;
 /* margin-top: 15px !important;*/
  border-radius: 4px;
  /*margin-left: 5%;*/
  font-weight: bold;
}
.formbg{
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.mrleft{
  margin-left: 7px;
}

.signUpBanner{
  margin-top: 12%;
}


.signUpNameTitle{
  font-size: 20px;
  margin-top: 15%;
  margin-bottom: 5%;
  color: #333333;
}
.resetpwdNameTitle{
  /*margin-bottom: 30px;*/
  font-size: 25px;
  margin-top: 11%;
  margin-bottom: 10%;
  font-weight: bold;
  color: #201a47;
}

.OESSubTitle{
  font-weight: normal;
  color: #555;
  font-size: 12px;
}
.OESTitle{
  margin-bottom: 0px !important;
}
.OESSubTitle2{
  color: #1d109d;
  font-size: 20px;
  font-weight: bold;
}
.signUpInnerWrapperOES,.forPassWrap{
  padding: 0px;
}
.termsClass{
  margin-left: 5px;
}
.OEXLogoWrapverify{
  margin-bottom: 15%;
}
.otpHeader{
  
  margin-bottom: 5%;
  /*text-align: left;*/
  font-family: 'PT Sans', sans-serif !important;
  font-size: 14px;
  padding-left: 0px;
  padding-right: 15px;
}
.OEXLogoWrap{
  margin-bottom: 4.5%;
}
.OESSubTitleee{
  margin-bottom: 8%;
}
.signupIconFont{
  margin-right: 5px;
}
.resendOtpColor{
  /*background: #d8d8d8;*/
}
.resendOtpWrap{
    text-align: right;
    /*background: #d8d8d8;*/
    font-size: 14px;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    /*margin-top: 17px;*/
    margin-left: 75px;
    font-weight: bold;
}
.resendOtpWrap span{
   /*font-family: "Poppins","Sans-serif";*/
  color: #333333;
  cursor: pointer;
}


.maintenanceClass{
  margin-top: 70px;
}
.pVText{
  text-align: center;
}
.loginLoadingTimeDiv{
  text-align: center;
  text-transform: capitalize;
  /*opacity: 0.4;*/
  cursor: not-allowed;
}
/**********Garima**********/
.logWrapper{
  background-image: url(https://wealthyvia.s3.ap-south-1.amazonaws.com/website/formbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.oesSignUpForm:focus ~ .floating-label, .oesSignUpForm:not(:focus):valid ~ .floating-label {
    top: -19px;
    font-size: 13px;
    color: #4183D7;
    left: 18px !important;
    display: block;
    font-weight: bold;
}
.floating-label1 {
    position: absolute;
    pointer-events: none;
    left: 17.5px;
    top: 8px;
    color: #555;
    font-weight: 600;
    font-size: 13px;
    transition: 0.2s ease all;
      font-family: 'PT Sans', sans-serif !important;

}
.oesSignUpForm:focus ~ .floating-label1, .oesSignUpForm:not(:focus):valid ~ .floating-label1 {
    top: -16px;
    font-size: 13px;
    left: 5px !important;
    display: block;
    font-weight: bold;
}
.lbfloatpass{
  left: 10px !important;
}
.form-group1{
  float:left;
  margin-bottom:0px;

}
.form-group2{
  margin-bottom:5px!important;
}

.UMloginbutton1{
  margin-bottom:5px!important;
}


.modalbg {
  position: fixed;
  font-family: 'Roboto', sans-serif !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  z-index: 99999;
  /*transition(0.5s);
  transition-delay(.2s);*/
  display: none;
  pointer-events: none;
  transform:scaleY(.01) scaleX(0);
  -webkit-animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  
}
@-webkit-keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}
@keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}

.dialog {
    width: 400px;
    position: relative;
    color:white;
    margin: 10% auto;
    padding: 5px 20px 13px 20px;
    border-radius:10px;
    /*.gradient;
    .box-shadow;*/
    background:rgba(255,255,255,0.2)
  }

.modalbg:target {
  display: none;
  pointer-events: auto;
  background: rgba(4, 10 ,30, .8);
  /*.transition();
  .dialog {
    top: -20px;
    .transition(.3s);
    .transition-delay;*/
  /*}*/
}


.modalclose {
  background: #606061;
  color: #FFFFFF;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 12px;
  /*.box-shadow;
  .transition;
  .transition-delay(.2s);*/
 /* &:hover { background: #00d9ff; .transition; }*/
}

.fineprint {
  font-style: italic;
  font-size: 10px;
  color: #646;
}
/*a { color: #333; text-decoration: none; }*/
.modaltext{
  color:#666;
}
.termsconditionslink{
  margin-bottom:10px;
}
.modalpara{
  white-space: pre-line;
}
.modalparascroll{
  height:300px;
  overflow-y: auto;
}
.radiobtnbox{
 border:1px solid #d8d8d8;
 border-radius: 5px;
 background:#d8d8d8;
}
.leftpaddingzero{
  padding-left:0!important;
}
.radiolabel{
  color:#444!important;
  font-weight:normal!important;
  font-size:14px!important;
  /*margin-top:2px;*/
}

.radiobtnstyle{
  height: 15px;
  width: 15px;
}

.radiobtnbx{
  padding-right: 0!important;
  padding-top: 4px!important;
  padding-bottom: 0!important;
}
.pdleftclr{
  padding-left: 0px;
}
.mrotplf{
  margin-left: 5% !important;
}
.resettitle1{
  margin-top: 63% !important;
}

.loginforms{
  padding-top: 5%;
  padding-left: 20%;
  padding-right: 5%;

}


.termspad{
    padding-bottom: 15px;
   /* padding-top: 5px;*/
    /*padding-left: 31px;*/
}



.sidebar{
  /*display: none;
  background: #fff;*/
}
.pdrmv{
  padding: 0px;
}
.rdolb{
  font-size: 13px;
  float: right;
  padding: 3px;
  color: #333;
  font-weight: bold;
}
.signupfrm{
  margin-top: 8%;
}
.verifypd{
  padding: 2px!important;
}
.veribtm{
  margin-bottom: 30px;
}
.veribtm1{
  margin-bottom: 15px;
}
.veriemail{
  padding-left: 7px;
  padding-right: 18px;
}
.veriemailmr{
  margin-left: 6% !important;
}
.FormWrapper1{
  padding: 7px;
  margin-top: 30px;
}
input[type=radio]:checked ~ span{
  color: #4183D7;
}
.signUpNameTitle2{
  font-size: 20px;
  margin-top: 15%;
  margin-bottom: 8%;
  color: #333333;
  text-align: center;
  font-family: 'PT Sans', sans-serif !important;
}
.signInbtn1{
  text-align: center;
  color: #333333;
  padding: 4px;
  background: #6CCEF9;
  margin-top: 12px !important;
  border-radius: 4px;
  font-weight: bold;
  border:1px solid #ccc;
}
.signuppd1{
  padding: 3px;
}
/*input[type='radio']:before {
      content: '';
      display: block;
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
  }

input[type="radio"]:checked:before {
      background: #4183D7;
   }*/
/*input[type=radio]:checked ~ .check {
  border: 5px solid #0DFF92;
}*/
.confirmbtm{
  border: 0 !important;
  box-shadow: none;
  border-bottom: 1px solid #efefef !important;
  border-radius: 4px;
  margin-bottom: 15px;
  height: 35px !important;
  font-size: 16px;
  background: #d8d8d8;
  font-family: 'PT Sans', sans-serif !important;
}

.userCreateWrap{
  padding-top:55px;
}
.under{
  text-decoration: underline;
}
.terms1{
  position: relative;
  top: -2px;
}
.mrleftsign{
  margin-left: 15px;
}

.backgroundErrImg,.backgroundErrImgMain{
    background: url(https://wealthyvia.s3.ap-south-1.amazonaws.com/website/background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 300px;
    box-shadow: 0px 0px 10px !important;
    margin-top: 71px;
    color: #fff;
    padding-top: 110px;
    text-align: center;
}
.backgroundErrImgMain{
  background: url(https://wealthyvia.s3.ap-south-1.amazonaws.com/website/background.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  margin-top: 0px;


}
.backgroundErrImg h4{
  font-size: 22px; 
  line-height: 1.4;
}
.backgroundErrImg b{
  font-size: 22px;
  text-decoration: underline;
  color: #f00;
  background: #fff; 
}
.resendOtpWrapcss{
  background: #d8d8d8!important;
}
.textpd{
  padding-right: 7px;
}
.textpd1{
  padding-left: 1px;
}
.textpdEye{
  padding-left: 7px !important;
}
.fltlft{
  float:left !important;
}
.pdcls {
    padding: 0px;
}
.studentSidebar{
  position: fixed;
}
.tip{
    top: 67% !important;
}
.long{
    top: 58% !important;
}
.selectLabel{
  margin-bottom: 4px;
  color: #555;
    font-weight: 600;
    font-size: 13px;
}
.bcktolgn{
  padding-top:10px;
  color:white;  
}
.bcktolgn:hover{
  cursor:pointer;
}
.greyText{
  color:#777;
}
.form-control:focus{
  background: #D8D8D8;
}


.input-group-addon{
  background-color: transparent !important;
}















.imageSize{
  font-size: 12px!important;
  padding-right: 35px;
}
.fontwt{
  font-weight: normal;
}
.customCls {
  border-bottom: 1px solid #efefef !important;
  background-color: #d8d8d8 !important;
  padding: 0px !important;
  padding-right: 10px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.customCls1 {
  padding-left: 10px !important;
}
.pass{
      z-index: 0 !important;
}

.showPwd{
  display: block;
}
.hidePwd{
  display: none;
}
.showPwd1{
  display: none;
}
.hidePwd1{
  display: block;
}
.showPwdC{
  display: block;
}
.hidePwdC{
  display: none;
}
.showPwd1C{
  display: none;
}
.hidePwd1C{
  display: block;
}

.marBtm{
  margin-bottom: 20px;
}

/*.showPwd,.hidePwd1C,.hidePwd1,.showPwdC{
  display: block;
}
.hidePwd,.showPwd1C,.showPwd1,.hidePwdC{
  display: none;
}*/

/*modal bck css*/

/*****SignUp.css*****/


/*.signupPadding{
  padding-top:4%;
}*/
.addDivSignUP{
  height: 20px;
}
.signUpFormWrap, .forgetpassFormWrap, .confirmModalFormWrap{
  height: 690px!important;
  position: absolute;
  box-shadow: 0px 0px 0px;
  border: 1px solid #ccc;
  margin-top: 60px;
  padding: 20px !important;
  background-color: #fff!important;
  float: right;
}
.signUpFormWrapold, .forgetpassFormWrap, .confirmModalFormWrap{
  /*height: 540px!important;*/
  position: absolute;
  box-shadow: 0px 0px 0px;
  border: 1px solid #ccc;
  margin-top: 60px;
  padding: 20px !important;
  background-color: #fff!important;
  float: right;
}
.loginFormWrap{
  z-index: 0px !important;
  border-radius: 0px;
  border:1px #aaa solid;
  padding:25px;
  margin-top: 5%;
  margin-bottom:  5%;
  padding-bottom: 5%;
  background-color: #fff!important;
  float: right;
}
.divLoginInWrap{
  z-index: 111;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.signT{
  margin-top: 8%;
}
.OESSubTitle2, .signUpNameTitle,.signInNameTitle,.resetpwdNameTitle{
  text-align: center;
  /*font-family:'Roboto-BoldCondensed';*/
}
/*.bordbt{
  border-bottom: 2px solid #1d109d;
}*/
.marBtm30{
  margin-bottom: 30px;
}
.noIb {
    display: inline;
    display: initial;
}



.firstverification{
  margin-top: 12%;
}

.logoImg{
  margin-top: 12%;
  margin-bottom: 4%;
  width: 90%;
}
/*/* login page css */
.login {
    padding: 0px !important;
}
.bookLoadWrapLog{
  padding: 2% 0% 0% 3%; 
}
.box-view1,.regsterTMSWrap {
    align-items: center;
    background: url(/images/examBackground.jpg);
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 100vh;
}

.divConfirmOtpModalWrap, .divForgotPasswordWrap, 
.divResetPasswordWrap, .divVerifyEmailWrap{
  z-index: 111;
  padding-left: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.loginPadding{
  padding-top:80px;
}

/*.forgotpwd{
  margin-top:75px;
}*/
.box-view2{
  box-shadow: 0px 0px 86px;
  padding-right: 0px;
}
.ETELoginTit,.ETESignTit{
  font-size: 34px;
    color: #337ab7;
    margin-top: 10px !important;
  font-family: 'PT Sans', sans-serif !important;
    padding: 0px;
    text-align: center;
}

.ETELoginSignTit,.ETESignSubTit{
  margin-top: 2px !important;
  color: #337ab7;
  font-size: 20px;
  font-family: 'PT Sans', sans-serif !important;
  text-align: center;
}
.ETESignTit{
  margin-top: 10px !important;
}
.ETESignSubTit{
  margin-bottom: 35px;
}

.abacusTextbox{
    /*border: 0 !important;*/
    box-shadow: none;
    /*border-bottom: 1px solid #efefef !important;*/
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 35px !important;
    font-size: 16px;
    /*background: #d8d8d8;*/
    background-color: #fff;
  font-family: 'PT Sans', sans-serif !important;
}
.tmsLoginTextBox{
  height: 35px !important;
  margin-bottom: 30px;
  border: 1px solid #ccc!important;
  box-shadow: none;
  border-radius: 4px;
  font-size: 16px;
  background: #fff;
  font-family: 'PT Sans', sans-serif !important;
}
.glyphi-custommm{
  border-left: 0px solid; 
}
.glyphi-custommmLeft{
  border-left: 1px solid; 
}
.UMname{
  margin-bottom: 12px;
}
.padBoth{
  padding: 6px 9px;
}
.abacusTextbox:focus{
    box-shadow: none;
}
.floating-label {
  position: absolute;
  pointer-events: none;
  left: 25px;
  top: 8px;
  color: #333;
  color: #333;
  font-weight: 100;
  font-size: 13px;
  transition: 0.2s ease all;
}




.floating-label-Date{
  display: none;
}
/*.inputText:focus ~ .floating-label,.inputText:not(:focus):valid ~ .floating-label,.inputTextSearch:focus ~ .floating-label,.inputTextSearch:not(:focus):valid ~ .floating-label{
  top: -22px;
  font-size: 15px;
  color: #333333;
  left: 17px;
  font-weight: bold;
  display: block;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 85%;
}*/
.inputText.has-content ~ .floating-label{
  top: -22px;
}

.inputText:focus ~ .floating-label,
.inputTextSearch:focus ~ .floating-label{
  top: -22px;
  /*font-size: 15px;*/
  color: #ccc!important;
  /*left: 17px;*/
  font-weight: 100;
  display: block;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 85%;
}

.oesSignUpForm:focus ~, .floating-label,.oesSignUpForm:not(:focus):valid ~, .floating-label{
  top: -16px;
  font-size: 15px;
  left: 10px;
  display: block;
  font-weight: 100;
}

.UMsignup{
  background: #fff;
}

.login h1{
  color: #337ab7 !important;
}
.tmsUserAccForm{
  border: 1px solid #eee !important;
}
.TMSLoginBTN{
  background-color: #337ab7 !important;
  font-size: 18px !important;
  margin-bottom: 10px !important;
}
.loginPageTit,.loginPageTitsub{
  font-size: 40px;
    color: #fff;
  font-family: 'PT Sans', sans-serif !important;
    margin-top: 35px !important;

}
.loginPageTitsub{
  margin-top: 5px !important;
}
.loadSubTit{
  color: #fff;
    margin-top: 10px !important;
    font-size: 20px;
  font-family: 'PT Sans', sans-serif !important;
}
.boxed-view__form  .btn{
  margin-top: 20px !important;
}
.createAccTit{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 98%;
  height: 34px;
}

/*.switch input {display:none;}*/

.aaaa .sliderNew {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  content: 'ok';
}

.aaaa .sliderNew:before {
  position: absolute;
  content: "";
  height: 34px;
  width: 50%;
  left: 0px;
  padding-top: 6px;
  bottom: 0px;
  color: #fff;
  background-color: #3c8dbc;
  transition: .4s;
  content: 'No, Its not!';
}

.aaaa input:checked + .sliderNew {
  background-color: #fff;  
}

.aaaa input:focus + .sliderNew {
  box-shadow: 0 0 1px #2196F3;
}

.aaaa input:checked + .sliderNew:before {
  transform: translateX(26px);
  content: 'Yes, It is!';
  left: 70px;
}

.viewNDDel{
  border-radius: 0px !important;
  font-family: 'PT Sans', sans-serif !important;
  padding: 2px 6px;
}

.offeredBtnWrap{
  z-index: 1;
    text-align: right;
    padding: 0px;
}
.offeredBtnWrap button{
  border-radius: 0px;
  font-family: 'PT Sans', sans-serif !important;
  font-weight: bold;
  font-size: 16px;
}
.hideHazardousWrap{
  display: none;
}
.HazardousWrap{
  width: 100%;
}
.TMsloginBackgrnd{
  /*padding-right: 0px;*/
    background: #fff;
    /*padding-left: 0px;*/
    padding-top: 4%;
    padding-bottom: 4%;
}
.forgotpass{
  text-align: center;
  font-weight: bold;
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  padding-top: 5px;
  background:#FFFFFF;
  padding: 7px;
  border-radius:4px;
  margin-top: 8px; 
  border: 1px solid #ccc;
  /*margin-left: 17px;*/
}
.UMcreateacc{
  /*font-family: Poppins-Regular;*/
  background:#6ccef9;
  font-size: 16px;
  text-align: center;
  padding: 7px;
  border-radius:4px;
  margin-top: 12px !important;
  margin-bottom: 3% !important; 
  /*margin-left: 17px;*/
  font-weight: bold;
  border: 1px solid #ccc;
}
/*.UMGreyy a, a:hover, a:focus {
    color: #333 ;
    text-decoration: none;

}*/
.signupp{
  padding-top: 5%;
  background: #fff;
  padding-bottom: 5%;
}
.customFl{
  float: left;
}




.confirmTitle{
    font-size: 30px;
    margin-top: 0%;
    padding: 8%;
    margin-bottom: 3%;
   /* color: #333333; */
    color: #201a47 !important;
    font-weight: bold;
  font-family: 'PT Sans', sans-serif !important;

}



.signInNameTitle{
    font-size: 30px;
    margin-top: 0%;
    padding: 8%;
    margin-bottom: 55%;
   /* color: #333333; */
    color: #201a47 !important;
    font-weight: bold;
  font-family: 'PT Sans', sans-serif !important;

}




















































.UMGrey,.UMGrey1,.UMGreyy, .UMIHaveRead{
  color: #999;
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  margin-left: 0px;
}
.UMGrey1{
  color: #333333;
}
.UMGrey{
  color: #333333;
  float: left;
}
/*.UMGrey:hover{
  color: #337ab7;
  text-decoration: underline;
}*/

.UMGreyy{
  color: #333333;
  float: left;
}
#businessListTable > tbody > tr > td:first-child{
  /*cursor: pointer;*/
} 
.carFlatSubmitBtn{
  display: none;
}
#awardModal .close{
  text-align: right;
}
.awarModalHead{
  padding: 0px;
    text-align: right !important;
    /*background: #d43f3a !important;*/
}
.awarModalHead h4{
  text-align: left;
    margin-top: -20px;
    margin-left: 15px;
    margin-bottom: 5px;
    color: #555;
}
.awarModalHead label{
  color: #666;
  font-size: 14px;
}
.awarModalHead button{
      /* color: #fff; */
    /* background: #dd4b39; */
    border: 0px;
    padding: 0px 8px;
    font-size: 20px;
    background: transparent;
    /*color: #fff;*/
}
.award_ModalBody p{
  color: #333 !important;
  font-size: 16px;
  text-align: center;
  font-family: 'PT Sans', sans-serif !important;
}
.award_ModalBody{
  padding: 5% 0px !important;
}
.awardYesBtn, .awardCancelBtn{
  margin-right: 15px;
  /*background: #38678f !important;*/
  color: #fff !important;
  font-family: 'PT Sans', sans-serif !important;
  padding: 2px 10px;
  font-size: 16px;
  border-radius: 0px!important;
  border: 0px !important;
}
.awardFooter{
  text-align: center !important;
  padding: 8px !important;
  /*background: #2e6da4 !important;*/
}
.awardCancelBtn{
  display: none;
}
.UMloginbutton{
  background: #DAA520;
  padding: 7px 0;
  color: #fff;
  cursor: pointer !important;
  border: 0;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 4px !important; 
  font-family: 'PT Sans', sans-serif !important;
}

.UMloginbutton:hover{
  color: #fff;
}
.UMloginbutton:focus{
  outline: none;
  background: #54Aff3;
  color: #fff;
}


/*-------------------- Online Exam system csss ------------------------*/

.signUpLeftWrap{
  background: #fff;
  padding: 0px 15px !important;

  /*height: 699px;*/
}
.signUpRighttWrap{
  overflow: hidden;
  /*height: 680px;*/
  padding: 0px !important;
  /*background: repeating-linear-gradient(
  -45deg,
  #606dbc,
  #606dbc 10px,
  #465298 60px,
  #465298 60px
);*/
/*background: linear-gradient(-45deg, 
    #fb3 33.3%, #58a 0, #58a 66.6%, yellowgreen 0);*/

}
.div1{
  height: 166px;
  background-color: #ffb900;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div2{
  height: 166px;
  background-color: #f56e28;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div3{
  height: 166px;
  background-color: #ea3771;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div4{
  height: 166px;
  background-color: #f190bb;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div5{
  height: 166px;
  background-color: #46c6f5;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div6{
  height: 166px;
  background-color: #08b3ba;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div7{
  height: 166px;
  background-color: #46b450;
  transform: skewY(-45deg);
  transform-origin: 0;
}
.div8{
  height: 166px;
  background-color: #ff5c5c;
  transform: skewY(-45deg);
  transform-origin: 0;

}
.signInbtn{
  text-align: center;
  /*color: #333333;*/
  color: #fff;
  padding: 4px;
  /*background: #fff;*/
 /* margin-top: 15px !important;*/
  /*border-radius: 4px;*/
  /*margin-left: 5%;*/
  font-weight: bold;
  /*border: 1px solid #ccc;*/
  margin-left: -27px;
}
.signInbtnReset{
  text-align: center;
  color: #fff;
  padding: 4px;
text-align: center;
  color: #333333;
  padding: 4px;
  background: #d8d8d8;
 /* margin-top: 15px !important;*/
  border-radius: 4px;
  /*margin-left: 5%;*/
  font-weight: bold;
  font-weight: bold;
}
.signInbtn a{
    color: #fff !important;
    /* text-decoration: none; */
    
}
.formbg{
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.mrleft{
  margin-left: 7px;
}

.signUpBanner{
  margin-top: 12%;
}


.signUpNameTitle{
  font-size: 20px;
  margin-top: 15%;
  margin-bottom: 5%;
  color: #333333;
}
/**/

.OESSubTitle{
  font-weight: normal;
  color: #555;
  font-size: 12px;
}
.OESTitle{
  margin-bottom: 0px !important;
}
.OESSubTitle2{
  color: #1d109d;
  font-size: 20px;
  font-weight: bold;
}
.signUpInnerWrapperOES,.forPassWrap{
  padding: 0px;
}
.termsClass{
  margin-left: 5px;
}
.OEXLogoWrapverify{
  margin-bottom: 15%;
}
.otpHeader{
  color: #333;
  /*color: #fff;*/
  margin-bottom: 1%;
  /*text-align: left;*/
  font-family: 'PT Sans', sans-serif !important;
  font-size: 14px;
  padding-left: 0px;
  padding-right: 15px;
}
.OEXLogoWrap{
  margin-bottom: 4.5%;
}
.OESSubTitleee{
  margin-bottom: 8%;
}
.signupIconFont{
  margin-right: 5px;
}
.resendOtpColor{
  /*background: #d8d8d8;*/
}
.resendOtpWrap{
    text-align: right;
    /*background: #d8d8d8;*/
    font-size: 14px;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    /*margin-top: 17px;*/
    margin-left: 75px;
    font-weight: bold;
}
.resendOtpWrap span{
   /*font-family: "Poppins","Sans-serif";*/
  color: #333333;
  cursor: pointer;
}


.maintenanceClass{
  margin-top: 70px;
}
.pVText{
  text-align: center;
}
.loginLoadingTimeDiv{
  text-align: center;
  text-transform: capitalize;
  /*opacity: 0.4;*/
  cursor: not-allowed;
}
/**********Garima**********/
.logWrapper{
  background-image: url(https://wealthyvia.s3.ap-south-1.amazonaws.com/website/formbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.oesSignUpForm:focus ~ .floating-label, .oesSignUpForm:not(:focus):valid ~ .floating-label {
    top: -19px;
    font-size: 13px;
    color: #000!important;
    left: 18px !important;
    display: block;
    font-weight: 100;
}
.floating-label1 {
    position: absolute;
    pointer-events: none;
    left: 17.5px;
    top: 8px;
    color: #333;
    font-weight: 100;
    font-size: 13px;
    transition: 0.2s ease all;
}
.oesSignUpForm:focus ~ .floating-label1, .oesSignUpForm:not(:focus):valid ~ .floating-label1 {
    top: -16px;
    font-size: 13px;
    color: #000!important;
    left: 5px !important;
    display: block;
    font-weight: 100;
}
.lbfloatpass{
  left: 10px !important;
}
.form-group1{
  float:left;
  margin-bottom:0px;

}
.form-group2{
  margin-bottom:5px!important;
}

.UMloginbutton1{
  margin-bottom:5px!important;
}


.modalbg {
  position: fixed;
  font-family: 'PT Sans', sans-serif !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  z-index: 99999;
  /*transition(0.5s);
  transition-delay(.2s);*/
  display: none;
  pointer-events: none;
  transform:scaleY(.01) scaleX(0);
  -webkit-animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  
}
@keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}

.dialog {
    width: 400px;
    position: relative;
    color:white;
    margin: 10% auto;
    padding: 5px 20px 13px 20px;
    border-radius:10px;
    /*.gradient;
    .box-shadow;*/
    background:rgba(255,255,255,0.2)
  }

.modalbg:target {
  display: none;
  pointer-events: auto;
  background: rgba(4, 10 ,30, .8);
  /*.transition();
  .dialog {
    top: -20px;
    .transition(.3s);
    .transition-delay;*/
  /*}*/
}


.modalclose {
  background: #606061;
  color: #FFFFFF;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 12px;
  /*.box-shadow;
  .transition;
  .transition-delay(.2s);*/
 /* &:hover { background: #00d9ff; .transition; }*/
}

.fineprint {
  font-style: italic;
  font-size: 10px;
  color: #646;
}
/*a { color: #333; text-decoration: none; }*/
.modaltext{
  color:#666;
}
.termsconditionslink{
  margin-bottom:10px;
}
.modalpara{
  white-space: pre-line;
}
.modalparascroll{
  overflow-y: auto;
}
.radiobtnbox{
 border:1px solid #d8d8d8;
 border-radius: 5px;
 background:#d8d8d8;
}
.leftpaddingzero{
  padding-left:0!important;
}
.radiolabel{
  color:#444!important;
  font-weight:normal!important;
  font-size:14px!important;
  /*margin-top:2px;*/
}

.radiobtnstyle{
  height: 15px;
  width: 15px;
}

.radiobtnbx{
  padding-right: 0!important;
  padding-top: 4px!important;
  padding-bottom: 0!important;
}
.pdleftclr{
  padding-left: 0px;
}
.mrotplf{
  margin-left: 5% !important;
}
.resettitle1{
  margin-top: 63% !important;
}

.loginforms{
  padding-top: 5%;
  padding-left: 20%;
  padding-right: 5%;

}


.termspad{
    padding-bottom: 15px;
   /* padding-top: 5px;*/
    /*padding-left: 31px;*/
}



.sidebar{
  /*display: none;
  background: #fff;*/
}
.pdrmv{
  padding: 0px;
}
.rdolb{
  font-size: 13px;
  float: right;
  padding: 3px;
  color: #333;
  font-weight: bold;
}
.signupfrm{
  margin-top: 8%;
}
.verifypd{
  padding: 4px;
}
.veribtm{
  margin-bottom: 10px;
}
.veribtm1{
  margin-bottom: 15px;
}
.veriemail{
  padding-left: 7px;
  padding-right: 18px;
}
.veriemailmr{
  margin-left: 6% !important;
}
.FormWrapper1{
  padding: 7px;
  margin-top: 30px;
}
input[type=radio]:checked ~ span{
  color: #4183D7;
}
.signUpNameTitle2{
  font-size: 20px;
  margin-top: 4%;
  margin-bottom: 4%;
  /*color: #333333;*/
  color: #f04723;
  font-weight: bold;
  text-align: center;
  font-family: 'PT Sans', sans-serif !important;
  /*font-family: 'Roboto-BoldCondensed';*/
}
.signInbtn1{
  text-align: center;
 /* color: #333333;*/
 /*color: #fff;*/
  /*padding: 4px;*/
  /*background: #d8d8d8;*/
  margin-top: 12px !important;
  /*border-radius: 4px;*/
  /* margin-left: 5%; */
  font-weight: bold;
}
.signuppd1{
  padding: 3px;
}
/*input[type='radio']:before {
      content: '';
      display: block;
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
  }

input[type="radio"]:checked:before {
      background: #4183D7;
   }*/
/*input[type=radio]:checked ~ .check {
  border: 5px solid #0DFF92;
}*/
.confirmbtm{
  /*border: 0 !important;*/
  box-shadow: none;
  border: 1px solid #ccc;
  /*border-bottom: 1px solid #efefef !important;*/
  border-radius: 4px;
  margin-bottom: 15px;
  height: 35px !important;
  font-size: 16px;
  /*background: #d8d8d8;*/
  background-color: #fff;
  font-family: 'PT Sans', sans-serif !important;
}

.userCreateWrap{
  padding-top:55px;
}
.under{
  text-decoration: underline;
}
.terms1{
  position: relative;
  top: -2px;
  color: #fff;
  left: 2px;
}
.mrleftsign{
  margin-left: -15px;
}
.mrleftsign:hover{
  color: #fff !important;
}

.backgroundErrImg,.backgroundErrImgMain{
    background: url(https://wealthyvia.s3.ap-south-1.amazonaws.com/website/background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 300px;
    box-shadow: 0px 0px 10px !important;
    margin-top: 71px;
    color: #fff;
    padding-top: 110px;
    text-align: center;
}
.backgroundErrImgMain{
  background: url(/https://wealthyvia.s3.ap-south-1.amazonaws.com/website/background.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  margin-top: 0px;


}
.backgroundErrImg h4{
  font-size: 22px; 
  line-height: 1.4;
}
.backgroundErrImg b{
  font-size: 22px;
  text-decoration: underline;
  color: #f00;
  background: #fff; 
}
.resendOtpWrapcss{
  background: #d8d8d8!important;
}

.fltlft{
  float:left !important;
}
.pdcls {
    padding: 0px;
}
.studentSidebar{
  position: fixed;
}
.tip{
    top: 67% !important;
}
.long{
    top: 58% !important;
}
.selectLabel{
  margin-bottom: 4px;
  color: #555;
    font-weight: 600;
    font-size: 13px;
}
.bcktolgn{
  padding-top:10px;
  color:white;  
}
.bcktolgn:hover{
  cursor:pointer;
}
.greyText{
  color:#777;
}
.form-control:focus{
  background: #fff;
}















.marbtm10{
  margin-bottom: 20px;
}

.border3{
  border-radius: 3px !important;
}

.imageSize{
  font-size: 12px!important;
  padding-right: 35px;
}
.fontwt{
  font-weight: normal;
}
.customCls {
  border-bottom: 1px solid #efefef !important;
  background-color: #fff !important;
  padding: 0px !important;
  padding-right: 10px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.eyeicon{
  border-bottom: 1px solid #efefef !important;
  background-color: #fff !important;
  
  /*padding-right: 10px !important;*/
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.customCls1 {
  padding-left: 10px !important;
}
.pass{
      z-index: 0 !important;
}

.showPwd{
  display: block;
}
.hidePwd{
  display: none;
}
.showPwd1{
  display: none;
}
.hidePwd1{
  display: block;
}
.showPwdC{
  display: block;
}
.hidePwdC{
  display: none;
}
.showPwd1C{
  display: none;
}
.hidePwd1C{
  display: block;
}

.marBtm{
  margin-bottom: 20px;
}

/*.showPwd,.hidePwd1C,.hidePwd1,.showPwdC{
  display: block;
}
.hidePwd,.showPwd1C,.showPwd1,.hidePwdC{
  display: none;
}*/

.showHideSignDiv{
    top:-28px;
    float: right;
    margin-right: 9px;
    z-index: 999;
    position: relative;
    font-size: 16px;
}

.signUpBackground{
  background-image: url("/images/TGK.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.backImageModal{
  background-image: url("/images/TGK_Post.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
/*a:hover{
  color: #fff;
}*/

.page_content{
    border: 1px solid #ddd;
    background-color: #fff;
}
.mrgleft{
  margin-left: -15px;
}
.modal{
  overflow-y: auto !important;
}

.referredbytext{
  margin-top: -9px;
    font-size: 13px;
    color: #000!important;
    left: 18px !important;
    display: block;
    font-weight: 100;
}
.boxMargreferedby{
  height: 52px;
  margin-bottom: 20px !important;
}

.placecontainercss{
    z-index: 111111;
    position: absolute;
    top: 35px;
    background-color: #fff;
    left: 15px;
    right:0px; 
    width:93%;   
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

.placepadding{
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    width: 100%;
    display: block;
}

.boxMargadd{
  margin-bottom: 30px !important;
}
/*.App {
  text-align: center;
}*/
.*:focus{
  outline: none;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.agenda{
  text-align: left;
  margin-left: 150px;
}

.mynavbar{
  color: #fff;
  text-decoration: none;
}

.mynavbar a{
  color: #fff;
  text-decoration: none;
  margin-left: 30px;
}

.post{
  font-weight: normal;
  text-align: left;
  margin-left: 50px;
  box-shadow: 0px 0px 20px #666;
  padding: 30px;
  margin-bottom: 30px;
  width: 85%;
}

button{
  padding: 5px;
  border: 0px;
  background-color: #fff;
  color: #666;
  font-weight: bold;
}

.block{
  background-color: #fff;
  color: #666;
}

.orangebg{
  background-color: #ffc107;
}

a{
  color: #333 !important;
}

a .active{
  color: #ffc107;
}
/*Greinsch*/
.greenColor{
  color: #5f9c30;
}
.mt50{
  margin-top:50px;
}
.asterix{
  font-size: 20px;
  color: #f00 !important;
}
.backColorGray{
  background-color: #eef3f4 !important;
}
.backColorWhite{
  background-color: #fff !important;
}
.mt20{
  margin-top: 20px;
}
.textAlignCenter{
  text-align: center;
  font-family: Arial;
}
.noPadding{
      padding: 0px !important;
}
.padding40{
    padding-right: 80px !important;
}
.textAlignRight{
  text-align: right;
}
.marginTop180{

  margin-top: 100px;

}
.customPillsGrocery {
    margin-left: 40%;
    margin-top: 45px;
    font-size: 18px !important;
    font-family: 'PT Sans', sans-serif;
    background-color: transparent!important;
    color: #1c3481 !important;
    padding: 0px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.customPillsGrocery>li.active>a, .customPillsGrocery>li.active>a:focus, .customPillsGrocery>li.active>a:hover{
    background-color: transparent!important;
    color: #1c3481 !important;
    border-top: 5px solid #5f9c30 !important;
    border-radius:0px !important;
}
.customPillsGrocery>li>a:hover{
  background-color: transparent !important;
}
.offerDetailsGrocery{
  background-color: #5f9c30;
  height:25px;
  padding-top:3px;
  color: #fff; 
  font-family: 'PT Sans', sans-serif;
}
.priceDivProductGrocery{
    color: #5f9c30 !important;
}
.borderBottom{
  font-size: 14px;
    color: #666;
    line-height: 24px;
    font-family: 'PT Sans', sans-serif;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.backColorPurple{
  background-color: #201747;
  color: #fff !important;
}
.backColorPink{
background-color: #DD7975 !important;
}
.ht50{
  height:60px;
}
.backColorGray{
   background-color: #DBD7DF;
 
}
/*.mt20{
  margin-bottom: 5%;
}*/
.marginZero{
  margin:0px !important;
}
.noPadding{
padding:0px !important;
}
.errorMsg{
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  color:#f00 !important;
}
/*color #376bff*/
.backColorBlack{
background-color: #000;
}
/*.fa-times{
  color: #60e8dd !important;
}*/
.blogDateSBP{
  font-size: 20px;
  font-weight: normal;
  font-family: Arial;
}
.noBold{
  font-weight: normal !important;
}
.textAlignRight{
  text-align: right !important;
}
.breakAll{
  word-break: break-all;
}
.curserPointer{
  cursor: pointer;
}
