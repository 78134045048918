.bulkEmployeeImg img{
    height: 70px;
}
.bulkEmployeeImg{
    padding-left: 0px;
}
.productselection{
    margin-bottom: 20px;
}
.productchartcontent{
    /*margin-bottom: 40px;
    margin-top: 40px;*/
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 16px;
}

.productselection .selectbox{
    margin-bottom: 0px !important;
}

.bulkuploadfile{
    margin-top: 20px;
}

.errorlabel{
    color: #f00;
}

.productchartout{
    padding-top: 40px;
    padding-bottom: 40px;
}

.productspace{
	margin-top: 40px;
	margin-bottom: 40px;
}

.nav-pills.chartpills>li {
    float: left;
    border-top: 1px solid #dde0e4;
    border-bottom: 1px solid #dde0e4;
    padding-right: 0px;
    margin-left: 0px !important;
}   

.nav-pills.chartpills>li:last-child {
    border-right: 1px solid #dde0e4; 
}

.nav-pills.chartpills > li > a {
    border-radius: 0 !important;
    color: #444;
    border-left: 1px solid #dde0e4 !important;
    padding: 7px 10px !important;
}    

.nav-pills.chartpills > li.active > a, .nav-pills.chartpills > li > a.active {
    margin-bottom: 0px !important;
    background-color: #fff !important;
    border: 1px solid #3c8dbc !important;
    color: #3c8dbc !important;
    padding: 7px 10px !important;
}   


.chartdiv{
	padding-top: 30px;
}

.hrline{
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.investingva{
	font-size: 17px;
    line-height: 21px;
}

.pageloader{
    height:300px;
}

.nav-pills.chartpills{
    float: right;
}

.blockborder{
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.chartheading{
    font-size: 23px;
}

.cagrheading{
    font-size: 18px;  
    
}
.cagrvalue{
    font-weight: 600;
}
.chartheightfix{
    height: 620px;
}
.colorwealthypr{
    color: #1f7ae0;
}
.colorwealthycgr {
    color: #1f7ae0;
    line-height: 1.57;
    font-weight: 600;
}
.colorwealthyindex{
    color: #E3AF64;
}

@media (max-width: 899px){
    .extspace{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .chartheightfix{
        height: 720px !important;
    }
}

@media (min-width: 1300px){
    
    .chartheightfix{
        height: 820px !important;
    }
}

@media (min-width: 900px) and (max-width: 1200px){
    
    .chartheightfix{
        height: 560px !important;
    }
}