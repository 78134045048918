/*.App {
  text-align: center;
}*/
.*:focus{
  outline: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.agenda{
  text-align: left;
  margin-left: 150px;
}

.mynavbar{
  color: #fff;
  text-decoration: none;
}

.mynavbar a{
  color: #fff;
  text-decoration: none;
  margin-left: 30px;
}

.post{
  font-weight: normal;
  text-align: left;
  margin-left: 50px;
  box-shadow: 0px 0px 20px #666;
  padding: 30px;
  margin-bottom: 30px;
  width: 85%;
}

button{
  padding: 5px;
  border: 0px;
  background-color: #fff;
  color: #666;
  font-weight: bold;
}

.block{
  background-color: #fff;
  color: #666;
}

.orangebg{
  background-color: #ffc107;
}

a{
  color: #333 !important;
}

a .active{
  color: #ffc107;
}
/*Greinsch*/
.greenColor{
  color: #5f9c30;
}
.mt50{
  margin-top:50px;
}
.asterix{
  font-size: 20px;
  color: #f00 !important;
}
.backColorGray{
  background-color: #eef3f4 !important;
}
.backColorWhite{
  background-color: #fff !important;
}
.mt20{
  margin-top: 20px;
}
.textAlignCenter{
  text-align: center;
  font-family: Arial;
}
.noPadding{
      padding: 0px !important;
}
.padding40{
    padding-right: 80px !important;
}
.textAlignRight{
  text-align: right;
}
.marginTop180{

  margin-top: 100px;

}
.customPillsGrocery {
    margin-left: 40%;
    margin-top: 45px;
    font-size: 18px !important;
    font-family: 'PT Sans', sans-serif;
    background-color: transparent!important;
    color: #1c3481 !important;
    padding: 0px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.customPillsGrocery>li.active>a, .customPillsGrocery>li.active>a:focus, .customPillsGrocery>li.active>a:hover{
    background-color: transparent!important;
    color: #1c3481 !important;
    border-top: 5px solid #5f9c30 !important;
    border-radius:0px !important;
}
.customPillsGrocery>li>a:hover{
  background-color: transparent !important;
}
.offerDetailsGrocery{
  background-color: #5f9c30;
  height:25px;
  padding-top:3px;
  color: #fff; 
  font-family: 'PT Sans', sans-serif;
}
.priceDivProductGrocery{
    color: #5f9c30 !important;
}
.borderBottom{
  font-size: 14px;
    color: #666;
    line-height: 24px;
    font-family: 'PT Sans', sans-serif;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.backColorPurple{
  background-color: #201747;
  color: #fff !important;
}
.backColorPink{
background-color: #DD7975 !important;
}
.ht50{
  height:60px;
}
.backColorGray{
   background-color: #DBD7DF;
 
}
/*.mt20{
  margin-bottom: 5%;
}*/
.marginZero{
  margin:0px !important;
}
.noPadding{
padding:0px !important;
}
.errorMsg{
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  color:#f00 !important;
}
/*color #376bff*/
.backColorBlack{
background-color: #000;
}
/*.fa-times{
  color: #60e8dd !important;
}*/
.blogDateSBP{
  font-size: 20px;
  font-weight: normal;
  font-family: Arial;
}
.noBold{
  font-weight: normal !important;
}
.textAlignRight{
  text-align: right !important;
}
.breakAll{
  word-break: break-all;
}
.curserPointer{
  cursor: pointer;
}