.headingofdashboard{
	font-size: 35px;
	font-weight: normal !important;
	margin-top: 20px;
	padding-bottom: 10px;
	 font-family:Arial;

}
.dateondashboard{
	font-size: 16px;
	font-weight: normal !important;
	margin-top: 30px;
	 font-family:Arial;
	 box-shadow: 0px 2px 6px 4px #aaa;
	 padding:6px;
	 border-radius: 5px;


}