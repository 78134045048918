@media print 
{
    tr, td, th {page-break-inside:avoid}
    thead {display:table-header-group}
    .NoPrint {visibility:hidden; display:none}
    a {color:#000000}
    .iconContainerIP{ padding-top: 0px; height: auto; }
    .mt20{margin-top: 0px; }
    .margintopad {  margin-top: 0px; margin-bottom: 20px !important; }
    .customTableIPTD { color: #000 !important;  padding: 10px !important; padding-top: 10px !important;   padding-bottom: 10px !important; border: 1px solid #aaa; }
    .customTablePP tr> td, th { border: 1px solid #aaa; color: #000 !important; padding: 10px !important; }
    .textAlignRight {    text-align: left !important; }
    .succesmsg{ display: none; }
    .emptyh4{ margin:0px;padding:0px;line-height:1;    }
    .dateContain{margin-bottom: 0px;padding-bottom: 0px;}
    .invoicetoh4{ margin:0px;padding:0px;line-height:1;    }
}
.note{
	font-size: 25px;
	color: #0f98d1;
	margin-top: 50px;
}
.noteRed{
  font-size: 25px;
  color: #f00;
  margin-top: 50px;
}
.outerBorder{
	border:1px solid #aaa;
}
.selectedPlanPR{
  height: 335px;
  padding:30px;
  color: #fff;
  font-size: 15px;
  background-size: 100% 100%;
  font-family: Arial !important;
  background-image: url("/images/bannerImgOrder.jpg");
}
.selectedPlanPR label{
	font-size: 40px;
}
.selectedPlanPR img{
  padding-top:8px;
  height: 90px;
}
.mt100{
	margin-top: 100px;
	margin-bottom: 60px;
}
.warning{
	font-size: 14px;
	font-weight: normal !important;
}
.successPay{
	color: #0cd222;
}
.failPay{
  color: #f00;
}
.successpaid{
  margin-top: 20px !important;
}
.suceesssection{
  margin-top: 100px !important;
}
.failedsection{
  margin-top: 30px !important;
}
.failedsection label{
  font-size: 30px !important;
}
