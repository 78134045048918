.blogFormBox{
	
	margin: 25px 0px;
}
.ckbox{
	height: 375px;
}
.boxform1{
	position: relative;
	top: 25px;
}