@import url('https://fonts.googleapis.com/css?family=Inconsolata:700');
.AllBlogsbannerWall{
  height: 435px;
  /*background-color:  #444;*/
  padding: 0px !important;
  position: relative;
    text-align: center;
    color: white;
    background-image: url("/images/ceo.png");
    background-size: 100% 100%;

}
.nopadding{
  padding: 0px;
}
.AllBlogscentered {
  background-color: #191818ba;
  padding: 0px !important;
  height: 435px;
  text-align:center !important;

}
.AllBlogstextcentered{
  position: absolute;
  top: 25%;
  text-align: center !important;
  left: 0%;
  font-family: "Arial";
  
}

.fs72{
  font-weight: 600;
  font-size: 72px;
}
.fs24{
  font-size: 22px;
}

.AllBlogsBox{
	background-color: #f6f6f6;
	border: 1px solid #eaeaea;
	padding: 20px;
}

.blogTitle{
	margin: 5px 0px;
	font-size: 18px;
    margin: 5px 0px;
    line-height: 23px;
    font-family: "Arial";
}
.blogTitleList{
	margin: 5px 0px;
    font-size: 1.8em;
    line-height: 23px
	 
}
.blogTitleListSmall{
	margin: 5px 0px;
    font-size: 14px;
    line-height: 23px
	 
}
.premiumBlogIndicateList{
	color: #f5d26e;
    font-size: 16px;
    position: absolute;
    margin-top: -11px;
    margin-left: -4px
}
.AllblogImgB{
	height: 173px!important;
	width: 100%;
}
.AllblogImgList{
	height: 80px!important;
}
.Allblog a:hover{
	text-decoration: none;
}
.Allblog:hover .blogTitle{
	color: #1db1f1;
}
.blogPara{
	overflow: hidden;
	height: 125px;
	text-align: justify;
	text-overflow: ellipsis;
	padding-top: 10px;
	overflow-wrap: break-word;


}
.blogParaList{
	overflow: hidden;
	text-align: justify;
	text-overflow: ellipsis;
	overflow-wrap: break-word;


}
.All1blog1{
	position: relative;
	height: 330px;
	overflow: hidden;
	margin-bottom: 50px;
	background-color: #fff;
	box-shadow: 0px 11px 15px 1px #ccc;
}
.All1blogList{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 5px;
	padding-top: 10px !important;
	margin-top: 7px;
	margin-bottom: 7px;
	background-color: #fff;
}
.p10{
	padding-left:  20px;
	padding-right: 20px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}

.z50{
	position: relative;
	top: -75px;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 4%;
 right: -2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.All1blog1:hover .middle {
  opacity: 1;
}

.wclr{
	color: #fff;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}
.rclr{
	color: #f00;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}

.hoverbk{
	    background-color: #111;
    border-radius: 12px;
}
.noBlogs{
	margin-top: 5%;
	margin-bottom: 10%;
	font-size:30px !important;
}
.premiumBlogIndicate{
	  position: absolute;
	  left: -20px;
	  top:14px;
	  padding-left: 20px;
	  padding-right:  20px;
	  transform: rotate(-40deg);
	  background-color:#f5d26e;
	  color: #333;
	  font-size: 16px;

}
.loadingImageContainer{
	height: 350px;

}
.loadingImageContainer img{
	height: 100%;
	width: 100%;

}
.customGridIcon{
	margin-top: 60px;
	margin-right: 10px;
	cursor: pointer;
	font-size: 20px !important;
}
.blogDate{
	font-size: 12px;
}
.searchIconABB{
  font-size: 20px;
  margin-top: 49px;
  cursor: pointer;
  color: #fff;

}
.open{
    display: block !important;
    transition: 10s all ease !important;

}
.AllblogList{
	margin-top: 40px;
}
.customInputAllBlog{
  margin-left:10px;
  height: 30px;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  margin-right: 10px;
  background-color: transparent;
  background-image: none;
  border:none;
}
.outerborder{
  margin-top: 55px;
	border: 1px solid #aaa;
	height: 30px;
	border-radius: 5px;
	margin-right: 10px;
}
.pad10search{
	padding-top: 7px !important;
	padding-left: 5px !important;

}
/*input[type=text] {
  width: 10px;
  border:none;
  margin-top: 50px;
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  background-image: url('/images/magnifying-glass.png');

  background-position: 10px 10px; 
  background-repeat: no-repeat;
  cursor:pointer;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
input[type=text]:focus {
  width: 20%;
   box-sizing: border-box;
  border: 2px solid #ccc;
 
}
*/
.customName{
	font-weight: normal;
    width: 148px !important;
    overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
}
