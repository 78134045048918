.researchpage {
    padding-top: 80px;
    padding-bottom: 80px;
    font-family: Arial;
}
.top-border-block {
    padding: 25px 30px 30px;
    margin-bottom: 30px;
    background: #fff;
    border-top: 10px solid #c9c9c1;
    position: relative;
}
.top-border-block:before {
    content: "";
    height: 10px;
    width: auto;
    display: block;
    position: absolute;
    background: url(/images/border-pat.jpg) repeat-x;
    top: -10px;
    left: 14px;
    right: 14px;
}    
.pageheading {
	font-size: 4rem;
    padding-top: 10px;
    padding-bottom: 20px;
    color: #201a47;
    line-height: 1.2;
    font-weight: bold;
}
.reporttitle{
	font-size: 27px;
    margin-top: 5px;
    color: #201a47 !important;
    font-family: Arial;
    font-weight: 600;
    line-height: 1.3
}
.reportdescription {
	font-size: 17px;
	font-family: Arial;
	padding-top: 10px;
	padding-bottom: 10px;
}
.date{
	font-size: 18px;
	font-family: Arial;
	padding-top: 10px;
	padding-bottom: 10px;
    color: #7e8183;
    font-size: 14px;
    line-height: 25px;
}
.searchinput{
    color: #000;
}
.reportborder {
    margin-top: 30px;
    margin-right: 0px !important;
}
.imgpdf{
    width:100%;
}
.imgblock{
    padding-left: 0px !important;
}
.datebox{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.fa-download:before {
    /* color: #201a47; */
    color: #25bbf3 !important;
    font-size: 20px;
}
.downloadpdf-img{
    width: 72px;
}
.leftspace{
    margin-left: 5px;
}
.searchreportinput{
    width:84%;
}
.lineheightdate{
    line-height: 1.8
}
.lineheightarrw{
    line-height: 1.26 !important
}
@media only screen and (max-width: 767px){
.pageheading {
    font-size: 24px;
    padding-top: 20px;
} 
.leftspace{
    margin-left: 5px !important;
}
.reportborder {
    margin-top: 0px;
    margin-bottom: 30px;
}   
.top-border-block {
    padding: 20px 10px 20px 10px;
}
}

input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
}

.todaysdate{
    text-align: center;
}

.margintopReport{
    margin-right: -20px;
    float: right !important;
}
    