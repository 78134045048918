
.bannerAUPara{
	font-size: 40px;
	font-weight: bold;
	line-height: 60px;
}
.headingAbout{
	color: #fff;
	font-size: 20px;
	padding: 30px;
	font-weight: bold;

}
.aboutUsContainer{
	background-image: linear-gradient(to right ,#201a47, #25bbf3);
	height: 100%;
}
.outstandingTeam{
	color: #fff;
	font-weight: bold;
	font-size:40px;
	padding-top:60px !important;
	padding: 20px;
 font-family:Arial;}
.customUlabout{
	list-style: circle;

}
.customUlabout li{
	color: #fff;
 font-family:Arial;	font-size: 20px;
	padding:20px;
}


.triangle-bottomleft {
    width: 0;
    height: 0;
     border-bottom: 520px solid #fff;
    border-right: 183px solid transparent
    }
.hexDiv1{
 font-family:Arial;	font-size: 18px;
	height: 280px;
	padding-left: 20px !important;
	padding-right: 20px !important;
	text-align: center;
	color: #fff;
}
.hexDiv1 img{
	height: 100px;
	padding: 5px;
	padding-bottom: 20px;
	border-bottom: 2px solid #aaa;
	filter: invert(60%);
}
.hexDiv1 p{
	margin-top: 20px;
	color: #888;
	padding-top: 0px !important;
	padding: 50px;
	font-size: 17px !important;
}
.hexDiv1Small{
	background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/hexaBack.png");
	background-repeat: no-repeat;
 font-family:Arial;	font-size: 18px;
	background-size: 100% 100%;
	height: 360px;
	padding: 70px !important;
	text-align: center;
	color: #fff;
}
.hexDiv1Small img{
	height: 70px;
}
.hexDiv1Small p{
	margin-top: 10px;
}
.customMarginHex{
	margin-top: 200px;
    margin-left: -173px;
}
.centerSecound{
margin-top: -183px;
    margin-left: -85px;

}
.marginLeft170{
margin-left: 170px;
}
.marginTop380{	
	margin-top: -380px;
}
.headLabelQF{
 font-family:Arial;
	position: absolute;
    font-size: 35px;
    top: 101px;
    left: 450px;
}
.headLabelQF1{
 font-family:Arial;
    font-size: 35px;
    margin-top: 20px;
}
.lastDiv{
	margin-top: -185px;
    margin-left: 170px;
}
.bannerContainerAU{
	background-image: url("/images/howWork.jpg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	text-align:center;
	height: auto;
	color: #fff;
	padding: 90px;
}
.descDivAU{
	padding: 40px;
	font-size: 18px;
}
.svgContent{
stroke:rgba(255, 255, 255, 0.45);stroke-width:5;
height: 10px !important;
width: 80px !important;
}
.qfHead{
	font-weight: bolder;
	font-size: 40px;
	padding: 40px; 

}
.nineYearImage{
	background-size: 100% 100%;
	height: 600px;

	text-align: center;
	background-attachment: fixed;
	color: #fff;
	background-image: url("/images/nineYear.jpg");
}
.nineYearImage p{
	margin-top:80px;
	line-height: 70px;
	font-weight: bolder;
	font-size: 56px;
}
.nineYearImage label{
	margin-top:10px;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;

}
.nineYearImage img{
	margin-top: 50px;
}
.riskDiv{
	background-size: 100% 100%;
	height: 600px;
	background-attachment: fixed;
	color: #fff;
	background-image: url("/images/riskBack.jpg");
}
.riskDiv p{
	margin-top:20px;
	line-height: 70px;
	font-weight: bolder;
	font-size: 60px;
}
.riskDiv label{
	margin-top:10px;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;

}
.riskDiv1{
	background-size: 100% 100%;
	height: 700px;
	background-attachment: fixed;
	color: #fff;
	background-image: url("/images/riskBack.jpg");
}
.riskDiv1 p{
	margin-top:20px;
	line-height: 70px;
	font-weight: bolder;
	font-size: 40px;
}
.riskDiv1 label{
	margin-top:10px;
	font-weight: normal;
	font-size: 18px;
	line-height: 30px;

}
.blackDiv{
	padding-top: 100px !important;
	padding: 60px !important;
		height: 600px;

	background-color: rgba(0,0,0,0.5) !important;
}
.blackDiv1{
	padding-top: 100px !important;
	padding: 40px !important;
	height: 700px;

	background-color: rgba(0,0,0,0.5) !important;
}
.blackDivRisk{
	padding-top: 30px !important;
	height: 600px;
	background-color: rgba(0,0,0,0.5) !important;
}
.blackDivOther{
     padding-top: 30px !important; 
    /* padding: 60px !important; */
    background-color: rgba(0,0,0,0.5) !important;
}

.imgContentAU{
	height: 80px;
}
.imgContentAU img{
	height: 100%;
	width: 100%;
}
.imageContent1{
	height: 500px;
	font-size: 20px;
	color: #666;

    padding: 30px !important;
/*    padding-left: 120px !important;
*/    padding-top: 20px !important;
}
.imageContent1 p{
    text-align: justify;	
}
.imageContent2{
	height: auto;
	font-size: 20px;
	color: #666;
    padding: 70px !important;
    padding-top: 40px !important;
}
.image1{
	height: 400px;
	margin-top: 30px !important;
	padding: 10px;
	background-image: url("/images/loading.gif");
 	background-repeat: no-repeat;
}
.image2{
	height: 350px;
}
.image2 img{
	height: 100%;
	width:  100%;
}
.image1 img{
	height: 100%;
	width:  100%;
}
.nameOfImageHead{
	font-size: 40px;
	color: #201A47;
}
.borderBottomDiv{
	width: 100px;
		border-bottom: 2px solid #201A47;
		margin-bottom: 20px;
}
.padding40inDiv{
	padding: 90px !important;
}

.sebicontent{
	color: #333;
	/*font-size: 19px;*/
	font-size: 1.5em;
    line-height: 1.5em;
    text-align: justify;
}

.sebiimage{
	text-align: center;
}

.sebiimage img{
	width:60%;
} 

.sebiname{
	margin-top:10px;
	font-size: 1.5em;
    line-height: 1.5em;
    
}

.regsebinumber{
	font-size: 24px;
}

