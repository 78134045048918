.backColorEF{
	background-color: #eef3f4;
}
.contactUsBody{
	padding: 20px;
	margin-bottom: 20px;
	
}
.LabelContactUs{
	
}
.contactspan{
	font-size: 30px !important;
    color: #666;
    font-family: 'PT Sans',sans-serif;
    font-weight: normal !important;
}
.contactUsSpan{    
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #666;
	padding-top: 5px;
}
.customInputContact{
	font-family: 'PT Sans',sans-serif;
	border:1px solid #aaa;
}
.customInput:focus{
	outline: none !important;
}
.customInput::placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.customInputArea:focus{
	outline: none !important;
}
.customInputArea::placeholder{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	color: #999;
}
.submitBtnContactUs{
	font-size: 14px;
    text-align: center;
	font-family: 'PT Sans',sans-serif;
    padding: 0px !important;
    background-color: #00baf2;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    height: 40px;
}
.storeInfo{
	padding: 20px;
	position: absolute !important;
	margin-top: 70px;
	margin-left: 17px;


}
.storeInfo label{
	font-size: 14px;
}
.contactUsBody span{
	font-family: 'PT Sans',sans-serif;
	font-size: 14px;
	padding:10px;
}
.contactUsForm{
	height: 100%;
	font-family: 'PT Sans',sans-serif;
	padding: 40px;

}
.customSelectContact{
	display: block;
    height: 40px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;

}
.heading{
    font-size: 18px;
    padding: 0px !important;
    padding-top: 10px !important;
font-weight: normal !important;	
}
.customSelectContact:focus{
	outline: none !important;
}
.mt10{
	margin-top:10px;
}
.marginBottom{
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid #aaa;
}
.sendButton{
	padding: 10px;
	background-color: #e7e7ea;
}
.headingSend{
  padding: 0px !important;
  font-weight: normal !important;
  font-size: 25px;
  
}
.sendUsSupport{
	margin-bottom: 20px;
}
